import styled from "styled-components";
import theme from "../../styles/theme";

export const Container = styled.textarea`
    width: 100%;
    height: 60vh;
    resize: none;
    background: rgba(239, 239, 222, 0.5);
    /* opacity: 0.5; */
    border-radius: 0.625rem;
    border: 0;
    box-sizing: border-box;
    font-family: 'Pretendard';
    font-weight: 400;
    font-size: 1.0625rem;
    line-height: 1.625rem;
    letter-spacing: -0.02em;

    color: ${theme.colors.mainBlackColor};

    padding: 2rem;
    outline: none;

    ::-webkit-scrollbar {
        width: 1.5rem;
        
    }
    ::-webkit-scrollbar-thumb {
        background-color: ${theme.colors.oliveColor2};
        border-radius: 3.75rem;
        background-clip : padding-box;
        border: 0.5rem solid transparent;
        height: 10rem;
    }
    ::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 10px;
    }
`