import React from 'react'
import StyledTextArea from '../../components/StyledTextArea/StyledTextArea.component'
import {Container, Section, Content, TitleText} from './RefundPolicyPage.style'
const RefundPolicyPage = () => {
    const content = 
    `
이용약관 중 환불정책에 관한 내용입니다. 
전체 내용과 특히, 제 24조(청약철회 등) 제5항을 참고한 후 사이트 하단 CS에 있는 메일로의 연락을 통해 환불이 진행됩니다.
 개인정보(성함, 핸드폰번호) 및 구매한 상품, 구매 번호를 기재하여 이메일을 통해 전달 주시면 주말 제외한 대부분의 경우 24시간 이내에 순차적으로 연락하여 환불절차가 진행될 수 있도록 합니다.


제 24 조 (청약철회 등)
1. ‘회사’의 ‘상품 등’의 구매취소 및 환불 규정은 『전자상거래 등에서의 소비자보호에 관한 법률』 등 관련 법령을 준수합니다.
2. ‘회사’와 ‘상품 등’의 구매에 관한 계약을 체결한 회원은 수신확인의 통지를 받은 날(전자우편 또는 SMS 문자통지 등을 받은 날) 또는 ‘상품 등’의 공급을 받은 날로부터 7일 이내에는 청약을 철회할 수 있습니다. 다만, 청약철회에 관하여 『전자상거래 등에서의 소비자보호에 관한 법률』 에 달리 정함이있는 경우에는 동 법 규정에 따릅니다.
3. 이용자는 ‘상품 등’을 배송 받은 경우 아래 각 호에 해당하는 경우에는 교환 및 반품, 환불을 할 수 없습니다.
가. 이용자에게 책임 있는 사유로 ‘상품, 서비스 등’이 멸실 또는 훼손된 경우
나. 이용자의 사용 또는 일부 소비에 의하여 ‘상품, 서비스 등’의 가치가 현저히 감소한 경우
다. 시간의 경과에 의하여 재판매가 곤란할 정도로 ‘상품, 서비스 등’의 가치가 현저히 감소한 경우
라. 같은 성능을 지닌 ‘상품, 서비스 등’으로 복제가 가능한 경우 그 원본인 상품, 서비스를 열람한 경우
마. 회사가 특정 쿠폰 또는 상품 등에 대하여 청약철회 제한에 관해 사전에 고지한 경우
4. 회원은 제1항, 제2항 및 제3항의 규정에 불구하고 ‘상품, 서비스 등’의 내용이 표시∙광고 내용과 다르거나 계약 내용과 다르게 이행된 때에는 당해 ‘상품, 서비스 등’을 공급받은 날부터 3일 이내, 그 사실을 안 날 또는 알 수 있었던 날부터 10일 이내에 청약철회 등을 할 수 있습니다.
5. 회원(입학생)이 '사이트'에 결제한 금액을 환불 받고자 할 경우 아래 기준대로 계산하여 반환합니다.
수강 신청시간 기준 : 7일 미만, 3강 미만 수강 시에는 전액 환불 합니다. 
수강 신청시간 기준 : 7일 이상이 지났거나, 3강 이상 수강 시에는 환불이 불가합니다.
(무료 강의는 포함하지 않습니다)

ex1) 22년 10월 2일 신청 -> 22년 10월 7일, 2강만 수강 -> 전액 환불
ex2) 22년 10월 2일 신청 -> 22년 10월 11일, 2강만 수강 -> 7일 이상이 지났기에 환불 불가능
ex3) 22년 10월 2일 신청 -> 22년 10월 7일, 무료 강의를 제외하고 3강만 수강 -> 3강 이상 수강했기에 환불 불가능


제 25 조 (청약철회 등의 효과)
1. 회원이 구매한 ‘상품 등’을 취소 또는 환불하는 경우 ‘회사’는 그 처리 결과에 대해 제13조(회원에 대한 통지)에 정한 방법으로 통지합니다.
2. ‘회사’는 회원으로부터 청약철회 또는 해지 신청을 받은 경우 분쟁의 소지가 없을 때에는 7영업일 이내에 이미 지급받은 대금을 환급합니다. 
3. 청약철회 등의 경우 공급받은 ‘상품 등’의 반환에 필요한 비용은 회원이 부담합니다. 다만, ‘상품 등’의 내용이 표시∙광고 내용과 다르거나 계약내용과 다르게 이행되어 청약철회 등을 하는 경우 ‘상품 등’에 필요한 비용은 ‘회사’가 부담합니다.
4. 회원의 단순변심에 의한 취소일 경우 환불처리에 발생하는 수수료와 기타 제반 비용은 회원이 부담합니다.
    `
    
    return (
        <Container>
        <Section>
            <Content>
            <TitleText>환불정책</TitleText>
            <StyledTextArea>
                {content}
            </StyledTextArea>
            </Content>
        </Section>
        </Container>
    )
}

export default RefundPolicyPage;