import { Link } from 'react-router-dom'
import styled from 'styled-components'
import theme from '../../styles/theme'
import { RootContainer } from '../../styles/public'
import { icons } from '../../static/icons/icon';
import { FC, Ref } from 'react';

export const HeaderContainer = styled.header`
    width: 100%;
    position: -webkit-sticky;
    position: sticky;
    z-index: 999;
    top: 0;
`

export const PopupBar = styled.div`
    padding-inline: 1rem;
    height: 50px;
    background-color: ${theme.colors.mainBlackColor};
    /* flex-grow: 1; */
`

export const PopupBarText = styled.p`
    color: white;
    line-height: 130%;
    font-size: 16px;
    margin: 0;
    text-align: center;
    letter-spacing: -0.02em;

    @media screen and (max-width: 576px) {
        font-size: 14px;
    }
`

export const CloseContainer = styled.div`
    display: flex;
    align-items: center;

    @media screen and (max-width: 576px) {
        > p {
            display: none;
        }
    }
`

export const IndexBar = styled.div`
    padding-inline: 1rem;
    background-color: #FCFAF6;
    height: 80px;
    @media screen and (max-width: 576px) {
        height: 60px;
        background-color: #FFFFFF;
    }
`
/* 아이콘을 감싸는 div */
const IconDiv = styled.div`
    cursor: pointer;
`

export const CloseBtn = styled.button`
    cursor: pointer;
    border: 0;
    margin-left: 5px;
    background-color: Transparent;
`

export const JiktalLogo = styled(Link)`
    display: flex;
    cursor: pointer;
    align-items: center;
    margin-right: 50px;
`

export const IndexBox = styled.div`
    display: flex;
    justify-content: center;
    :hover {
        > div {
            display: block;
            padding-top: 2.5rem;
        }
    }
`

export const StyledLink = styled(Link)`
    font-size: 17px;
    font-weight: 500;
    line-height: 20px;
    text-decoration: none;
    color: ${theme.colors.mainBlackColor};
    @media screen and (max-width: 576px) {
        display: none;
    }
    :hover {
        font-weight: 700;
        color: ${theme.colors.mainColor};
    }
`

export const LoginBtn = styled(Link)`
    width: 80px;
    height: 40px;
    border-radius: 60px;
    color: white;
    background-color: #6B3F36;
    border: 0;
    font-size: 17px;
    text-decoration: none;
    text-align: center;
    vertical-align: middle;
    line-height: 40px;
    
    @media screen and (min-width: 576px) {
        display: block;
    }
    @media screen and (max-width: 576px) {
        display: none;
    }
`

export const UserBtn = styled.div`
    cursor: pointer;

    @media screen and (min-width: 576px) {
        display: block;
    }
    @media screen and (max-width: 576px) {
        display: none;
    }
`

export const ThreeBarBtn = styled.div`
    cursor: pointer;
    @media screen and (min-width: 576px) {
        display: none;
    }
    @media screen and (max-width: 576px) {
        display: block;
    }
`

export const MobileMenuContainer = styled.div`
    display: none;
    @media screen and (max-width: 576px) {
        display: flex;
        align-items: center;
    }
`

export const MobileLogin = styled(Link)`
    display: flex;
    text-decoration: none;
    justify-content: center;
    align-items: center;
    animation: fadein 3s;
    > p {
        color: ${theme.colors.mainColor};
        margin-left: 0.5rem;
        margin-block: 0;
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 1.125rem;
    }
`
export const MobileLogout = styled.div`
    display: flex;
    text-decoration: none;
    justify-content: center;
    align-items: center;
    animation: fadein 3s;
    > p {
        color: ${theme.colors.mainColor};
        margin-left: 0.5rem;
        margin-block: 0;
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 1.125rem;
    }
`