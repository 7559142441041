import React, { ReactNode } from 'react'
import { Container, Div, TitleText, ContentText, SubText, Line } from './ReviewCard.style'
interface Props {
    children: ReactNode;
}

const ReviewCard = ({ children }: Props) => {
  return <Container>{children}</Container>
}

ReviewCard.Div = ({ children }: Props) => {
    return <Div>{children}</Div>
  }

ReviewCard.Title = ({ children }: Props) => {
    return <TitleText>{children}</TitleText>
}

ReviewCard.Content = ({ children }: Props) => {
    return <ContentText>{children}</ContentText>
}

ReviewCard.Line = () => {
    return <Line />
}

ReviewCard.Name = ({ children }: Props) => {
    return <SubText>{children}</SubText>
}

ReviewCard.Score = ({ children }: Props) => {
    return <SubText>{children}</SubText>
}

export default ReviewCard