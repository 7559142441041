import styled from "styled-components";
import theme from "../../styles/theme";

export const Container = styled.div`
    width: 100%;
    margin-bottom: 1rem;
`

export const Text = styled.p`
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 1.625rem;
    margin-block: 1rem;
    color: ${theme.colors.mainColor};
`

export const Input = styled.input`
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #ccced1;
    border-radius: 0;
    padding: 1rem 2rem;

    :focus {
        border: 1px solid hsl(218,81.8%,56.9%);
        box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.1) inset;
        outline: none;
    }
`