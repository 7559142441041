import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import TopTabSlider from '../../components/TopTabSlider/TopTabSlider.component';
import UserInfo from '../../components/UserInfo/UserInfo.component';
import MyClassRoomContainer from '../../container/MyClassRoomConatiner/MyClassRoomContainer.component';
import PaymentHistoryContainer from '../../container/PaymentHistoryContainer/PaymentHistoryContainer.component';
import UserInfoModificationContainer from '../../container/UserInfoModificationContainer/UserInfoModificationContainerSocial.component';
import { api } from '../../util/api';
import { Container, Content, Section } from './MyPage.style';
import { useAppSelector } from '../../hooks/hooks'
import UserInfoModificationContainerHome from '../../container/UserInfoModificationContainer/UserInfoModificationContainerHome.component';
import UserInfoModificationContainerSocial from '../../container/UserInfoModificationContainer/UserInfoModificationContainerSocial.component';




const MyPage = () => {
    const location = useLocation();
    const tabNumber = location.state?.tabNumber;

    const [userID, setUserID] = useState("")
    const [userName, setUserName] = useState("")
    const [userNickname, setUserNickname] = useState("")
    const [userEmail, setUserEmail] = useState("")
    const [userPhone, setUserPhone] = useState("")
    const [userType, setUserType] = useState("")

    const getUserInfo = () => {
      api.get('/user')
      .then((res: any) => {
          setUserID(res.data.info.login_id);
          setUserName(res.data.info.name);
          setUserNickname(res.data.info.nickname);
          setUserEmail(res.data.info.email);
          setUserPhone(res.data.info.phone);
          setUserType(res.data.info.social);
      })
      .catch((err: any) => {
          console.log(err);
      });
    }

    useEffect(() => {
        getUserInfo();
    }, [])

    const TabElements = [
      {
        label: "내 강의실",
        container: <MyClassRoomContainer />
      },
      {
        label: "결제내역",
        container: <PaymentHistoryContainer />
      },
      {
        label: "회원정보 수정",
        container: userType === 'home' ? <UserInfoModificationContainerHome
                      userID={userID}
                      userName={userName}
                      userNickname={userNickname}
                      userEmail={userEmail}
                      userEmailHandler={setUserEmail}
                      userPhone={userPhone}
                    /> : <UserInfoModificationContainerSocial
                    userID={userID}
                    userName={userName}
                    userNickname={userNickname}
                    userEmail={userEmail}
                    userPhone={userPhone}
                  />
      },
    ]

    return (
        <Container>
            <Section>
                <Content>
                    <UserInfo 
                      userNickname={userNickname}
                      userEmail={userEmail}
                    />
                    <TopTabSlider TabElements={TabElements} tapNumber={tabNumber} />
                </Content>
            </Section>
        </Container>
    )
}

export default MyPage