import React from 'react'
import { icons } from '../../static/icons/icon'
import { FlexColumn } from '../../styles/public'
import { Container, EmailText, NicknameText, ProfileImgBox, ProfileImg } from './UserInfo.style'

interface Props {
    userNickname: string
    userEmail: string
}

const UserInfo = ({ userNickname, userEmail }: Props) => {

  return (
    <Container>
        <ProfileImgBox>
            <ProfileImg src="https://cdn-icons-png.flaticon.com/512/149/149071.png" />
        </ProfileImgBox>
        
        <FlexColumn>
            <NicknameText>
                {userNickname} <span>님</span>
            </NicknameText>
            <EmailText>
                {userEmail}
            </EmailText>
        </FlexColumn>
        
    </Container>
  )
}

export default UserInfo