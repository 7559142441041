import styled from "styled-components";
import theme from "../../styles/theme";

export const Container = styled.div`
    width: 100%;
    height: auto;
    background: #FFFFFF;
    box-shadow: 0rem 0.0625rem 0.5rem rgba(152, 152, 152, 0.1);
    border-radius: 0.25rem;
    box-sizing: border-box;
    padding-inline: 1.5rem;
    padding-block: 3rem 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media screen and (max-width: 576px) {
        padding-block: 4rem 1.5rem;
    }
`

export const Div = styled.div`
`

export const TitleText = styled.h1`
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.875rem;
    color: ${theme.colors.mainColor};
    white-space: pre-wrap;

    @media screen and (max-width: 576px) {
        font-size: 1.125rem;
        line-height: 1.625rem;
    }
`

export const ContentText = styled.p`
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.75rem;
    letter-spacing: -0.02em;
    color: ${theme.colors.greyColor1};
    white-space: pre-wrap;

    @media screen and (max-width: 576px) {
        font-size: 0.9375rem;
        line-height: 1.75rem;
        letter-spacing: -0.02em;
    }
`

export const Line = styled.hr`
    border: 1px solid #D6D6D4;
`

export const SubText = styled.p`
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.625rem;
    margin-left: 0.5rem;
    color: ${theme.colors.mainBlackColor};

    @media screen and (max-width: 576px) {
        font-size: 1rem;
        line-height: 1.625rem;
    }
`
    

