import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import InputBox from '../../components/InputBox/InputBox.component';
import SubmitBtn from '../../components/SubmitBtn/SubmitBtn.component';
import { FlexRowJustifyContentSpaceBetween, Form } from '../../styles/public';
import { Container, Contnet, TitleText } from './SignUpPage.style'
import { api } from '../../util/api'
import { useForm } from "react-hook-form"
import ConsentForm from '../../components/ConsentForm/ConsentForm.component';
import ConsentPopup from '../../components/ConsentPopup/ConsentPopup.component';
import { terms } from '../../static/terms/terms';

interface Data {
    id: string,
    pw: string,
    name: string,
    nickname: string,
    email: string,
    phone: string
}

const SignUpPage = () => {
    const navigate = useNavigate();

    /** 회원가입 정보 서버 전송 */
    const PostSignUp = (data: Data) => {
        api.post('/signjoin/join', {
            "id": data.id,
            "pw": data.pw,
            "name": data.name,
            "nickname": data.nickname,
            "email": data.email,
            "phone": data.phone
        })
        .then((res: any) => {
            if (res.data.ok) {
                alert(`회원가입이 완료되었습니다!\n${data.name}님 반갑습니다.`)
                navigate('/login')
            }
        })
        .catch((err: any) => {
            if (err.respose.status === 400) {
                alert(`잘못된 정보입니다.`)
            } else {
                alert(`시스템 오류입니다.`)
            }
        });
    }


    /** 각 정보 중복 확인 */
    const dupleInput = (type: string, korType: string, value: string) => {
        api.post('/signjoin/duple', {
            [type]: value
        })
        .then((res: any) => {
            if (res.data.dup) {
                setError(
                    type,
                    { message: `중복된 ${korType}입니다.` },
                    { shouldFocus: true },
                );
            }
        })
        .catch((err: any) => {
            console.log(err);
        });
    }

    /** 모든 정보가 다 규칙에 맞게 입력되었는지 확인 */
    const onSubmit = (data: any) => {
        if (!firstCheck) {
            alert('이용약관에 체크해주세요.')
            return
        }

        if (!secondCheck) {
            alert('개인정보 수집 및 이용동으에 체크해주세요.')
            return
        }

        if (data.pw !== data.confirmPw) {
            setError(
                'confirmPw',
                { message: '비밀번호가 일치하지 않습니다.' },
                { shouldFocus: true },
            );
            return ;
        } 
        dupleInput("id", "아이디", data.id);
        dupleInput("nickname", "닉네임", data.nickname);
        dupleInput("email", "이메일", data.email);
        dupleInput("phone", "휴대폰 번호", data.phone);

        PostSignUp(data);
    }   

    const {
        setValue,
        register,
        formState: { isSubmitting, errors },
        handleSubmit,
        setError
    } = useForm();

    useEffect(() => {
        register("name", {
            required: "이름을 입력 해주세요.",
            pattern: {
              value: /^[가-힣]{2,5}$/,
              message: "이름 형식에 맞지 않습니다. (한글 2~5자)"
            }
        });
        register("id", {
            required: "아이디를 입력 해주세요.",
            pattern: {
                value: /^[a-z]+[a-z0-9]{5,19}$/,
                message: "아이디 형식에 맞지 않습니다. (영어 소문자, 숫자 5~19자)"
            }
        });
        register("nickname", {
            required: "닉네임을 입력 해주세요.",
            pattern: {
                value: /^(?=.*[a-z0-9가-힣])[a-z0-9가-힣]{2,15}$/,
                message: "닉네임 형식에 맞지 않습니다. (2~15자)"
            }
        });
        register("pw", {
            required: "비밀번호를 입력 해주세요.",
            pattern: {
                value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                message: "비밀번호 형식에 맞지 않습니다. (영어, 숫자, 특수문자(@, $, !, %, *, #, ?, &) 포함 8자리 이상)"
            }
        });
        register("confirmPw", {
            required: "비밀번호을 재입력 해주세요.",
            pattern: {
                value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                message: "비밀번호 형식에 맞지 않습니다. (영어, 숫자, 특수문자(@, $, !, %, *, #, ?, &) 포함 8자리 이상)"
            }
        });
        register("email", {
            required: "이메일을 입력 해주세요.",
            pattern: {
                value: /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i,
                message: "이메일 형식에 맞지 않습니다."
            }
        });
        register("phone", {
            required: "휴대폰 번호를 입력 해주세요.",
            pattern: {
                value: /^01(?:0|1|[6-9])(?:\d{3}|\d{4})\d{4}$/,
                message: "휴대폰 형식에 맞지 않습니다. ('-' 제거)"
            }
        });
    }, [register]);

    const [firstCheck, setFirstCheck] = useState(false);
    const [secondCheck, setsecondCheck] = useState(false);

    /** 전체 동의 */
    const AllCheck = () => {
        if (firstCheck || secondCheck) {
            setFirstCheck(false)
            setsecondCheck(false)
        } else {
            setFirstCheck(true)
            setsecondCheck(true)
        }
    }
    
    /** 이용약관 동의 */
    const FistCheck = () => {
        if (firstCheck) {
            setFirstCheck(false)
        } else {
            setFirstCheck(true)
        }
    }

    /** 개인정보 수집 동의 */
    const SecondCheck = () => {
        if (secondCheck) {
            setsecondCheck(false)
        } else {
            setsecondCheck(true)
        }
    }
    
    // 약관 팝업 여닫기
    const [termsOpen, setTermsOpen] = useState(false);
    const [termsTitle, setTermsTitle] = useState("");
    const [termsText, setTermsText] = useState("");

    const openTerms = (terms: { title: string; text: string; }) => {
        setTermsTitle(terms.title)
        setTermsText(terms.text)
        setTermsOpen(true);
    };

    const closeTerms = () => {
        setTermsOpen(false)
    }

    return (
        <Container>
            <Contnet>
                <TitleText>회원가입</TitleText>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <InputBox>
                        <InputBox.Label htmlFor="name">이름<span>*</span></InputBox.Label>
                        <InputBox.InputText 
                            placeholder='이름을 입력해 주세요.'
                            type='text'
                            handler={async (e: any) => setValue("name", e.target.value)}
                        />
                        { errors?.name && <InputBox.ErrorText>{`${errors?.name?.message}`}</InputBox.ErrorText> }
                    </InputBox>
                    <InputBox>
                        <InputBox.Label htmlFor="id">아이디<span>*</span></InputBox.Label>
                        <InputBox.InputText 
                            placeholder='아이디를 입력해 주세요.'
                            type='text'
                            handler={async (e: any) => setValue("id", e.target.value)}
                        />
                        { errors?.id && <InputBox.ErrorText>{`${errors?.id?.message}`}</InputBox.ErrorText> }
                    </InputBox>
                    <InputBox>
                        <InputBox.Label htmlFor="nickname">닉네임<span>*</span></InputBox.Label>
                        <InputBox.InputText 
                            placeholder='닉네임을 입력해 주세요.'
                            type='text'
                            handler={async (e: any) => setValue("nickname", e.target.value)}
                        />
                        { errors?.nickname && <InputBox.ErrorText>{`${errors?.nickname?.message}`}</InputBox.ErrorText> }
                    </InputBox>
                    <InputBox>
                        <InputBox.Label htmlFor="pw">비밀번호<span>*</span></InputBox.Label>
                        <InputBox.InputText 
                            placeholder='비밀번호를 입력해 주세요.'
                            type='password'
                            handler={async (e: any) => setValue("pw", e.target.value)}
                        />
                        { errors?.pw && <InputBox.ErrorText>{`${errors?.pw?.message}`}</InputBox.ErrorText> }
                    </InputBox>
                    <InputBox>
                        <InputBox.Label htmlFor="confirmPw">비밀번호 재확인<span>*</span></InputBox.Label>
                        <InputBox.InputText 
                            placeholder='비밀번호를 재입력해 주세요.'
                            type='password'
                            handler={async (e: any) => setValue("confirmPw", e.target.value)}
                        />
                        { errors?.confirmPw && <InputBox.ErrorText>{`${errors?.confirmPw?.message}`}</InputBox.ErrorText> }
                    </InputBox>

                    <InputBox>
                        <InputBox.Label htmlFor="email">이메일<span>*</span></InputBox.Label>                   
                        <InputBox.InputText 
                            placeholder='이메일 주소를 입력해 주세요.'
                            type='email'
                            handler={async (e: any) => setValue("email", e.target.value)}
                        />
                        { errors?.email && <InputBox.ErrorText>{`${errors?.email?.message}`}</InputBox.ErrorText> }
                    </InputBox>
                    <InputBox>
                        <InputBox.TextDiv>
                            <InputBox.Label htmlFor="phone">연락처</InputBox.Label>
                            <InputBox.SubText>*강의 개강, 이벤트, 모임 공지 등이 있을 때만 이용합니다.</InputBox.SubText>
                        </InputBox.TextDiv>
                        <InputBox.InputText 
                            placeholder='연락처를 입력해 주세요.'
                            type='tel'
                            handler={async (e: any) => setValue("phone", e.target.value)}
                        />
                        { errors?.phone && <InputBox.ErrorText>{`${errors?.phone?.message}`}</InputBox.ErrorText> }
                    </InputBox>
                    <ConsentForm>
                        <ConsentForm.Label>
                            <ConsentForm.CheckBox changeEvent={AllCheck} checked={firstCheck && secondCheck} />
                            <ConsentForm.Allcheck>전체동의</ConsentForm.Allcheck>
                        </ConsentForm.Label>
                        
                        <ConsentForm.Line />

                        <ConsentForm.Label>
                            <ConsentForm.CheckBox changeEvent={FistCheck} checked={firstCheck} />
                            <ConsentForm.Check><span>필수)</span> 이용약관</ConsentForm.Check>
                            <ConsentForm.RightArrowButton PopupOpen={() => openTerms(terms.TermsAndConditions)} />
                        </ConsentForm.Label>

                        <ConsentForm.Label>
                            <ConsentForm.CheckBox changeEvent={SecondCheck} checked={secondCheck}  />
                            <ConsentForm.Check><span>필수)</span> 개인정보 수집 및 이용동의</ConsentForm.Check>
                            <ConsentForm.RightArrowButton PopupOpen={() => openTerms(terms.PersonalInformationCollectionItem)} />
                        </ConsentForm.Label>
                    </ConsentForm>

                    <SubmitBtn
                        value='가입하기'
                        type='submit'
                        disabled={isSubmitting}
                    />
                </Form>
            {
                termsOpen && (
                    <ConsentPopup closePopup={closeTerms}>
                        <ConsentPopup.Title>{termsTitle}</ConsentPopup.Title>
                        <ConsentPopup.Text>{termsText}</ConsentPopup.Text>
                    </ConsentPopup>
                )
            }
            </Contnet>
        </Container>
    )
}

export default SignUpPage