import React from 'react'
import './style.css';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Container, Text } from './PostEditor.style';
import { useState } from 'react';
import { api } from '../../util/api';

interface Props {
  title?: string;
  type: "intro" | "post";
  value: string;
  setValue: (e: any) => void;
}

const PostEditor = ({ title, type, value, setValue }: Props) => {
    const [flag, setFlag] = useState(false);
    const imgLink = "https://onoff-house.com:8000"

    const customUploadAdapter = (loader: any) => {
    return {
        upload: () => {
            return new Promise ((resolve, reject) => {
              const data = new FormData();

              loader.file.then((file: any) => {
                data.append("postImage", file);
                data.append("location", type)
                
                api.post('/user/image/post', data, {
                  headers: {
                    'Content-Type': 'multipart/form-data'
                  }
                })
                .then((res: any) => {
                  console.log(res.data)
                  if(!flag) {
                    setFlag(true);
                  }
                  resolve({
                    default: `${imgLink}/${res.data.src}`
                  })
                })
                .catch((err: any) => {
                  console.log(err);
                })
                                
              })
            })
        }
      }
    }
    
    function uploadPlugin (editor: any){ // (3)
      editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => {
        return customUploadAdapter(loader);
      }
    }

    return (
        <Container>
          <Text>{title}</Text>
          <CKEditor
            editor={ClassicEditor}
            config={{
              placeholder: "내용을 입력하세요.",
              extraPlugins: [uploadPlugin]
            }}
            data={value}
            onChange={(e: any, editor: { getData: () => any; }) => {
              const data = editor.getData();
              // console.log(typeof(data))
              setValue(data);
            }}
          />
        </Container>
      );
}

export default PostEditor