import styled from "styled-components";
import theme from "../../styles/theme";

export const Container = styled.div`
    padding: 1rem;
    background: #FFFFFF;
    box-shadow: 0rem 0rem 0.1875rem rgba(56, 56, 56, 0.1);
    border-radius: 0.25rem;
    box-sizing: border-box;
    width: 100%;
    > hr {
        border: 1px solid ${theme.colors.brownColor2};
        /* transform: scaleY(0.5); */
        opacity: 0.5;
        width: 100%;
    }
    
`

export const Category = styled.h3`
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.375rem;
    color: ${theme.colors.mainColor};
    margin-block: 0;
    margin-right: 1rem;
`

export const DateText = styled.p`
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: ${theme.colors.greyColor1};
`

export const DetailBtn = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    > p {
        min-width: fit-content;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.1875rem;
        letter-spacing: -0.02em;
        color: ${theme.colors.mainColor};
        margin-right: 0.5rem;
    }
    > span {
        position: relative;
        top: 1px;
        margin-right: 0.5rem;
    }
    @media screen and (max-width: 576px) {
        display: none;   
    }
`

export const TicketName = styled.h1`
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 2.75rem;
    letter-spacing: -0.02em;
    color: #6B3F36;
    margin-block: 1rem 0.5rem;
`

export const BoldText = styled.p`
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.25rem;
    color: ${theme.colors.greyColor1};
`

export const SubText = styled(BoldText)`
    font-weight: 400;
    margin-block: 0.25rem;
    @media screen and (max-width: 576px) {
        font-size: 0.8125rem;
    }
`