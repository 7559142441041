import styled from "styled-components";
import { RootContainer, RootContent, RootSection, TitleText } from "../../styles/public";
import mainpage_fourthsection_background from '../../static/img/mainpage_fourthsection_background.png';
import mainpage_fourthsection_mobile_background from '../../static/img/mainpage_fourthsection_mobile_background.png';

import theme from "../../styles/theme";
export const Container = styled(RootContainer)`
    width: 100%;
    height: 100%;
`

export const FirstSection = styled(RootSection)`
    padding-block: 9rem;
    background-image: url(${mainpage_fourthsection_background});
    background-repeat : no-repeat;
    background-size: cover;

    @media screen and (max-width: 576px) {
        padding-block: 4rem;
        background-color: #F4F4F1;
        background-image: url(${mainpage_fourthsection_mobile_background});
        background-size: 150%;
        background-position: 75% 20%;
        height: 32.875rem;
    }
`

export const FirstSectionContent = styled(RootContent)`
    height: 100%;
    align-items: start;
`

export const FirstSectionTitleText = styled(TitleText)`
    font-weight: 400;
    font-size: 2.1875rem;
    line-height: 3rem;

    letter-spacing: -0.02em;
    color: ${theme.colors.mainBlackColor};

    b {
        font-weight: 800;
        color: ${theme.colors.mainColor};
    }

    @media screen and (max-width: 576px) {
        font-size: 1.5rem;
        line-height: 140%;
    }
`

export const FirstSectionSmallText = styled.p`
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 130%;
    letter-spacing: -0.02em;
    color: #736E29;

    @media screen and (max-width: 576px) {
        font-size: 0.9375rem;
        line-height: 170%;
    }
`

export const SecondSection = styled(RootSection)`
    background-color: #FCFAF6;
    padding-block: 5rem;

    @media screen and (max-width: 576px) {
        padding-block: 2rem;
    }
`

export const SecondSectionContent = styled(RootContent)`
    display: grid;
    padding-inline: 3rem;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
    row-gap: 3rem;
    width: 100%;
    @media screen and (max-width: 576px) {
        padding-inline: 0;
        grid-template-columns: 1fr;
        row-gap: 1rem;
    }
`

export const SecondSectionTitleText = styled.h1`
    font-weight: 700;
    font-size: 1.875rem;
    line-height: 2.75rem;
    letter-spacing: -0.02em;
    color: ${theme.colors.mainBlackColor};

    @media screen and (max-width: 576px) {
        font-size: 1.5rem;
        order: 1;
    }
`

export const SecondSectionBasicText = styled.p`
    
    font-weight: 700;
    font-size: 1.0625rem;
    line-height: 165%;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: ${theme.colors.greyColor1};

    @media screen and (max-width: 576px) {
        font-size: 0.9375rem;
    }
`

export const SecondSectionGrayText = styled(SecondSectionBasicText)`
    font-weight: 400;
    color: ${theme.colors.greyColor2}
`

export const TextDiv = styled.div`
    @media screen and (max-width: 576px) {
        /* text-align: center; */
    }
`

export const CircleImg = styled.div`
    width: 60%;
    aspect-ratio: 1/1;
    border-radius: 50%;
    overflow: hidden;
    :nth-child(even) {
        justify-self: end;
    }
    
    @media screen and (max-width: 576px) {
        order: 2;
        font-size: 0.9375rem;
        justify-self: center;
        margin: 0 auto;
    }
`

export const ThirdSection = styled(RootSection)`
    background: linear-gradient(90deg, rgba(234,235,229,1) 65%, rgba(0,0,0,0) 100%), url('img/admissionpage_thirdsection_background.png');
    background-repeat : no-repeat;
    background-size: contain;
    background-blend-mode: normal;
    background-position: 100% 0;
    padding-block: 4vw;

    @media screen and (max-width: 576px) {
        background: linear-gradient(180deg, rgba(234,235,229,1) 30%, rgba(0,0,0,0) 100%), url('img/admissionpage_thirdsection_background.png');
        background-position: 50% 100%;
        background-size: 150%;
        background-repeat : no-repeat;
        height: 30.3125rem;
        padding-block: 3rem;
    }
`

export const ThirdSectionContent = styled(RootContent)`
    @media screen and (max-width: 576px) {
        align-items: flex-start;
    }
`

export const ThirdSectionText = styled.p`
    font-weight: 400;
    font-size: 2rem;
    line-height: 2.5rem;
    letter-spacing: -0.02em;
    color: ${theme.colors.mainBlackColor};
    > b {
        font-weight: 700;
    }
    @media screen and (max-width: 576px) {
        font-size: 1.25rem;
        line-height: 150%;
    }
`

export const GreenButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    width: 205px;
    height: 50px;
    background: #736E29;
    border-radius: 4px;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
    border: 0;
`