import styled from 'styled-components'
import mainpage_secondsection_background from '../../static/img/mainpage_secondsection_background.png';
import mainpage_fourthsection_background from '../../static/img/mainpage_fourthsection_background.png';
import mainpage_fourthsection_mobile_background from '../../static/img/mainpage_fourthsection_mobile_background.png';
import theme from '../../styles/theme';
import { RootContent, RootContainer, RootSection, TitleText } from '../../styles/public';

export const Container = styled(RootContainer)`
    width: 100%;
`

export const FirstSection = styled(RootSection)`
    background-color: ${theme.colors.lightGreenColor};
    @media screen and (max-width: 576px) {
        padding: 1.5rem;
    }
`

export const FirstSectionContent = styled(RootContent)`
`

export const SecondSection = styled(RootSection)`
    background: linear-gradient(180deg, #EFEFDE 0%, rgba(239, 239, 222, 0) 30%), url(${mainpage_secondsection_background});
    flex-direction: column;
    padding: 3rem;
    @media screen and (max-width: 576px) {
        padding: 1.5rem;
    }
`

export const SecondSectionTopContent = styled(RootContent)`
    height: auto;
    display: grid;
    grid-template-columns: repeat(3, minmax(15rem, 23.75rem));
    grid-column-gap: 1rem;
    grid-row-gap: 5rem;

    @media screen and (max-width: 576px) {
        grid-row-gap: 0rem;
        grid-column-gap: 0rem;
        grid-template-columns: repeat(1, minmax(15rem, auto));
    }
`

export const SecondSectionBottomContent = styled(RootContent)`
    position: relative;
    flex-direction: column;
`

export const SecondSectionTopTextBox = styled.div`
    width: 100%;
    float: left;
    order: 1;
    @media screen and (max-width: 576px) {
        width: 100%;
        margin-bottom: 2rem;
    }
`
export const SecondSectionBottomTextBox = styled.div`
    order: 4;
    width: 100%;
    @media screen and (max-width: 576px) {
        width: 100%;
        margin-bottom: 2rem;
        order: 3;
    }
`

export const SecondSectionBottomText = styled(TitleText)`
    margin: 5rem 0;
    
    > b {
        font-weight: 700;
        color: ${theme.colors.mainColor};
    }
    @media screen and (max-width: 576px) {
        width: 100%;
        float: left;
        margin-block: 2rem;
        white-space: pre-wrap;
    }
`

export const ThirdSection = styled(RootSection)`
    background: #B0A777;
    padding-block: 1.5rem;
    
    @media screen and (max-width: 576px) {
        padding: 1.5rem;
    }
`
export const ThirdSectionContent = styled(RootContent)`
    > div:nth-child(1) {
        width: 100%;
        p {
            font-weight: 400;
            font-size: 2rem;
            line-height: 2.5rem;
            letter-spacing: -0.02em;
            color: #FFFFFF;
        }
        b {
            font-weight: 700;
        }
    }
    
    > div:nth-child(2) {
        width: 60%;
        /* height: auto; */
        margin-block: 3rem;
    }

    @media screen and (max-width: 576px) {
        flex-direction: column;

        > div:nth-child(1) {
            p {
                font-size: 1.25rem;
            }
        }
        > div:nth-child(2) {
            width: 100%;
            height: auto;
            margin-bottom: 0;
        }
    }
`

export const ThirdSectionText = styled.p`
    font-weight: 400;
    font-size: 2rem;
    line-height: 3.125rem;
    letter-spacing: -0.02em;
    color: #FFFFFF;
    margin-top: 0;
    > span {
        font-weight: 700;
    }
`
export const GreenButton = styled.button`
    background: #736E29;
    border-radius: 0.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 0.875rem 1.25rem; */
    width: 7.5rem;
    height: 3.125rem;
    border: 0;
    cursor: pointer;
    /* 폰트 */
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.375rem;
    color: #FFFFFF;
`

export const FourthSection = styled(RootSection)`
    padding-block: 3.125rem;
    background-image: url(${mainpage_fourthsection_background});
    background-repeat : no-repeat;
    background-size: cover;
    display: flex;
    justify-content: flex-start;

    @media screen and (max-width: 576px) {
        background-color: #F4F4F1;
        background-image: url(${mainpage_fourthsection_mobile_background});
        background-size: cover;
        background-position: 45% 10%;
        padding: 1.5rem;
    }
`

export const FourthSectionContent = styled(RootContent)`
    div {
        margin-block: 6rem;
    }
`

export const BrownButton = styled.button`
    background-color: ${theme.colors.mainColor};
    cursor: pointer;
    border-radius: 0.25rem;
    margin-top: 16px;
    width: 13.75rem;
    height: 3.4375rem;
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.375rem;
    color: #FFFFFF;
    border: 0;
    @media screen and (max-width: 576px) {
        width: 160px;
        height: 2.8125rem;
        font-size: 16px;
    }
`

export const RibbonCard = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding: 1rem 2rem;
    background: #FFFFFF;
    box-shadow: 0rem 0.0625rem 0.5rem rgba(152, 152, 152, 0.1);
    border-radius: 0.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    order: 3;
    div {
        display: flex;
        flex-direction: column;
        h3 {
            font-weight: 700;
            font-size: 1.125rem;
            line-height: 1.625rem;
            color: ${theme.colors.mainColor};
        }
        p {
            font-weight: 400;
            font-size: 1rem;
            line-height: 1.75rem;
            letter-spacing: -0.02em;
            color: #4D4444;
        }
    }

    @media screen and (max-width: 576px) {
        order: 4;
        width: 100%;
        margin-block: 0.5rem;
    }
`
export const UserIconLighter = styled.div`
`

export const ScouterPattern = styled.div`
    margin-inline: 5px;
`