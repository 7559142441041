import React from 'react'
import SubjectCard from '../../components/SubjectCard/SubjectCard.component'
import { BottomContent, BottomSection, Container } from './SubjectsPage.style'
import TopSection from '../../components/TopSection/TopSection.component'
import { FlexRow } from '../../styles/public'
import { subjects } from '../../static/subjects/subjects'

const EducationSubjectPage = () => {
  return (
    <Container>
        <TopSection
            background="linear-gradient(to right, #FCFAF5 20%, rgba(252, 250, 245, 0) 50%), radial-gradient(rgba(239, 239, 222, 0.2), rgba(239, 239, 222, 0.2)), url('/img/subjectspage_background.png');"
            backgroundPosition='center'
            backgroundSize='cover'
        >
            <TopSection.Title>교육 과목</TopSection.Title>
            <TopSection.BasicText>직장을 탈출하기 위해서 우리는 학교에서 가르치지 않는 걸 배워야 합니다.</TopSection.BasicText>
        </TopSection>

        <BottomSection>
            <BottomContent>
            { subjects.map((subject, i) => { return (
                <SubjectCard category={subject.category}>
                    <SubjectCard.ThumbnailContainer>
                        <SubjectCard.Thumbnail src={subject.thumbnail} />
                    </SubjectCard.ThumbnailContainer>
                    <SubjectCard.TextContainer>
                        <FlexRow>
                            <SubjectCard.CategoryBox>{subject.kr_category}</SubjectCard.CategoryBox>
                            <SubjectCard.TitleText>{subject.title}</SubjectCard.TitleText>
                        </FlexRow>
                    </SubjectCard.TextContainer>
                </SubjectCard>
            )})}
            </BottomContent>
        </BottomSection>
    </Container>
  )
}

export default EducationSubjectPage