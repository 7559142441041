import React, { useRef, useEffect, useState } from "react";
import {
  Container,
  TabsContainer,
  TabPanelContainer,
  TabHeaderContainer,
  StylizedTab,
  StyledTabPanel,
  TabsHolder,
  TabSlider,
  TapLine,
} from "./TopTabSlider.style";

interface Props {
  TabElements: {label: string, container: JSX.Element }[]
  type?: string
  tapNumber?: number
}

const Tab = ({ label, active, onClick }: any) => {
  return (
    <StylizedTab role="tab" active={active} onClick={onClick}>
      {label}
    </StylizedTab>
  );
};

const Tabs = ({ selectedTab, onChange, children }: any) => {
  const containerRef = useRef<any>(null);
  const [containerWidth, setContainerWidth] = useState<any>(0);
  
  useEffect(() => {
    setContainerWidth(containerRef.current.getBoundingClientRect().width);
  }, [containerRef]);
  
  const sliderWidth = children.length;
  // console.log(containerWidth)
  const tabs = children.map((child: any) => {
    const handleClick = (e: any) => {
      onChange(e, child.props.value);
    };

    return React.cloneElement(child, {
      key: child.props.value,
      active: child.props.value === selectedTab,
      onClick: handleClick,
    });
  });

  return (
    <TabHeaderContainer ref={containerRef}>
      <TabsHolder>{tabs}</TabsHolder>
      <TabSlider width={sliderWidth} index={selectedTab} />
      <TapLine />
    </TabHeaderContainer>
  );
};

const TabPanel = ({ children, value, selectedIndex }: any) => {
  const hidden = value !== selectedIndex;

  return (
    <StyledTabPanel hidden={hidden} active={!hidden}>
      {children}
    </StyledTabPanel>
  );
};

const TopTabSlider = ({ TabElements, tapNumber = 0 }: Props) => {
  const [activeTab, setActiveTab] = useState(tapNumber);

  const handleChange = (e: any, value: any) => {
    setActiveTab(value);
  };
  
  return (
    <Container>
      <TabsContainer>
        <Tabs selectedTab={activeTab} onChange={handleChange}>
          {
            TabElements.map((element, i) => {
              return(
                <Tab label={element.label} value={i} key={`tabheader${i}`}></Tab>
              )
            })
          }
        </Tabs>
      </TabsContainer>

      <TabPanelContainer>
          {
            TabElements.map((element, i) => {
              return(
                <TabPanel value={activeTab} selectedIndex={i} key={`tabpanel${i}`}>
                  {element.container}
                </TabPanel>
              )
            })
          }
      </TabPanelContainer>
    </Container>
  );
};

export default TopTabSlider;
