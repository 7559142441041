import styled, {css} from "styled-components";
import theme from "../../styles/theme";

export const Container = styled.div`
    position: relative;
    width: 100%;
    border-radius: 4px;
`

export const Tab = styled.div`
    border-bottom: 1px solid #ECECEC;
`

export const TitleContainer = styled.div<{ active : boolean }>`
  margin: 0;
  padding: 1.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  background: ${props => props.active ? "#F6F6F5" : "#FFFFFF"};
`;

export const Div = styled.div`
    display: flex;
    align-items: center;
`

export const TitleText = styled.h3<{ active : boolean }>`
  margin: 0;
  font-weight: ${props => props.active ? "700" : "400"};
  font-size: 1.125rem;
  line-height: 1.375rem;

  color: ${props => props.active ? theme.colors.mainColor : "#000000"};
`

export const Icon = styled.div`
  margin-right: 1rem;
`


export const BodyContainer = styled.div<{ active : boolean, bodyHeight: number }>`
  display: block;
  position: relative;
  padding: 0;
  margin: 0;
  height: 0;
  overflow: hidden;
  transition: height 0.3s;
  background-color: #FFFFFF;
  ${({ active, bodyHeight }) =>
    active &&
    css`
      height: ${bodyHeight}px;
    `}
`;

export const Content = styled.div`
  margin: 0;
  padding: 2rem 3rem;
  height: auto;
  
  > p {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.1875rem;
    color: ${theme.colors.mainBlackColor};
  }
  @media screen and (max-width: 576px) {
    padding: 1rem;
  }
`;