import React, { ReactNode } from 'react'
import { Container, Title, SubTitle, QnAContainer, QnABox, Question, Answer, Input, Button } from './TitleContent.style'
interface Props {
    children: ReactNode
}

interface InputProps {
    value: string;
    setValue: (e: any) => void;
}

interface AddButtonProps {
    children: ReactNode;
    handler: () => void;
}

interface DelButtonProps {
    children: ReactNode;
    handler: (e: any) => void;
}

const TitleContent = ({ children }: Props) => {
  return <Container>{children}</Container>
}
TitleContent.Title = ({ children }: Props) => {
    return <Title>{ children }</Title>
}

TitleContent.SubTitle = ({ children }: Props) => {
    return <SubTitle>{ children }</SubTitle>
}

TitleContent.Container = ({ children }: Props) => {
    return <QnAContainer>{ children }</QnAContainer>
}

TitleContent.Box = ({ children }: Props) => {
    return <QnABox>{children}</QnABox>
}

TitleContent.Question = ({ children }: Props) => {
    return <Question>{children}</Question>
}

TitleContent.Answer = ({ children }: Props) => {
    return <Answer>{children}</Answer>
}

TitleContent.Input = ({ value, setValue }: InputProps) => {
    return <Input placeholder={`내용을 입력하세요.`} value={value} onChange={(e) => setValue(e.currentTarget.value)} />
}

TitleContent.AddButton = ({ handler, children }: AddButtonProps) => {
    return <Button
        onClick={handler}
    >{children}</Button>
}

TitleContent.DelButton = ({ handler, children }: DelButtonProps) => {
    return <Button
        onClick={handler}
    >{children}</Button>
}

export default TitleContent