import React, { ReactNode } from 'react'
import { icons } from '../../static/icons/icon';
import { AllCheckText, Label, CheckBox, CheckText, Container, RightArrowButton, Line } from './ConsentForm.styled';


interface Props {
    children: ReactNode;
}

interface CheckBoxProps {
    changeEvent: () => void;
    checked: boolean;
}

interface ArrowProps {
    PopupOpen: () => void;
}

const ConsentForm = ({ children }: Props) => {
  return <Container>{children}</Container>
}

ConsentForm.Label = ({ children }: Props) => {
    return <Label>{children}</Label>
}

ConsentForm.CheckBox = ({ changeEvent, checked }: CheckBoxProps) => {
    return <CheckBox 
                type="checkbox" 
                onChange={changeEvent}
                checked={checked}
            />
}

ConsentForm.Allcheck = ({ children }: Props) => {
    return <AllCheckText>{children}</AllCheckText>
}

ConsentForm.Check = ({ children }: Props) => {
    return <CheckText>{children}</CheckText>
}

ConsentForm.RightArrowButton = ({ PopupOpen }: ArrowProps) => {
    return <RightArrowButton onClick={PopupOpen}>{icons.brownrightarrow}</RightArrowButton>
}

ConsentForm.Line = () => {
    return <Line />
}

export default ConsentForm