import React, { ReactNode, useEffect } from 'react'
import { icons } from '../../static/icons/icon';
import { Background, Container, CloseButton, Box, Title, Text} from './ConsentPopup.styled';
interface Props {
    isOpen?: boolean
    closePopup?: () => void
    children: ReactNode
}

const ConsentPopup = ({ children, closePopup }: Props) => {
    // 스크롤 잠금
    useEffect(() => {
        document.body.style.cssText = `
        position: fixed; 
        top: -${window.scrollY}px;
        overflow-y: scroll;
        width: 100%;`;
      return () => {
        const scrollY = document.body.style.top;
        document.body.style.cssText = '';
        window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
      };
    }, []);

    return (
        <Background>
            <Container>
                <CloseButton onClick={closePopup}>{icons.closeButton}</CloseButton>
                <Box>
                    {children}
                </Box>
            </Container>
        </Background>
    )
}

ConsentPopup.Title = ({ children }: Props) => {
    return <Title>{children}</Title>
}

ConsentPopup.Text = ({ children }: Props) => {
    return <Text>{children}</Text>
}

export default ConsentPopup