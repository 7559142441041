import styled from "styled-components";
import { RootContainer, RootContent, RootSection } from "../../styles/public";
import subjectpage_background from '../../static/img/subjectpage_background.png';
import theme from "../../styles/theme";

export const Container = styled(RootContainer)`
    background-color: ${theme.colors.background2};
 `

export const TopSection = styled(RootSection)`
    position: absolute;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(${subjectpage_background});
    padding-block: 8vw;
    
    @media screen and (max-width: 576px) {
        display: none;
    }
`

export const Content = styled(RootContent)`
    height: 100%;
    padding: 5rem 1rem;
    display: grid;
    grid-template-columns: 7fr 4fr;
    grid-gap: 5rem;
    box-sizing: border-box;

    @media screen and (max-width: 576px) {
        display: flex;
        flex-direction: column;
        padding: 1rem 1rem;
    }
`

export const LeftContainer = styled.div`
    width: 100%;
    height: 100%;
`

export const ThumbnailContainer = styled.div<{backgroundImage: string}>`
    position: relative;
    width: 100%;
    aspect-ratio: 16/9;
    background-image: url(${props => props.backgroundImage});
    background-repeat : no-repeat;
    background-size : cover;    
    /* background-color: grey; */
    border-radius: 4px;
` 
export const Thumbnail = styled.img`
    width: 100%;
    height: 100%;
`

export const PlayIcon = styled.div`
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`

export const RightContainer = styled.div`
    width: 100%;
    height: 100%;
    @media screen and (max-width: 576px) {
        display: none;
    }
`