import React, { Dispatch, SetStateAction, useEffect } from 'react'
import { Container, Content, ChangePasswordBtn, FixBtn } from './UserInfoModificationContainer.style'
import InputBox from '../../components/InputBox/InputBox.component'
import { FlexRowCenter, Form } from '../../styles/public'
import { useState } from 'react'
import PopupBox from '../../components/PopupBox/PopupBox.component'
import { api } from '../../util/api'
import LoadingBox from '../../components/LoadingBox/LoadingBox.component'
import { useForm } from "react-hook-form"

interface Props {
  userID: string;
  userName: string;
  userNickname: string;
  userEmail: string;
  userPhone: string;
  userEmailHandler: Dispatch<SetStateAction<string>>;
}

const UserInfoModificationContainerHome = ({ 
  userID, 
  userName, 
  userNickname, 
  userEmail, 
  userPhone,
  userEmailHandler
} : Props) => {
  const [popupOpen, setPopupOpen] = useState(false);
  const openPopup = () => {
    setPopupOpen(true);
  };
  const closePopup = () => {
    setPopupOpen(false);
  };

  const [loading, setLoading] = useState(false);
  const openLoading = () => {
    setLoading(true);
  };
  const closeLoading = () => {
    setLoading(false);
  };

  /** 비밀번호 변경 메일 전송 요청 */
  const resetPassword = () => {
    openLoading();
    api.get('/user/mail/send/password')
    .then((res: any) => {
      if (!res.data.err) {
          closeLoading();
          openPopup();
      } else {
          closeLoading();
          alert('시스템 오류입니다.')
      }
    })
    .catch((err: any) => {
        closeLoading();
        alert('시스템 오류입니다.')
        console.log(err);
    });
  }

  /** 정보 변경 요청 */
  const modifySubmit = (data: any) => {
    api.put('/user/edit/info', {
      "email": data.email, // 변경할 이메일
      "phone": data.phone // 변경할 전화
    })
    .then((res: any) => {
      if (!res.data.err) {
        alert('정보가 수정 되었습니다.');
        userEmailHandler(data.email)
      } else {
        alert('정보를 수정하지 못 했습니다.');
      }
    })
    .catch((err: any) => {
        console.log(err);
    });
  }

  const {
    setValue,
    register,
    formState: { isSubmitting, errors },
    handleSubmit
  } = useForm();

  useEffect(() => {
    register("email", {
      required: "변경 할 이메일을 입력 해주세요.",
      pattern: {
        value: /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i,
        message: "이메일 형식에 맞지 않습니다."
      }
    });
    
    register("phone", {
      required: "변경 할 휴대폰 번호를 입력 해주세요.",
      pattern: {
        value: /^01(?:0|1|[6-9])(?:\d{3}|\d{4})\d{4}$/,
        message: "휴대폰 형식에 맞지 않습니다. ('-' 제거)"
      }
    });
  }, [register]);

  setValue("email", userEmail)
  setValue("phone", userPhone)

  return (
    <Container>
      <Content>
      <Form onSubmit={handleSubmit(modifySubmit)}>
          <InputBox>
            <InputBox.Label>이름</InputBox.Label>
            <InputBox.InputText 
                placeholder='연락처를 입력해 주세요.'
                type='text'
                value={userName}
                disabled={true}
            />
          </InputBox>
          <InputBox>
              <InputBox.Label>아이디</InputBox.Label>
              <InputBox.InputText 
                  placeholder='아이디를 입력해 주세요.'
                  type='text'
                  value={userID}
                  disabled={true}
              />
          </InputBox>
          <InputBox>
              <InputBox.Label>닉네임</InputBox.Label>
              <InputBox.InputText 
                  placeholder='닉네임을 입력해 주세요.'
                  type='text'
                  value={userNickname}
                  handler={async (e: any) => setValue("nickname", e.target.value)}
                  disabled={true}
              />
          </InputBox>
          <InputBox>
              <InputBox.Label htmlFor="email">이메일</InputBox.Label>
              <InputBox.InputText 
                  placeholder='이메일 주소를 입력해 주세요.'
                  type='email'
                  value={userEmail}
                  handler={async (e: any) => setValue("email", e.target.value)}
                  disabled={false}
              />
              { errors?.email && <InputBox.ErrorText>{`${errors?.email?.message}`}</InputBox.ErrorText> }
          </InputBox>
          <InputBox>
              <InputBox.Label htmlFor="phone">휴대폰번호</InputBox.Label>
              <InputBox.InputText 
                  placeholder='연락처를 입력해 주세요.'
                  type='tel'
                  value={userPhone}
                  handler={async (e: any) => setValue("phone", e.target.value)}
                  disabled={false}
              />
              { errors?.phone && <InputBox.ErrorText>{`${errors?.phone?.message}`}</InputBox.ErrorText> }
          </InputBox>
          <FlexRowCenter>
            <ChangePasswordBtn 
              type="button" 
              onClick={() => resetPassword()}
              value="비밀번호 변경" 
            />
            <FixBtn
              value='수정하기'
              type='submit'
              disabled={isSubmitting}
            />
          </FlexRowCenter>
      </Form>

      </Content>
      {
        popupOpen && (
          <PopupBox>
            <PopupBox.Text>고객님의 이메일로 메일이 발송되었습니다.</PopupBox.Text>
            <PopupBox.Btn closePopup={closePopup}>확인</PopupBox.Btn>
          </PopupBox>
        )
      }
      { loading && ( <LoadingBox /> ) }
      
    </Container>
  )
}

export default UserInfoModificationContainerHome

function userEmailHandler(email: any) {
  throw new Error('Function not implemented.')
}
