import styled, { css } from "styled-components";
import { FlexRow } from "../../styles/public";
import theme from "../../styles/theme";

export const Container = styled(FlexRow)`
    margin-block: 3rem;
    display: flex;
    justify-content: center;
`

export const Btn = styled.button<{active?: boolean}>`
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0;
    border: 0;
    background-color: transparent;
    font-family: 'Pretendard';
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375rem;
    margin-inline: 1rem;

    color: ${theme.colors.greyColor2};

    :disabled {
        color: ${theme.colors.mainColor};
        font-weight: 700;
    }
`