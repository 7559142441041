import styled from "styled-components";
import theme from "../../styles/theme";

export const Container = styled.div`
    width: 100%;
`

export const Text = styled.p`
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 1.625rem;
    margin-block: 1rem;
    color: ${theme.colors.mainColor};
`