import React, { ReactNode } from 'react'
import { Container, TitleText, RadioGroup, Radio } from './InputPaymentMethod.style'

interface Props {
    children: ReactNode
}

interface RadioProps {
    name?: string;
    label?: string;
    value?: string;
    isChecked?: boolean;
    handleChange?: any;
}

const InputPaymentMethod = ({children}: Props) => {
  return <Container>{children}</Container>
}

InputPaymentMethod.TitleText = ({children}: Props) => {
    return <TitleText>{children}</TitleText>
}

InputPaymentMethod.RadioGroup = ({children}: Props) => {
    return <RadioGroup>{children}</RadioGroup>
}

InputPaymentMethod.Radio = ({ name, value, label, isChecked, handleChange }: RadioProps) => {
    const handleRadioChange = (e: any) => {
        const { id } = e.currentTarget;
        handleChange(id); // Send back id to radio group for comparison
    };
    return (
        <Radio>
            <input 
                type="radio" 
                name={name} 
                id={value} 
                value={value} 
                checked={isChecked} 
                onChange={handleRadioChange} 
            />
            <label htmlFor={value}>
                {label}
            </label>
        </Radio>
    
    )
}



export default InputPaymentMethod