import styled from "styled-components";
import theme from "../../styles/theme";

export const Table = styled.table`
    width: 100%;
    border-top: 1px solid ${theme.colors.greyColor3};
    border-collapse: collapse;
`

export const Td = styled.td`
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid ${theme.colors.greyColor3};
    height: 6.5rem;
    box-sizing: border-box;
    padding-inline: 1rem;
    @media screen and (max-width: 576px) {
        height: 6rem;
    }
`

export const FixationText = styled.p`
    margin: 0 0.5rem;

    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.625rem;
    color: ${theme.colors.mainColor};
`

export const TitleText = styled.h1`
    margin: 0;
    font-weight: 500;
    font-size: 1.1875rem;
    line-height: 1.625rem;

    color: ${theme.colors.mainBlackColor};
    @media screen and (max-width: 576px) {
        font-size: 0.875rem;
        line-height: 160%;
    }
`

export const GreyText = styled.p`
    margin: 0;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.625rem;
    color: ${theme.colors.greyColor2};
    @media screen and (max-width: 576px) {
        font-weight: 400;
        font-size: 0.8125rem;
        line-height: 160%;
    }
`