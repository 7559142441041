import styled from "styled-components";

export const Container = styled.div`
    padding-block: 1rem;
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(36.25rem, 1fr));
    grid-gap: 20px;

    @media screen and (max-width: 576px) {
        grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    }
`