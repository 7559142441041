import HTMLReactParser from 'html-react-parser';
import React, { useRef, useState } from 'react'
import { icons } from '../../static/icons/icon';
import { Container, Tab, TitleContainer, TitleText, Icon, BodyContainer, Content } from './FAQAccordionTab.style'; 
interface Props {
  FAQList?: Array<{ q: string, a: string}>
}


const AccordionTab = ({ FAQList } : Props) => {
  const [currentAccordion, setCurrentAccordion] = useState(-1);
  const [bodyHeight, setBodyHeight] = useState(0);
  
  const item0 = useRef<any>(null);
  const item1 = useRef<any>(null);
  const item2 = useRef<any>(null);
  const item3 = useRef<any>(null);
  const item4 = useRef<any>(null);
  const item5 = useRef<any>(null);
  const item6 = useRef<any>(null);
  const item7 = useRef<any>(null);
  const item8 = useRef<any>(null);
  const item9 = useRef<any>(null);

  const refs = [item0, item1, item2, item3, item4, item5, item6, item7, item8, item9];

  return (
    <Container>
      {
        FAQList.map(({ q, a }, i) => {
          // 현재 선택 되었는지 확인
          const active = currentAccordion === i;
          return (
            <Tab key={`accordion-item-${i}`}>
              <TitleContainer
                active={active}
                onClick={() => {
                  active ? setCurrentAccordion(-1) : setCurrentAccordion(i);
                  setBodyHeight(refs[i].current?.clientHeight);
                }}
              >
                  <Icon>{active ? icons.upArrow : icons.downArrow }</Icon>
                  <TitleText active={active}>{q}</TitleText>
              </TitleContainer>
              <BodyContainer active={active} bodyHeight={bodyHeight}>
                <Content ref={refs[i]}>{HTMLReactParser(a)}</Content>
              </BodyContainer>
            </Tab>
          )
      })}
    </Container>
  )
}

export default AccordionTab