import { DefaultTheme } from "styled-components";

const theme: DefaultTheme = {
    colors : {
        mainColor: '#6B3F36',
        background: '#FCFAF6',
        background2: '#FEFDFB',
        mainBlackColor: '#2D1009',
        greyColor1: '#4D4444',
        greyColor2: '#958D8D',
        greyColor3: '#D7D7D7',
        brownColor1: '#A6756A',
        brownColor2: '#D7BFB9',
        oliveColor1: '#A6945D',
        oliveColor2: '#D9CEAC',
        oliveGreenColor: '#736E29',
        whiteColor: '#FFFFFF',
        lightGreenColor: '#EFEFDE',
        lightYellowColor: '#FCFAF6'
    }
}

export default theme;

