import styled from "styled-components";
import { RootContainer, RootContent, RootSection } from "../../styles/public";
import theme from "../../styles/theme";

export const Container = styled(RootContainer)`

`

export const Section = styled(RootSection)`
    
`

export const Content = styled(RootContent)`
    flex-direction: column;
`

export const CommentNumber = styled.h3`
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.75rem;
    color: ${theme.colors.mainBlackColor};
`

export const ModifiedAndDelete = styled.div`
    display: flex;
`

export const Modified = styled.p`
    cursor: pointer;
`

export const Bar = styled.p`
    margin-inline: 1rem;
`


export const Delete = styled(Modified)`
`

export const ButtonBox = styled.div`

`

export const PinButton = styled.button<{active: boolean}>`
    cursor: pointer;
    border: 0;
    border-radius: 50%; 
    width: 3.125rem;
    height: 3.125rem;
    background-color: ${props => props.active ? `${theme.colors.mainColor}` : `${theme.colors.greyColor3}`};
    color: white;
    margin-inline: 1rem;
`

export const CircleButton = styled.button`
    cursor: pointer;
    border: 0;
    border-radius: 50%; 
    width: 3.125rem;
    height: 3.125rem;
    background-color: ${theme.colors.greyColor3};
    color: white;
    margin-inline: 1rem;
`