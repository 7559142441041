import styled from "styled-components";
import theme from "../../styles/theme";

export const WriteBtn = styled.button`
    cursor: pointer;
    border: 0;
    width: 4.6875rem;
    height: 2.625rem;
    background: ${theme.colors.mainColor};
    border-radius: 0.25rem;
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.125rem;
    color: #FFFFFF;
`