import styled from "styled-components";
import theme from "../../styles/theme";

export const Container = styled.div`
    cursor: pointer;
    width: 100%;
`

export const ThumbnailContainer = styled.div`
    position: relative;
    width: 100%;
    aspect-ratio: 16/9;
    overflow: hidden;
    background-color: grey;
    border-radius: 10px;
` 
export const Thumbnail = styled.img`
    width: 100%;
    height: 100%;
`

export const PlayIcon = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`

export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
`

export const TitleContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-block: 0.5rem;
`

export const CategoryBox = styled.div`
    width: 52px;
    height: 28px;
    background: ${theme.colors.mainColor};
    border-radius: 2px;
    margin-right: 0.5rem;
    font-weight: 600;
    font-size: 14px;
    line-height: 28px;
    text-align: center;
    line-height: 28px;
    color: #FFFFFF;
` 

export const TitleText = styled.h1`
    font-weight: 700;
    font-size: 20px;
    line-height: 18px;
    display: flex;
    align-items: center;

    color: ${theme.colors.mainColor};
`

export const DescriptionText = styled.p`
    margin: 0;
    font-weight: 400;
    font-size: 17px;
    line-height: 26px;

    letter-spacing: -0.02em;


    color: ${theme.colors.greyColor1};

`