import HTMLReactParser from 'html-react-parser'
import React from 'react'
import { Container, ContentBox, TitleText } from './IntroduceSubjectContainer.style'

interface Props {
  intro: string
}


const IntroduceSubjectContainer = ({ intro }: Props) => {
  return (
    <Container>
      <TitleText>
        강의소개
      </TitleText>
      <ContentBox>
        {HTMLReactParser(intro)}
      </ContentBox>
    </Container>
  )
}

export default IntroduceSubjectContainer