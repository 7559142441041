import { useEffect } from "react";
import { useLocation } from "react-router-dom";

/*
    화면 전환 했을 때 스크롤 맨 위로 올라가게 하는 기능
*/

const ScrollToTop = (): any => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export default ScrollToTop;