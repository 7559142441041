import styled from "styled-components";
import theme from "../../styles/theme";

export const Container = styled.div`
    border: 1px solid ${theme.colors.greyColor3};
    border-radius: 4px;
    padding: 0.5rem 1rem;
    margin-block: 2rem;
    box-sizing: border-box;
`

export const CheckBox = styled.input`
    appearance: none;
    width: 1.375rem;
    height: 1.375rem;
    border: 1px solid ${theme.colors.greyColor3};
    border-radius: 0.25rem;
    
    &:checked {
        border-color: transparent;
        background-image: url("data:image/svg+xml,%3csvg viewBox='-4 0 20 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M10.6654 1.5L4.2487 7.91667L1.33203 5' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e");
        background-size: 100% 100%;
        background-position: 50%;
        background-repeat: no-repeat;
        background-color: ${theme.colors.mainColor};
    }
`

export const Label = styled.label`
    display: flex;
    align-items: center;
    user-select: none;
    width: 100%;
`

export const AllCheckText = styled.p`
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 100%;
    letter-spacing: -0.03em;
    margin-left: 0.25rem;
    color: ${theme.colors.mainBlackColor};
`

export const CheckText = styled.p`
    font-weight: 500;
    font-size: 1rem;
    line-height: 100%;
    letter-spacing: -0.03em;
    margin-left: 0.25rem;
    color: ${theme.colors.mainBlackColor};
    > span {
        color: ${theme.colors.mainColor};
    }
`

export const Line = styled.hr`
    border: 1px solid ${theme.colors.greyColor3};
    transform: scaleY(0.5);
`

export const RightArrowButton = styled.div`
    cursor: pointer;
    margin-left: auto;
`