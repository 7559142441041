import React, { useEffect, useState } from 'react'
import { 
  Container,
  FirstSection,
  FirstSectionContent,
  SecondSection,
  ThirdSection,
  ThirdSectionContent,
  GreenButton,
  FourthSection,
  FourthSectionContent,
  BrownButton,
  SecondSectionTopTextBox,
  SecondSectionBottomText,
  SecondSectionTopContent,
  SecondSectionBottomContent
} from './MainPage.style'

import {
  TitleText,
  Bold,
  BasicText,
  GrayText,
  Img,
  StyledLink
} from '../../styles/public'
import theme from '../../styles/theme'
import ImageSlider from '../../components/ImageSlider/ImageSlider.component'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import ImageCard from '../../components/ImageCard/ImageCard.component'
import ReviewCardSlider from '../../container/ReviewCardSlider/ReviewCardSlider.Component'
import RenewalPopup from '../../components/RenewalPopup/RenewalPopup.component'
import { api } from '../../util/api'

const MainPage = () => {
  const navigate = useNavigate();
  const [columns, setColumns] = useState<Array<{post_id: string, title: string}>>([]);

  const getColumns = () => {
    api.get('/posts/column/main')
      .then((res: any) => {
        setColumns(res.data.post)
      })
      .catch((err: any) => {
        console.log(err);
    })
  }

  useEffect(() => {
    getColumns();
  }, [])

  return (
    <Container>
      {/* <RenewalPopup /> */}
      <FirstSection>
        <FirstSectionContent>
          <ImageSlider />
        </FirstSectionContent>
      </FirstSection>
      
      <SecondSection>
        <SecondSectionTopContent>
          <SecondSectionTopTextBox>
            <TitleText>칼럼만 읽어도<br /><Bold>직장 탈출이 가능합니다.</Bold></TitleText>
            <BasicText>기존의 고정관념을 깨지 않으면 당신의 인생은 절대 변하지 않습니다. 칼럼만 읽어도 여러분의 삶을 충분히 바꿀 수 있습니다.</BasicText>
            <Link to="/column"><BrownButton>6가지 칼럼 읽기</BrownButton></Link>
          </SecondSectionTopTextBox>

        { columns.length > 0 && <>
          <ImageCard handler={() => navigate(`/column/post/${columns[0].post_id}`)}>
            <Img src={require('../../static/img/mainpage_secondsection_picture1.png')}></Img>
            <ImageCard.TextBox>
              <ImageCard.Title>칼럼 1</ImageCard.Title>
              <ImageCard.Text>{columns[0].title}</ImageCard.Text>
            </ImageCard.TextBox>
          </ImageCard>

          <ImageCard handler={() => navigate(`/column/post/${columns[1].post_id}`)}>
            <Img src={require('../../static/img/mainpage_secondsection_picture2.png')}></Img>
            <ImageCard.TextBox>
              <ImageCard.Title>칼럼 2</ImageCard.Title>
              <ImageCard.Text>{columns[1].title}</ImageCard.Text>
            </ImageCard.TextBox>
          </ImageCard>
        </> }
          

        </SecondSectionTopContent>
        <SecondSectionBottomContent>
          <SecondSectionBottomText>{`"쉽지 않겠죠, 
하지만`} <b>{`시도한 사람들은 쉽게 성공하고
돈을 법니다."`}</b></SecondSectionBottomText>

          <ReviewCardSlider />
        </SecondSectionBottomContent>   
      </SecondSection>
        
      <ThirdSection>
        <ThirdSectionContent>
          <div>
            <p color={theme.colors.whiteColor}>직장탈출학교에서 <b>누구나 받을 수 있는<br />장학금도 받아가세요.</b></p>
            <StyledLink to="/scholarship/1"><GreenButton>자세히 보기</GreenButton></StyledLink>
          </div>
          <div>
            <Img src={require('../../static/img/mainpage_thirdsection_picture.png')} />
          </div>
        </ThirdSectionContent>
      </ThirdSection>


      <FourthSection>
        <FourthSectionContent>
          <div>
            <TitleText>당신의 인생에 <Bold>터닝포인트가</Bold> <br />되길 바랍니다.</TitleText>
            <BasicText>저는 28살 때부터 직장 탈출을 준비했고 불과 2년만에 연봉 3억을 만들게 됩니다. 도대체 뭘 했길래 이런 일이 생길 수 있었을까요?</BasicText>
            <GrayText>직장탈출학교 대표 내건주</GrayText>
            <Link to="/admission"><BrownButton>직장탈출학교 입학하기</BrownButton></Link>
          </div>
        </FourthSectionContent>
      </FourthSection>
    </Container>
  )
}

export default MainPage