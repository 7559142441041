export const conversionDate = (t: number) => {
    var date = new Date(t);
    var year = date.getFullYear();
    var month = "0" + (date.getMonth() + 1);
    var day = "0" + date.getDate();
    return `${year}.${month.substr(-2)}.${day.substr(-2)}`;
}

export const conversionTime = (t: number) => {
    var date = new Date(t);
    var hour = "0" + date.getHours();
    var minute = "0" + date.getMinutes();
    var second = "0" + date.getSeconds();
    return hour.substr(-2) + ":" + minute.substr(-2) + ":" + second.substr(-2);
}

export const conversionMilliSec = (d: string) => {
    d = d.replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3')
    var milliSec = new Date(d)
    return milliSec.getTime()
}

export const getTomorrow = () => {
    var today = new Date();
    var tomorrow = new Date(today.setDate(today.getDate() + 1));
    var year = tomorrow.getFullYear();
    var month = ("0" + (1 + tomorrow.getMonth())).slice(-2);
    var day = ("0" + tomorrow.getDate()).slice(-2);

    return year + month + day;
}