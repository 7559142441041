import styled from "styled-components";
import { RootContainer, RootContent } from "../../styles/public";
import theme from "../../styles/theme";

export const Container = styled(RootContainer)`
    background-color: ${theme.colors.background};
    padding-block: 3rem 14rem;
    padding-inline: 1rem;
`

export const Content = styled(RootContent)`
    flex-direction: column;
    justify-content: center;
    /* padding-inline: 1rem;; */
`

export const TitleContainer = styled.div`
    width: 100%;
    margin-bottom: 3rem;

`

export const TitleText = styled.h1`
    font-weight: 800;
    font-size: 34px;
    line-height: 44px;
    letter-spacing: -0.02em;
    margin-bottom: 1rem;
    color: ${theme.colors.mainColor};
`

export const BasicText = styled.p`
    font-weight: 400;
    font-size: 17px;
    line-height: 18px;

    color: ${theme.colors.greyColor1};
`


export const Button = styled.button`

`
