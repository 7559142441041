import React, { ReactNode } from 'react'
import { Section, TopContent, TitleText, DescriptionText } from './Topsection.styled'
interface Props {
    children: ReactNode
    background?: string
    backgroundPosition?: string
    backgroundSize?: string
}

const TopSection = ({ children, background, backgroundPosition, backgroundSize }: Props) => {
  return (
    <Section 
        background={background} 
        backgroundPosition={backgroundPosition} 
        backgroundSize={backgroundSize}
    >
        <TopContent>
            <div>
                { children }
            </div>
        </TopContent>
    </Section>
  )
}

TopSection.Title = ({ children }: Props) => {
    return <TitleText>{ children }</TitleText>
}

TopSection.BasicText = ({ children }: Props) => {
    return <DescriptionText>{ children }</DescriptionText>
}

export default TopSection