import React, { Dispatch, SetStateAction, useEffect, useState, useRef } from 'react'
import { Container, Tab, NoSubIndex, Index, SubIndexBox, Content, SubIndex } from './MobileMenuBox.style'
import { useAppSelector } from "../../hooks/hooks";

interface SubIndexs {
    name: string,
    url: string,
}
interface Indexs {
    name: string,
    url: string,
    subIndexs: SubIndexs[]
}

interface Props {
    indexs: Array<Indexs>,
    on: boolean
    setOn: Dispatch<SetStateAction<boolean>>
}

const MobileMenuBox = ({ on, indexs, setOn }: Props) => {
    const [curTab, setCurTab] = useState(-1);
    const [bodyHeight, setBodyHeight] = useState(0);

    const item0 = useRef<any>(null);
    const item1 = useRef<any>(null);
    const item2 = useRef<any>(null);
    const item3 = useRef<any>(null);
    const item4 = useRef<any>(null);

    const refs = [item0, item1, item2, item3, item4];

    const isLoggedIn = useAppSelector((state) => state.userReducer.isLoggedIn);

    // 스크롤 잠금
    useEffect(() => {
        if (on) {
            document.body.style.cssText = `
            position: fixed; 
            top: -${window.scrollY}px;
            overflow-y: scroll;
            width: 100%;`;
        } else {
            const scrollY = document.body.style.top;
            document.body.style.cssText = '';
            window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
        }
    });
    return (
        <Container on={on}>
        { indexs.map((index, i) => {
            const isSubIndex = index.subIndexs !== null; // 하위 메뉴가 있는지
            const active = curTab === i; // 현재 선택 된 메뉴
            return (
                <Tab key={index.name}>
                    { isSubIndex ? ( // 서브 목차가 있으면
                        <>
                            <Index
                                active={active}
                                onClick={() => {
                                    setBodyHeight(refs[i].current?.clientHeight);
                                    active ? setCurTab(-1) : setCurTab(i)
                                }}
                            >
                                {index.name}
                            </Index>
                            <SubIndexBox active={active} bodyHeight={bodyHeight}>
                                <Content ref={refs[i]}>
                                {
                                    index.subIndexs.map((subIndex) => {
                                        return (
                                            <SubIndex 
                                                key={subIndex.name} 
                                                to={subIndex.url} 
                                                onClick={e => {
                                                    setOn(false)
                                                    setCurTab(-1)
                                                }}
                                            >
                                                {subIndex.name}
                                            </SubIndex>
                                        )
                                    })
                                }    
                                </Content>
                            </SubIndexBox>
                        </>
                    ) : (
                        <NoSubIndex 
                            to={index.url}
                            onClick={e => setOn(false)}
                        >
                            {index.name}
                        </NoSubIndex>
                    ) }
                </Tab>
            )
            })}
        {
            isLoggedIn ? (
                <Tab>
                    <NoSubIndex 
                        to='/mypage'
                        onClick={e => setOn(false)}
                    >
                        마이페이지
                    </NoSubIndex>
                </Tab>
            ) : null
        }

        </Container>
    )
}

export default MobileMenuBox