import React from 'react'
import GradeCard from '../../components/GradeCard/GradeCard.component';
import theme from '../../styles/theme';
import { 
    Container,
    Content,
    TitleContainer,
    TitleText,
    BasicText,
    CardContainer

} from './AdmissionGuidePage.style';

export type CardDataType = {
    color : string,
    title : string,
    subTitle : string,
    description : string,
}

const AdmissionGuidePage = () => {
    const cardDatas: CardDataType[] = [
        {
            "color" : theme.colors.oliveColor1,
            "title" : "신입생",
            "subTitle" : "6가지 칼럼 읽기",
            "description" : `칼럼을 읽지 않은 분은 강의 신청을 
권장하지 않습니다.`,
        },
        {
            "color" : theme.colors.brownColor1,
            "title" : "입학생",
            "subTitle" : "메신저 / 글쓰기",
            "description" : `직장 탈출 학교 과목은 2가지입니다. 
원하시는 강의만 신청하여도 상관 없으나, 
메신저 → 글쓰기 순서를 추천합니다.`,
        },
        {
            "color" : theme.colors.mainColor,
            "title" : "졸업생",
            "subTitle" : "1:1 진단서",
            "description" : `100만원 이상 수익을 만든 학생만 신청이 
가능합니다. 1:1 맞춤형 진단서를 작성하여 
내성적인 건물주와 대면으로 상담을 진행합니다.`,
        }
    ]

    return (
        <Container>
            <Content>
                <TitleContainer>
                    <TitleText>
                        신입생 가이드
                    </TitleText>
                    <BasicText>
                        직장 탈출 학교 졸업은 간단합니다. 
                    </BasicText>
                </TitleContainer>
                

                <CardContainer>
                    { cardDatas.map((cardData) => {
                        return (
                            <GradeCard
                                key={cardData.title} data={cardData}
                            />
                        )
                    })}
                </CardContainer>
            </Content>
        </Container>
    )
}

export default AdmissionGuidePage;