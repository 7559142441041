import styled from "styled-components";
import { RootContent } from "../../styles/public";
import theme from "../../styles/theme";

export const Container = styled.div`
    width: 100%;
`

export const Head = styled.div`

`

export const TitleText = styled.h1`
    font-weight: 700;
    font-size: 1.75rem;
    line-height: 2.75rem;
    letter-spacing: -0.02em;
    margin-bottom: 1rem;
    color: ${theme.colors.mainBlackColor};
`

export const UserThumbnail = styled.div`
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 100%;
    overflow: hidden;
`

export const Username = styled.p`
    font-weight: 500;
    font-size: 1.0625rem;
    line-height: 1.125rem;
    color: ${theme.colors.greyColor2};
    margin: 0 0.5rem;
`

export const DateText = styled(Username)`
`

export const Line = styled.hr`
    margin-block: 2rem;
    border: 1px solid ${theme.colors.greyColor3};
`

export const Content = styled(RootContent)`
`