import React from 'react'
import TopSection from '../../components/TopSection/TopSection.component'
import PostContainer from '../../container/PostsContainer/PostsContainer.component'
import { Container, BottomSection, BottomContent } from './ScholarshipPage.style'

const ScholarshipPage = () => {
  return (
    <Container>
        <TopSection
            background="linear-gradient(to right, #EFEFDE 65%, rgba(252, 250, 245, 0) 100%), url('/img/scholarshippage_background.png');"
            backgroundPosition='right'
            backgroundSize='contain'
        >
            <TopSection.Title>장학금</TopSection.Title>
            <TopSection.BasicText>자신만의 평생업도 찾고, 장학금도 받아가세요. </TopSection.BasicText>
        </TopSection>
        <BottomSection>
            <BottomContent>
                <PostContainer />
            </BottomContent>
        </BottomSection>
    </Container>
  )
}

export default ScholarshipPage