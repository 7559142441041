import React, { ReactNode } from 'react'
import { Container, TextBox, Title, Text} from './ImageCard.style'
interface Props {
    children: ReactNode
    handler?: () => void;
    src?: string
}

const ImageCard = ({ children, handler }: Props) => {
  return (
    <Container onClick={handler}>
        {children}
    </Container>
  )
}

ImageCard.TextBox = ({ children }: Props) => {
    return <TextBox>{children}</TextBox>
}
ImageCard.Title = ({ children }: Props) => {
    return <Title>{children}</Title>
}

ImageCard.Text = ({ children }: Props) => {
    return <Text>{children}</Text>
}

export default ImageCard