import React, { useEffect } from 'react'
import { Container, Content, FixBtn } from './UserInfoModificationContainer.style'
import InputBox from '../../components/InputBox/InputBox.component'
import { FlexRowCenter, Form } from '../../styles/public'
import { useState } from 'react'
import PopupBox from '../../components/PopupBox/PopupBox.component'
import { api } from '../../util/api'
import LoadingBox from '../../components/LoadingBox/LoadingBox.component'
import { useForm } from "react-hook-form"

interface Props {
  userID: string;
  userName: string;
  userNickname: string;
  userEmail: string;
  userPhone: string;
}

const UserInfoModificationContainerSocial = ({ 
  userID, 
  userName, 
  userNickname, 
  userEmail, 
  userPhone,
} : Props) => {
  const [popupOpen, setPopupOpen] = useState(false);
  const openPopup = () => {
    setPopupOpen(true);
  };
  const closePopup = () => {
    setPopupOpen(false);
  };

  const [loading, setLoading] = useState(false);
  const openLoading = () => {
    setLoading(true);
  };
  const closeLoading = () => {
    setLoading(false);
  };

  /** 정보 변경 요청 */
  const modifySubmit = (data: any) => {
    api.put('/user/edit/nickname', {
      "nickname": data.nickname, // 변경할 이메일
    })
    .then((res: any) => {
      if (!res.data.err) {
        alert('정보가 수정 되었습니다.');
      } else {
        alert('정보를 수정하지 못 했습니다.');
      }
    })
    .catch((err: any) => {
        console.log(err);
    });
  }

  const {
    setValue,
    register,
    formState: { isSubmitting, errors },
    handleSubmit
  } = useForm();

  useEffect(() => {
    register("nickname", {
      required: "닉네임을 입력 해주세요.",
      pattern: {
        value: /^(?=.*[a-z0-9가-힣])[a-z0-9가-힣]{2,15}$/,
        message: "닉네임 형식에 맞지 않습니다. (2~15자)"
      }
    });
  }, [register]);

  return (
    <Container>
      <Content>
      <Form onSubmit={handleSubmit(modifySubmit)}>
        <InputBox>
          <InputBox.Label>이름</InputBox.Label>
          <InputBox.InputText 
              placeholder='이름 입력해 주세요.'
              type='text'
              value={userName}
              disabled={true}
          />
        </InputBox>
        <InputBox>
          <InputBox.Label>휴대폰번호</InputBox.Label>
          <InputBox.InputText 
              placeholder='연락처를 입력해 주세요.'
              type='tel'
              value={userPhone}
              disabled={true}
              // handler={onTelHandler}
          />
        </InputBox>
        <InputBox>
          <InputBox.Label htmlFor="nickname">닉네임</InputBox.Label>
          <InputBox.InputText 
              placeholder='닉네임을 입력해 주세요.'
              value={userNickname}
              type='text'
              handler={async (e: any) => setValue("nickname", e.target.value)}
              disabled={false}
          />
          { errors?.nickname && <InputBox.ErrorText>{`${errors?.nickname?.message}`}</InputBox.ErrorText> }
        </InputBox>
        <FlexRowCenter>
          <FixBtn
            value='수정하기'
            type='submit'
            disabled={isSubmitting}
          />
        </FlexRowCenter>
        
      </Form>

      </Content>
      {
        popupOpen && (
          <PopupBox>
            <PopupBox.Text>고객님의 이메일로 메일이 발송되었습니다.</PopupBox.Text>
            <PopupBox.Btn closePopup={closePopup}>확인</PopupBox.Btn>
          </PopupBox>
        )
      }
      { loading && ( <LoadingBox /> ) }
      
    </Container>
  )
}

export default UserInfoModificationContainerSocial