import styled from "styled-components";
import theme from "../../styles/theme";

export const Container = styled.div`
    cursor: pointer;
    border-radius: 10px 10px 0px 0px;
    overflow: hidden;
    width: 100%;
`

export const IndexBox = styled.div`
    position: absolute;
    width: 2.75rem;
    height: 2.75rem;
    border-radius: 10px 0px 0px 0px;
    background: ${theme.colors.mainColor};
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
`   

export const Thumbnail = styled.div`
    display:flex;
    justify-content: center;
    aspect-ratio: 16 / 9;
    width: 100%;
`

export const TextBox = styled.div`
    background-color: white;
    padding: 0.25rem 1.25rem;
`

export const TitleText = styled.h1`
    font-weight: 600;
    font-size: 1.375rem;
    line-height: 1.125rem;
    color: ${theme.colors.mainBlackColor};
    @media screen and (max-width: 576px) {
        font-size: 1.0625rem;
    }
`

export const DateText = styled.p`
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.125rem;
    color: ${theme.colors.greyColor2};
    @media screen and (max-width: 576px) {
        font-size: 0.875rem;
    }
`