import React, { useEffect } from 'react'
import { Background, Box } from './LoadingBox.styled'
import MoonLoader from "react-spinners/MoonLoader";
import theme from '../../styles/theme';

const LoadingBox = () => {
    // 스크롤 잠금
    useEffect(() => {
        document.body.style.cssText = `
        position: fixed; 
        top: -${window.scrollY}px;
        overflow-y: scroll;
        width: 100%;`;
      return () => {
        const scrollY = document.body.style.top;
        document.body.style.cssText = '';
        window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
      };
    }, []);

    return (
        <Background>
            <Box>
                <MoonLoader
                    color={theme.colors.mainColor}
                    size={50}
                />
            </Box>
        </Background>
    )
}

export default LoadingBox