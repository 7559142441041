import styled from "styled-components";
import theme from "../../styles/theme";

export const Background = styled.div`
    z-index: 999;
    position: fixed;
    top:0; left: 0; bottom: 0; right: 0;
    background: rgba(0, 0, 0, 0.4);
`

export const Box = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #FFFFFF;
    border-radius: 10px;
    padding: 2rem;
    box-sizing: border-box;
    min-width: 21.875rem;
`

export const Btn = styled.div`
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 3.125rem;
    background: ${theme.colors.mainColor};
    border-radius: 0.25rem;

    font-weight: 700;
    line-height: 130%;
    letter-spacing: -0.02em;
    color: #FFFFFF;
`

export const Text = styled.p`
    font-weight: 500;
    line-height: 2rem;
    text-align: center;
    width: 100%;
    margin-block: 2.5rem;
    letter-spacing: -0.02em;
    white-space: pre-line;
    color: ${theme.colors.mainBlackColor};
    > span {
        font-weight: 700;
        color: ${theme.colors.mainColor};
    }
`