import React, { useState } from 'react'
import { icons } from '../../static/icons/icon';
import { Container, Tab, TitleContainer, TitleText, Icon, Div, BodyContainer, Content, VideoText, VideoTimeText, AllTimeText } from './CurriAccordionTab.style'; 

interface VideoProps {
  length: string;
  mckey: string;
  title: string;
}
interface Props {
  title: string;
  totalLength: string;
  videos: Array<VideoProps>;
  getKollusJWT: (mcKey: string) => void;
}


const CurriAccordionTab = ({ title, totalLength, videos, getKollusJWT } : Props) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(true);

  return (
    <Container>
      <Tab>
        <TitleContainer
          active={isAccordionOpen}
          onClick={() => {
            isAccordionOpen ? setIsAccordionOpen(false) : setIsAccordionOpen(true);
          }}
        >
          <Div>
            <Icon>{isAccordionOpen ? icons.upArrow : icons.downArrow }</Icon>
            <TitleText active={isAccordionOpen}>{title}</TitleText>
          </Div>
          <Div>
            <AllTimeText>{totalLength}</AllTimeText>
          </Div>
        </TitleContainer>
        <BodyContainer active={isAccordionOpen}>
        { videos.map(({ title, length, mckey }, i) => {
          return (
              <Content>
                <Div onClick={() => getKollusJWT(mckey)}>
                  <Icon>{icons.vedio}</Icon>
                  <VideoText>{title}</VideoText>
                </Div>
                <VideoTimeText>{length}</VideoTimeText>
              </Content>
        )})}
        </BodyContainer> 
      </Tab>
    </Container>
  )
}

export default CurriAccordionTab