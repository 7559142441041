import React, { ReactNode, useEffect } from 'react'
import { Background, Box, Btn, Text } from './PopupBox.styled'

interface Props {
    isOpen?: boolean
    closePopup?: () => void
    children: ReactNode
}

const PopupBox = ({ children }: Props) => {
    // 스크롤 잠금
    useEffect(() => {
        document.body.style.cssText = `
        position: fixed; 
        top: -${window.scrollY}px;
        overflow-y: scroll;
        width: 100%;`;
      return () => {
        const scrollY = document.body.style.top;
        document.body.style.cssText = '';
        window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
      };
    }, []);

    return <Background><Box>{children}</Box></Background>
}

PopupBox.Text = ({ children }: Props) => {
    return <Text>{children}</Text>
}

PopupBox.Btn = ({ children, closePopup }: Props) => {
    return <Btn onClick={closePopup}>{children}</Btn>
}

export default PopupBox