import React from 'react'
import { CardDataType } from '../../pages/AdmissionGuidePage/AdmissionGuidePage'
import { icons } from '../../static/icons/icon'
import { BottomContainer, Container, DescriptionText, SubTitleText, SymbolBox, TitleText, TopContainer } from './GradeCard.style'

interface Props {
  data: CardDataType
}

const GradeCard = ({ data } : Props) => {
  const { color, title, subTitle, description  } = data
  return (
    <Container>
      <TopContainer>
        <SymbolBox color={color}>
          {icons.scoutePattern}
        </SymbolBox>
        <TitleText color={color}>{title}</TitleText>
      </TopContainer>
      
      <BottomContainer>
        <SubTitleText>
          {subTitle}
        </SubTitleText>
        <DescriptionText>
          {description}
        </DescriptionText>
      </BottomContainer>
    </Container>
  )
}

export default GradeCard