import styled from "styled-components";
import { RootContainer, RootContent } from "../../styles/public";
import admissionguidepage_background from '../../static/img/admissionguidepage_background.png';
import theme from "../../styles/theme";

export const Container = styled(RootContainer)`
    background-image: url(${admissionguidepage_background});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
    padding-block: 3rem 14rem;
    padding-inline: 1rem;

    @media screen and (max-width: 576px) {
        background-position-X: 52.5%;
    }
`

export const Content = styled(RootContent)`
    flex-direction: column;
    justify-content: center;
    /* padding-inline: 1rem;; */
`

export const TitleContainer = styled.div`
    width: 100%;
    margin-bottom: 3rem;

`

export const TitleText = styled.h1`
    font-weight: 800;
    font-size: 34px;
    line-height: 44px;
    letter-spacing: -0.02em;
    margin-bottom: 1rem;
    color: ${theme.colors.mainColor};
`

export const BasicText = styled.p`
    font-weight: 400;
    font-size: 17px;
    line-height: 18px;

    color: ${theme.colors.greyColor1};
`

export const CardContainer = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(23.75rem, 1fr));
    grid-gap: 20px;
    @media screen and (max-width: 576px) {
        grid-template-columns: repeat(1, minmax(100%, 1fr));
        align-items: flex-start;
    }
`