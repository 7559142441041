import styled from "styled-components";
import { Img, RootContainer, RootContent, RootSection } from "../../styles/public";

export const Container = styled(RootContainer)`
    
`

export const TopSection = styled(RootSection)<{background : string}>`
    background: url(${props => props.background}) rgba(0, 0, 0, 0.5);
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: 70%;
    padding-block: 5rem;
    @media screen and (max-width: 576px) {
        padding-block: 3rem;
    }
`

export const TopSectionImg = styled(Img)`
    /* position: absolute; */
`

export const Content = styled(RootContent)`
    
`

export const TitleText = styled.h1`
    font-weight: 700;
    font-size: 2.125rem;
    line-height: 2.75rem;
    letter-spacing: -0.02em;
    color: #FFFFFF;
    @media screen and (max-width: 576px) {
        font-size: 1.5rem;
    }
`

export const GrayText = styled.p`
    margin-inline: 0.5rem;
    font-weight: 500;
    font-size: 1.0625rem;
    line-height: 1.125rem;
    color: #FFFFFF;
`

export const TextSection = styled(RootSection)`
    padding-block: 3rem;
    background-color: #FFFFFF;   
    @media screen and (max-width: 576px) {
        padding-block: 1.5rem;
    }
`


export const ModifiedAndDelete = styled.div`
    display: flex;
    color: white;
`

export const Modified = styled.p`
    cursor: pointer;
`

export const Bar = styled.p`
    margin-inline: 1rem;
`


export const Delete = styled(Modified)`
`