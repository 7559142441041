import React, { ReactNode } from 'react'
import {Container, Table, Row, Data, StyledLink} from './ToggleDropdown.style'

interface Props {
    children: ReactNode
}

interface LinkElementProps {
    children: ReactNode
    to: string
}
interface ActionElementProps {
    children: ReactNode
    action: any
}

const ToggleDropdown = ({ children }: Props) => {
  return (
    <Container>
        <Table>
            <tbody>
                {children}
            </tbody>
        </Table>
    </Container>
  )
}

// Router를 통한 이동일 때
ToggleDropdown.Link = ({ children, to }: LinkElementProps) => {
    return (
        <StyledLink to={to}>
            <Row>            
                <Data>
                    {children}
                </Data>
            </Row>
        </StyledLink>
    )
}

// 특정 함수를 실행해야 할 때
ToggleDropdown.Action = ({ children, action }: ActionElementProps) => {
    return (
        <Row onClick={action}>
            <Data >
                {children}
            </Data>
        </Row>
    )
}

export default ToggleDropdown