import styled from "styled-components";
import { RootContainer, RootContent, RootSection } from "../../styles/public";
import theme from "../../styles/theme";

export const Container = styled(RootContainer)`
    background-image: url('/img/admissionguidepage_background.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
    padding-block: 3rem;
`

export const Section = styled(RootSection)`
    padding: 0;
`

export const Content = styled(RootContent)`
    flex-direction: column;
    width: 32rem;
`

export const TitleText = styled.h1`
    font-weight: 700;
    font-size: 1.875rem;
    line-height: 2.75rem;
    letter-spacing: -0.02em;
    color: ${theme.colors.mainBlackColor};
`

export const Box = styled.div`
    background: #FFFFFF;
    border-radius: 0.25rem;
    padding: 2rem;
    width: 100%;
    box-sizing: border-box;
    @media screen and (max-width: 576px) {
        border-radius: 0;
    }
`