import React, { ReactNode } from 'react'
import { icons } from '../../static/icons/icon'
import {Container, ThumbnailContainer, PlayIcon, ThumbnailImg, BottomContainer, CategoryBox, TitleText, EmptyBar, PercentGageBar, PercentText} from './MyLectureCard.style'

interface Props {
    children?: ReactNode
    gage?: number
    src?: string
    clickEvent?: () => void;
}


const MyLectureCard = ({ children }: Props) => {
    return <Container>{children}</Container>
}

MyLectureCard.ThumbnailContainer = ({ children, clickEvent }: Props) => {
    return (
        <ThumbnailContainer onClick={clickEvent}>
            {children}
            <PlayIcon>{icons.play_white}</PlayIcon>
        </ThumbnailContainer>
    ) 
}

MyLectureCard.Thumbnail = ({ src }: Props) => {
    return <ThumbnailImg src={src} />
}

MyLectureCard.BottomContainer = ({ children }: Props) => {  
    return <BottomContainer>{children}</BottomContainer>
}

MyLectureCard.CategoryBox = ({ children }: Props) => {
    return <CategoryBox>{children}</CategoryBox>
}

MyLectureCard.TitleText = ({children}: Props) => {
    return <TitleText>{children}</TitleText>
}

MyLectureCard.PercentGage = ({ gage }: Props) => {
    return <EmptyBar><PercentGageBar gage={gage}></PercentGageBar></EmptyBar>
}

MyLectureCard.PercentText = ({ children }: Props) => {
    return <PercentText>{children}% 수강 완료</PercentText>
}

export default MyLectureCard