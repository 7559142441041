import styled from "styled-components";
import { RootContainer, RootContent, RootSection } from "../../styles/public";
import theme from "../../styles/theme";

export const Container = styled(RootContainer)`
    background-color: ${theme.colors.background};
`

export const Section = styled(RootSection)`
    padding-block: 3rem 15rem;
`

export const Content = styled(RootContent)`
    flex-direction: column;
    align-items: flex-start;
`