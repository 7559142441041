import React, { ReactNode } from 'react'
import { icons } from '../../static/icons/icon'
import {Container, Category, DateText, DetailBtn, TicketName, BoldText, SubText} from './PaymentCard.style'

interface DetailBtnProps {
    handler: () => void;
}
interface Props {
    children: ReactNode;
}

const PaymentCard = ({ children }: Props) => {
    return <Container>{children}</Container>
}

PaymentCard.Category = ({children} : Props) => {
    return <Category>{children}</Category>
}

PaymentCard.Date = ({ children }: Props) => {
    return <DateText>{children}</DateText>
}
PaymentCard.DetailBtn = ({ handler }: DetailBtnProps) => {
    return <DetailBtn onClick={handler}><p>자세히 보기</p><span>{icons.rightArrow_brown}</span></DetailBtn>
}

PaymentCard.TicketName = ({ children }: Props) => {
    return <TicketName>{children}</TicketName>
}

PaymentCard.BoldText = ({ children }: Props) => {
    return <BoldText>{children}</BoldText>
}

PaymentCard.SubText = ({ children }: Props) => {
    return <SubText>{children}</SubText>
}


export default PaymentCard