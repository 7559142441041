import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import InputBox from '../../components/InputBox/InputBox.component'
import InputPaymentMethod from '../../components/InputPaymentMethod/InputPaymentMethod.component'
import PopupBox from '../../components/PopupBox/PopupBox.component'
import SubmitBtn from '../../components/SubmitBtn/SubmitBtn.component'
import { Form } from '../../styles/public'
import { api } from '../../util/api'
import { Container, Section, Content, TitleText, Box} from './InputPayInfoPage.style'
import { useForm } from "react-hook-form"
import { useEffect } from 'react'
import { getTomorrow } from '../../util/conversionTimestamp'
import Parser from 'html-react-parser';
import { useAppSelector } from '../../hooks/hooks'
import { getCookie, setCookie } from '../../util/cookie'
import { useAppDispatch } from "../../hooks/hooks";
import { setUserCourses, setUserPermission } from "../../redux/slice/userSlice";
declare global {
    interface Window {
      IMP: any
    }
}

const InputPayInfoPage = () => {
    const navigate = useNavigate();
    const { category } = useParams();

    const subjectsPrice = useAppSelector((state: { subjectsReducer: { subjects: any; }; }) => state.subjectsReducer.subjects)[category];
    const userSocial = useAppSelector((state: { userReducer: { social: any; }; }) => state.userReducer.social);
    const dispatch = useAppDispatch();
    // 결제 수단 선택
    const [selectedPayMethod, setSelectedPayMethod] = useState("card");
    const handleChange = (inputValue: any) => {
        setSelectedPayMethod(inputValue);
    };
    // 팝업 여닫기
    const [popupOpen, setPopupOpen] = useState(false);
    const [popupMessage, setPopupMessage] = useState("결제가 완료되었습니다.")
    const openPopup = () => {
        setPopupOpen(true);
    };
    
    
    const closePopup = () => {
        setPopupOpen(false);
        navigate('/mypage', {state:{ tabNumber: 1 }}); // 성공하고 닫을 때는 
    };

    // 유저 정보 가져옴
    const [userName, setUserName] = useState("")
    const [userEmail, setUserEmail] = useState("")
    const [userPhone, setUserPhone] = useState("")
    const amount = subjectsPrice.sale_price;

    const getUserInfo = () => {
        api.get('/user')
        .then((res: any) => {
            setUserName(res.data.info.name);
            setUserEmail(res.data.info.email);
            setUserPhone(res.data.info.phone);
        })
        .catch((err: any) => {
            console.log(err);
        });
    }

    useEffect(() => {
        getUserInfo();
    }, [])

    const {
        setValue,
        register,
        formState: { errors, isDirty },
        handleSubmit
    } = useForm({mode: 'all'});

    const submit = async (data: any) => {
        await new Promise((r) => setTimeout(r, 500));
        requestPay(data.name, data.phone, userEmail, amount, selectedPayMethod)
    }

    /** 아임포트 결제 정보 요청 */
    const requestPay = (name: string, phone: string, email: string, amount: number, option: string) => {
        const IMP = window.IMP; // 생략 가능
        const vbanck_due = getTomorrow() + "2359";

        IMP.init("imp05237507");
        IMP.request_pay({ // param
            pg : 'html5_inicis', //테스트 시 html5_inicis.INIpayTest 기재 
            pay_method : option,
            merchant_uid: `${new Date().getTime()}_${userSocial}_${category}`, //상점에서 생성한 고유 주문번호
            name : `${name}님의 직장탈출학교 수강권 결제`,
            amount : amount,
            buyer_email : email,
            buyer_name : name,
            buyer_tel : phone,   //필수 파라미터 입니다.
            m_redirect_url : `http://onoff-house.com/paysuccess/${category}`,
            escrow : false, //에스크로 결제인 경우 설
            vbank_due: vbanck_due,
            bypass : {
                acceptmethod : "noeasypay", // 간편결제 버튼을 통합결제창에서 제외(PC)
                P_RESERVED: "noeasypay=Y"  // 간편결제 버튼을 통합결제창에서 제외(모바)
            },
        }, (rsp: any) => { // callback
            if (rsp.success) {
                sendPayInfo(rsp.imp_uid, rsp.merchant_uid, rsp.paid_amount)
            } else {
                alert('시스템 오류 입니다.')
            }
        });
    }
    /** 요청한 결제 정보 서버에 전송 */
    const sendPayInfo = (imp_uid: string, merchant_uid: string, paid_amount: number) => {
        api.post(`/user/payment/request`, {
            "imp_uid": imp_uid, // 결제 요청의 응답에 있는 import 결제 id
            "merchant_uid": merchant_uid, // 결제 요청의 응답에 있는 주문번호
            "price": paid_amount, // 물건 금액
            "lectureName": category // 강의 카테고리
        })
        .then((res: any) => {
            if (res.data.status === 'paid') {
                openPopup()
                setCookie('token', res.data.token, { // 새로운 토큰 발급
                    path: '/',
                    maxAge: 60 * 60 * 24, // 24시간
                });
                
                api.get('/user') // 권한 수정
                .then((res: any) => {
                    navigate('/mypage', {state:{ tabNumber: 1 }}); // 성공하고 닫을 때는 
                    dispatch(setUserPermission(res.data.info.permission))
                    dispatch(setUserCourses(res.data.info.availableCourse));
                })
                .catch((err: any) => {
                    console.log(err);
                });

            } else if (res.data.status === 'vbank') {
                console.log("결제 전: ", getCookie('token'));
                setCookie('token', res.data.token, { // 새로운 토큰 발급    
                    path: '/',
                    maxAge: 60 * 60 * 24, // 24시간
                });
                console.log("결제 후: ", getCookie('token'));
                setPopupMessage(`
                    <span>입금 후 강의 시청이 가능합니다.</span>
                    
                    <span>입급 은행</span> : ${res.data.vbank.name}
                    <span>계좌번호</span> : ${res.data.vbank.account}
                    <span>입금 기한</span> : ${getTomorrow()} 23시 59분 까지
                `)
                openPopup()
            } else {
                alert('변조된 요청입니다.')
            }
        })
        .catch((err: any) => {
            alert('시스템 오류 입니다.')
            console.log(err);
        });
    }

    useEffect(() => {
        register("name", {
            required: "이름을 입력 해주세요.",
            pattern: {
              value: /^[가-힣]{2,5}$/,
              message: "이름 형식에 맞지 않습니다. (한글 2~5자)"
            },
            value: userName
        });
        register("phone", {
            required: "휴대폰 번호를 입력 해주세요.",
            pattern: {
                value: /^01(?:0|1|[6-9])(?:\d{3}|\d{4})\d{4}$/,
                message: "휴대폰 형식에 맞지 않습니다. ('-' 제거)"
            },
            value: userPhone
        });
    }, [register]);

    setValue("name", userName)
    setValue("phone", userPhone)

    return (
        <Container>
            <Section>
                <Content>
                    <TitleText>결제정보</TitleText>
                    <Box>
                        <Form onSubmit={handleSubmit(submit)}>
                        <InputBox>
                            <InputBox.Label htmlFor="name">이름<span>*</span></InputBox.Label>
                            <InputBox.InputText
                                placeholder='이름을 입력해 주세요.'
                                type='text'
                                value={userName}
                                aria-invalid={!isDirty ? undefined : errors.name ? "true" : "false"}
                                handler={async (e: any) => setValue("name", e.target.value)}
                            />
                            { errors?.name && <InputBox.ErrorText>{`${errors?.name?.message}`}</InputBox.ErrorText> }
                        </InputBox>

                        <InputBox>
                            <InputBox.Label htmlFor="tel">휴대폰 번호<span>*</span></InputBox.Label>
                            <InputBox.InputText
                                placeholder='휴대폰 번호를 입력해 주세요.'
                                type='tel'
                                value={userPhone}
                                aria-invalid={!isDirty ? undefined : errors.phone ? "true" : "false"}
                                handler={async (e: any) => setValue("phone", e.target.value)}
                            />
                            { errors?.phone && <InputBox.ErrorText>{`${errors?.phone?.message}`}</InputBox.ErrorText> }
                        </InputBox>

                        <InputBox>
                            <InputBox.Label>결제금액</InputBox.Label>
                            <InputBox.InputText 
                                placeholder='이름을 입력해 주세요.'
                                type='text'
                                value={`${amount.toLocaleString()}원`}
                                disabled={true}
                                handler={() => {}}
                            />
                        </InputBox>
                        <InputPaymentMethod>
                            <InputPaymentMethod.TitleText>결제수단</InputPaymentMethod.TitleText>
                            <InputPaymentMethod.RadioGroup>
                                <InputPaymentMethod.Radio 
                                    name="결제 수단"
                                    value="card"
                                    label="카드결제"
                                    isChecked={true}
                                    handleChange={handleChange}
                                />
                                {/* <InputPaymentMethod.Radio 
                                    name="결제 수단"
                                    value="vbank"
                                    label="무통장 입금"
                                    handleChange={handleChange}
                                /> */}
                            </InputPaymentMethod.RadioGroup>
                            {/* { selectedPayMethod === "" && <InputBox.ErrorText>{`결제수단을 선택해주세요.`}</InputBox.ErrorText> } */}
                        </InputPaymentMethod>

                        <SubmitBtn 
                            value='결제하기'
                            type='submit'
                        />
                        </Form>
                    </Box>
                </Content>
            </Section>
            {
                popupOpen && (
                    <PopupBox>
                        <PopupBox.Text>{Parser(popupMessage)}</PopupBox.Text>
                        <PopupBox.Btn closePopup={closePopup}>확인</PopupBox.Btn>
                    </PopupBox>
                )
            }
        </Container>
  )
}

export default InputPayInfoPage;