import React, { ReactNode } from 'react'
import { Container, Th, Tr, Td, PageMoveContainer, Btn } from './UserInfoTable.style'

interface Props {
    children: ReactNode
}

interface BtnProps {
    children: ReactNode
    onClick: () => void;
    disabled: boolean;
}

const UserInfoTable = ({ children }: Props) => {
    return <Container>{children}</Container>
}

UserInfoTable.Th = ({ children }: Props) => {
    return <Th>{ children }</Th>
}

UserInfoTable.Tr = ({ children }: Props) => {
    return <Tr>{ children }</Tr>
}

UserInfoTable.Td = ({ children }: Props) => {
    return <Td>{ children }</Td>
}

UserInfoTable.PageMove = ({ children } : Props) => {
    return <PageMoveContainer>{children}</PageMoveContainer>
}

UserInfoTable.Btn = ({ children, onClick, disabled }: BtnProps) => {
    return <Btn
        onClick={onClick}
        disabled={disabled}
    >{children}</Btn>;
}

export default UserInfoTable