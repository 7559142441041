import React, { useEffect, useState } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import InputTitle from '../../components/InputTitle/InputTitle.component'
import PostEditor from '../../components/PostEditor/PostEditor.component'
import SubmitBtn from '../../components/SubmitBtn/SubmitBtn.component'
import { api } from '../../util/api'
import { Container, Content, Section } from './PostingPage.style'

const PostingPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { boardType, missionType, postID, originTitle, originContents } = location.state; // 게시판 타입
  
  const [title, setTitle] = useState(originTitle); // 제목
  const [contents, setContents] = useState(originContents); // 내용
  
  const writePost = () => {
    if (title === "") {
      alert('제목을 입력해주세요!')
      return
    }

    if (contents === "") {
      alert('내용을 입력해주세요!')
      return
    }
    
    !postID ? // post id가 없으면 게시글 작성
      api.post('/user/posts', {
        "board_type": missionType ? missionType : boardType,
        "title": title,
        "contents": contents,
      })
        .then((res: any) => {
            alert('작성되었습니다.');
            navigate(missionType ? `/mission/${missionType}/1` : `/${boardType}/1`);
        })
        .catch((err: any) => {
            console.log(err);
      }) : // post id가 있으면 게시글 수정
      api.put(`/user/posts/${postID}`, {
        "title": title,
        "contents": contents,
      })
        .then((res: any) => {
            alert('수정되었습니다.');
            navigate(`/${boardType}/post/${postID}`, { state: { boardType: boardType, postID: postID }});
        })
        .catch((err: any) => {
            console.log(err);
      })
  }

  useEffect(() => {
    const deleteTmpImage = async () => {
      await api.delete(`/user/image`)
    }  
    deleteTmpImage();
  }, [])


  return (
    <Container>
      <Section>
        <Content>
          <InputTitle
            title="제목"
            value={title}
            setValue={setTitle}
          />
          <PostEditor
            title="내용"
            type="post"
            value={contents}
            setValue={setContents}
          />
          <SubmitBtn 
              value='게시물 등록'
              type='submit'
              handler={writePost}
          />
        </Content>        
      </Section>
    </Container>
  )
}

export default PostingPage