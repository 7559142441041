import React, { useCallback, useRef, useState } from 'react'
import { icons } from '../../static/icons/icon';
import { FlexRow, FlexRowJustifyContentSpaceBetween } from '../../styles/public';
import {
    Container,
    CommentTable, 
    Td, 
    CommentWriteContainer, 
    CommentWriteBox,
    CommentInput,
    Username,
    CommentText,
    DateText,
    WriteReply,
    MarginDiv,
    ThreeCircleBtn,
    Button,
    ReplyWriteBox,
    ReplyInput,
    CommentModifyContainer
} from './CommentContainer.style'
import { useAppSelector } from "../../hooks/hooks";
import { api } from '../../util/api';
import { conversionDate, conversionTime } from '../../util/conversionTimestamp';
import { useEffect } from 'react';
interface CommentData {
    contents: string,
    date: number,
    id: number,
    parent: number,
    user_id: number,
    post_id: number,
    user_nickname: string
}
interface Props {
    comments: CommentData[],
    postID: string
}

const CommentContainer = ({ comments, postID }: Props) => {
    const isLoggedIn = useAppSelector((state) => state.userReducer.isLoggedIn);
    const curUserNickname = useAppSelector((state) => state.userReducer.nickname);
    const permission = useAppSelector((state) => state.userReducer.permission);

    // 각 댓글 들
    const CommentTableTd = ({ contents, date, id, parent, user_id, post_id, user_nickname }: CommentData) => {
        const isMine = curUserNickname === user_nickname // 내 댓글인지?
        const deleteComment = () => {
            // 현재 사용자가 관리자인지 아닌지
            const mode = permission === 4 ? 'admin' : 'user';
            if(window.confirm('정말 삭제하시겠습니까?'))   
                api.delete(`/${mode}/reply/${id}`, {
                    data: {
                        "post_id": post_id,
                    }
                })
                .then((res: any) => {
                    alert('삭제되었습니다.')
                    console.log(res.data)
                    setCommentList(res.data.replies)
                })
                .catch((err: any) => {
                    console.log(err);
                });
            else  
                return false;
        }

        const [onReply, setOnReply] = useState(false)
        const [reply, setReply] = useState("");

        const replyTextArea = useRef<any>();

        /** 댓글 내용의 길이에 맞게 창 늘어나기 */
        const replyAreaResizeHeight = useCallback(() => {
            commentTextAreaRef.current.style.height = commentTextAreaRef.current.scrollHeight + "px";
        }, []);

        const writeReply = () => {
            if (reply === "") {
                alert("답글을 입력해주세요!")
                return
            }
            api.post(`/user/reply`, {
                "contents": reply, // 댓글 내용
                "post_id": postID, // 댓글이 달릴 게시글 id
                "parent_id": id, // 대댓이 달리는 댓글 id. 대댓이 아니면 null 입력
            })
            .then((res: any) => {
                console.log(res)
                setCommentList(res.data.replies)
            })
            .catch((err: any) => {
                console.log(err);
            });
        }

        const [newComment, setNewComment] = useState(contents);
        const [onModifyComment, setOnModifyComment] = useState(false);

        const modifyComment = () => {
            if (newComment === "") {
                alert("댓글을 입력해주세요!")
                return
            }
            api.put(`/user/reply/${id}`, {
                "contents": newComment, // 댓글 내용
                "post_id": postID, // 댓글이 달릴 게시글 id
            })
            .then((res: any) => {
                console.log(res.data)
                setCommentList(res.data.replies)
            })
            .catch((err: any) => {
                console.log(err);
            });
        }

        return (
            onModifyComment ? (
                <CommentModifyContainer>
                    <CommentWriteBox>
                        <FlexRow>
                            <MarginDiv>
                                {icons.user}
                            </MarginDiv>
                            <Username>
                                {user_nickname}
                            </Username>
                        </FlexRow>
                        <CommentInput 
                            // ref={commentTextAreaRef}
                            // onInput={commentAreaResizeHeight}
                            onChange={(e) => setNewComment(e.currentTarget.value)}
                            value={newComment}
                        />
                        <FlexRowJustifyContentSpaceBetween>
                            <Button 
                                reply={true} 
                                onClick={() => setOnModifyComment(false)}
                            >취소</Button>
                            <Button 
                                reply={true}
                                onClick={() => {                                
                                    modifyComment()
                                    setOnModifyComment(false)
                            }}>등록하기</Button>
                        </FlexRowJustifyContentSpaceBetween>
                    </CommentWriteBox>
                </CommentModifyContainer>
            ) : (
            <>
                <Td isMine={isMine} >
                    {
                        parent && (
                            <MarginDiv>
                                {icons.rightangle}
                            </MarginDiv>
                        )
                    }
                    <MarginDiv>
                        {icons.user}
                    </MarginDiv>
                    <div>
                        <Username>
                            {user_nickname}
                        </Username>
                        <CommentText>
                            {contents}
                        </CommentText>
                        <FlexRow>
                            <DateText>
                                {conversionDate(date)} | {conversionTime(date)}
                            </DateText>

                            {   // 로그인 되어 있을 때만 보이게
                                (isLoggedIn && !parent) &&
                                <WriteReply onClick={() => {
                                    setOnReply(!onReply)
                                    setOnModifyComment(false)
                                }}>답글쓰기</WriteReply>
                            }
                        </FlexRow>
                    </div>
                    {
                        // 관리자 이거나 내 댓글 일 때만
                        (permission === 4 || isMine) && (
                            <ThreeCircleBtn>
                                {icons.threeCirle}
                                <div>
                                    <p onClick={() => { 
                                        setOnModifyComment(true)
                                        setOnReply(false)
                                    }}>수정하기</p>
                                    <p onClick={() => deleteComment()}>삭제하기</p>
                                </div>
                            </ThreeCircleBtn>
                        )
                    }
                </Td>
            {
                onReply && 
                <Td>
                    <ReplyWriteBox>
                        <ReplyInput
                            ref={replyTextArea}
                            onInput={replyAreaResizeHeight}
                            onChange={(e: any) => setReply(e.currentTarget.value)}
                            placeholder={'답글을 입력해주세요.'}
                            value={reply}
                            disabled={!isLoggedIn}
                        />
                        <Button
                            onClick={() => { 
                                writeReply()
                                setOnReply(false)
                            }}>등록하기</Button>
                    </ReplyWriteBox>
                </Td>
            }
            </>
            )
        )
    }

    const [commentList, setCommentList] = useState([])
    useEffect(() => {
        setCommentList(comments)
    }, [comments])
    
    const commentTextAreaRef = useRef<any>();

    /** 댓글 내용의 길이에 맞게 창 늘어나기 */
    const commentAreaResizeHeight = useCallback(() => {
        commentTextAreaRef.current.style.height = commentTextAreaRef.current.scrollHeight + "px";
    }, []);

    const [comment, setComment] = useState("");
    
    /** 댓글 작성 */
    const writeComment = () => {
        if (comment === "") {
            alert("댓글을 입력해주세요!")
            return
        }
        api.post(`/user/reply`, {
            "contents": comment, // 댓글 내용
            "post_id": postID, // 댓글이 달릴 게시글 id
            "parent_id": null, // 대댓이 달리는 댓글 id. 대댓이 아니면 null 입력
        })
        .then((res: any) => {
            console.log(res)
            setCommentList(res.data.replies)
        })
        .catch((err: any) => {
            console.log(err);
        });
    }

    return (
        <Container>
            <CommentTable>
                <tbody>
                    {
                        commentList.map((data, i) => {
                            return (
                                <tr key={`comment${i}`}>
                                    <CommentTableTd 
                                        contents={data.contents}
                                        date={data.date}
                                        id={data.id}
                                        parent={data.parent}
                                        user_id={data.user_id}
                                        post_id={data.post_id}
                                        user_nickname={data.user_nickname}
                                    />
                                </tr>
                            )
                        })
                    }
                </tbody>
            </CommentTable>

            <CommentWriteContainer>
                <CommentWriteBox>
                    <CommentInput 
                        ref={commentTextAreaRef}
                        onInput={commentAreaResizeHeight}
                        onChange={(e) => setComment(e.currentTarget.value)}
                        value={comment}
                        placeholder={!isLoggedIn ? '로그인 이후 댓글 입력이 가능합니다.' : '댓글을 입력해주세요.'}
                        disabled={!isLoggedIn}
                    />
                    <Button onClick={() => { 
                        writeComment()
                        setComment("")
                    }}>등록하기</Button>
                </CommentWriteBox>
            </CommentWriteContainer>
        </Container>
    )
}

export default CommentContainer;

