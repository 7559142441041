import styled from "styled-components";
import theme from "../../styles/theme";

export const Container = styled.aside`
    position: sticky;
    top: 10rem;
    width: 100%;
    background: #FFFFFF;
    box-sizing: border-box;
    border: 0.0625rem solid #FFFFFF;
    box-shadow: 0rem 0.125rem 0.375rem rgba(155, 155, 155, 0.16);
    border-radius: 0.625rem;
    padding: 2rem;
    display: flex;
    flex-direction: column;

    :nth-child(2) {
        display:none;
    }

    @media screen and (max-width: 576px) {
        background: transparent;
        position: relative;
        border: 0;
        top: 0;
        box-shadow: none;
        padding: 0;
        margin-block: 1rem;

        :nth-child(2) {
            display: flex;
        }
    }
`

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
`

export const Category = styled.div`
    width: 3.25rem;
    height: 1.75rem;
    background: ${theme.colors.mainColor};
    border-radius: 0.125rem;

    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.75rem;
    text-align: center;
    letter-spacing: -0.02em;
    color: #FFFFFF;
`

export const TitleText = styled.h1`
    font-weight: 700;
    font-size: 1.75rem;
    line-height: 2.125rem;
    color: ${theme.colors.mainColor};
`

export const DescriptionText = styled.p`
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    margin-block: 0rem 1rem;
    color: ${theme.colors.greyColor1};
`

export const Line = styled.hr`
    border: 0.1px solid ${theme.colors.greyColor3};
    opacity: 0.5;
    width: 100%;
`

export const TimeInfoText = styled.p`
    font-weight: 700;
    font-size: 0.9375rem;
    line-height: 1.125rem;
    color: ${theme.colors.oliveGreenColor};
    margin-block: 10px;
    > span {
        font-weight: 400;
        color: ${theme.colors.mainBlackColor}
    }
`

export const TotalCostText = styled.p`
    font-weight: 500;
    font-size: 0.9375rem;
    line-height: 1.125rem;
    color: ${theme.colors.greyColor2};
    margin-block: 0.5rem;
    > span {
        font-weight: 900; 
        font-size: 1.125rem;
        line-height: 1.125rem;
        color: #333333;
    }
`

export const SaleCostText = styled.p`
    margin-left: 1rem;
    font-weight: 600;
    font-size: 0.9375rem;
    line-height: 1.125rem;
    color: ${theme.colors.greyColor1};
    margin-block: 0.5rem;
    > span {
        font-weight: 900; 
        font-size: 1.125rem;
        line-height: 1.125rem;
        color: #333333;
    }
`

export const GrayText = styled.p`
    font-weight: 400;
    font-size: 0.9375rem;
    line-height: 1.125rem;

    color: #D7D7D7;
`

export const Button = styled.button<{permission: number}>`
    cursor: pointer;
    border: 0;
    width: 100%;
    padding: 0.75rem;

    background: ${props => props.permission > 0 ? theme.colors.brownColor1 : theme.colors.mainColor};
    border-radius: 0.25rem;
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.375rem;
    text-align: center;
    color: #FFFFFF;
`