import styled from "styled-components";
import { RootContent } from "../../styles/public";
import theme from "../../styles/theme";

export const Container = styled.div`
    background-color: ${theme.colors.lightGreenColor};
    padding-block: 3rem;
`

export const Content = styled(RootContent)`
    flex-direction: column;
`

export const TitleText = styled.h1`
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.625rem;
    color: ${theme.colors.greyColor1};
`

export const Table = styled.table`
    width: 100%;
    border-top: 1px solid ${theme.colors.oliveColor2};
    margin-bottom: 2rem;
`

export const GoToListBtn = styled.button`
    cursor: pointer;
    border: 1px solid ${theme.colors.brownColor1};
    padding: 1rem 1.25rem;
    border-radius: 4px;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.1875rem;
    background-color: transparent;
    display: flex;
    align-items: center;
    text-align: center;
    color: ${theme.colors.mainColor};
`

export const Td = styled.td`
    cursor: pointer;
    width: 100%;
    border-bottom: 1px solid ${theme.colors.oliveColor2};
    padding: 1.5rem;
    @media screen and (max-width: 576px) {
        padding: 1.5rem 0.125rem;
    }
`

export const PostTitleText = styled.h1`
    margin: 0;
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.625rem;
    color: ${theme.colors.greyColor1};
    @media screen and (max-width: 576px) {
        font-size: 0.9375rem;
        line-height: 1.625rem;
    }
    
`

export const GreyText = styled.p`
    margin: 0;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.625rem;
    color: ${theme.colors.greyColor2};
`