
export const subjects = [
    {
        category: "messenger",
        kr_category: "메신저",
        title: "2년만에 직장을 나온 비밀 ",
        description: "0원으로 시작하는 메신저 사업에 대한 구체적인 설명은 무료로 공개한 OT 영상을 참고해주시면 됩니다.",
        thumbnail: '/img/messenger_thumbnail.png',
        duration: '365일'
    },
    {
        category: "writing",
        kr_category: "글쓰기",
        title: "내건주의 마지막 비결",
        description: "제가 말하는 글쓰기는 일반적이지 않습니다. 이에 대한 구체적인 설명은 무료로 공개한 OT 영상을 참고해주시면 됩니다.",
        thumbnail: '/img/writing_thumbnail.png',
        duration: '365일'
    },
]