import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "../../hooks/hooks";
import {
  Container,
  TabsContainer,
  TabHeaderContainer,
  StylizedTab,
  TabsHolder,
  TabSlider,
  TapLine,
} from "./MissionTopTabSlider.style";

interface Props {
  TabElements: {label: string, missionType: string }[];
  missionType: string;
}

const Tab = ({ label, active, onClick, link }: any) => {
  return (
    <Link to={`/mission/${link}/1`}>
      <StylizedTab role="tab" active={active} onClick={onClick}>
        {label}
      </StylizedTab>
    </Link>
  );
};

const Tabs = ({ selectedTab, children }: any) => {
  const sliderWidth = children.length;
  const tabs = children.map((child: any) => {

    return React.cloneElement(child, {
      key: child.props.value,
      active: child.props.value === selectedTab,
    });
  });

  return (
    <TabHeaderContainer>
      <TabsHolder>{tabs}</TabsHolder>
      <TabSlider width={sliderWidth} index={selectedTab} />
      <TapLine />
    </TabHeaderContainer>
  );
};

const MissionTopTabSlider = ({ TabElements, missionType }: Props) => {
  const [activeTab, setActiveTab] = useState(0);
  
  useEffect(() => {
    missionType === 'messenger' ?
    setActiveTab(0) :
    setActiveTab(1)
  }, [missionType])

  const handleChange = (e: any, value: any) => {
    setActiveTab(value);
  };

  return (
    <Container>
      <TabsContainer>
        <Tabs selectedTab={activeTab} onChange={handleChange}>
          { TabElements.map((element, i) => {
              return <Tab label={element.label} value={i} key={`tabheader${i}`} link={element.missionType} />
          })}
        </Tabs>
      </TabsContainer>
    </Container>
  );
};

export default MissionTopTabSlider;
