import styled, { css } from 'styled-components';
import { RootContainer, RootContent, RootSection } from '../../styles/public';
import theme from '../../styles/theme';

export const Container = styled(RootContainer)`
    
`

export const BottomContent = styled(RootContent)`
    flex-direction: column;
`

export const BottomSection = styled(RootSection)`
    padding-block: 3rem;
`