import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import MyLectureCard from '../../components/MyLectureCard/MyLectureCard.component'
import { useAppSelector } from '../../hooks/hooks'
import { subjects } from '../../static/subjects/subjects'
import { FlexRow } from '../../styles/public'
import { api } from '../../util/api'
import { BoldText, Container } from './MyClassRoomContainer.style'

const MyClassRoomContainer = () => {
  const navigate = useNavigate();
  const permission = useAppSelector((state: { userReducer: { permission: any; }; }) => state.userReducer.permission);
  const courses = useAppSelector((state: { userReducer: { courses: any; }; }) => state.userReducer.courses);

  const [mySubjectProgress, setMySubjectProgress] = useState<{[key: string] : number}>({})

  const getMyLectureInfo = () => {
    api.get(`/user/lecture/progress`)
    .then((res: any) => {
      if (!res.data.err) {
        setMySubjectProgress(res.data.progress)
      } else {
        alert('강의 정보를 가져올 수 없습니다.')
      }
    })
    .catch((err: any) => {
      console.log(err);
    });
  }

  useEffect(() => {
    getMyLectureInfo()
  }, [])

  return (
    <Container>
      { permission >= 1 ? (
        courses.map((course: string, i: number) => {
          const subject = subjects.filter((s) => {
              return s.category === course;
          })[0]
          // 진도율
          const progress = mySubjectProgress[subject.category]
          return (
            <MyLectureCard key={`MyLecture${i}`}>
              <MyLectureCard.ThumbnailContainer clickEvent={() => navigate(`/subjects/${subject.category}`)}>
                <MyLectureCard.Thumbnail src="img/dummy.png" />
              </MyLectureCard.ThumbnailContainer>
              <MyLectureCard.BottomContainer>
                <FlexRow>
                  <MyLectureCard.CategoryBox>{subject.kr_category}</MyLectureCard.CategoryBox>
                  <MyLectureCard.TitleText>{subject.title}</MyLectureCard.TitleText>
                </FlexRow>
                <MyLectureCard.PercentGage gage={progress} />
                <MyLectureCard.PercentText>{progress}</MyLectureCard.PercentText>
              </MyLectureCard.BottomContainer>
            </MyLectureCard>
          )
        })
      ) : (
        <BoldText>수강 신청된 강의가 없습니다.</BoldText>
      )}
    </Container>
  )
}

export default MyClassRoomContainer