import React from 'react'
import StyledTextArea from '../../components/StyledTextArea/StyledTextArea.component'
import {Container, Section, Content, TitleText} from './PersonalInfoCollectPage.style'

const PersonalInfoCollectPage = () => {
  const content = `
회사(주식회사 내건주)는 회원가입, 쇼핑몰 이용, 서비스 신청 및 제공 등을 위해 다음과 같은 개인정보를 수집하고 있습니다. 회사(주식회사 내건주)는 개인의 주민등록번호 및 아이핀 정보를 받지 않습니다.
가. 개인정보 항목
회원 필수항목: 전자우편주소, 이름, 전화번호, 연령대, 성별 카카오톡 ID 및 닉네임등 카카오톡에서 제공하는 정보, 네이버 ID 및 네이버에서 제공하는 정보
비회원 필수항목: 주문자 이름, 주문 결제자 주소, 수취인 이름, 배송지 정보, 연락처, 고객메모
부가항목: 주문자 이름, 주문 결제자 주소, 수취인 이름, 배송지 정보, 연락처, 환불요청 시 환불계좌번호
다만, 서비스 이용과정에서 서비스 이용기록, 접속 로그, 쿠키, 접속 IP 정보, 결제기록 등이 생성되어 수집될 수 있습니다.
나. 수집방법: 회원가입 시의 회원가입 정보 및 고객센터를 통한 전화 및 온라인 상담

개인정보 수집이용 목적]

회사(주식회사 내건주)의 개인정보 수집 목적은 최적화된 맞춤화 서비스를 제공하기 위함이며, 회사(주식회사 내건주)는 서비스 제공을 원활하게 하기 위해 필요한 최소한의 정보만을 수집하고 있습니다.
서비스 이용에 따른 대금결제, 물품배송 및 환불 등에 필요한 정보를 추가로 수집할 수 있습니다.
회사(주식회사 내건주)는 개인정보를 수집, 이용목적 이외에 다른 용도로 이용하거나 회원의 동의 없이 제3자에게 이를 제공하지 않습니다.



[개인정보 보유 및 이용기간]

회사(주식회사 내건주)는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 개인정보를 지체 없이 파기합니다.
단, 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사(주식회사 내건주)는 아래와 같이 관계법령에서 정한 일정한 기간 동안 회원 개인정보를 보관합니다.

가. 상법 등 법령에 따라 보존할 필요성이 있는 경우

① 표시 • 광고에 관한 기록
보존근거: 전자상거래 등에서의 소비자보호에 관한 법률
보존기간: 6개월

② 계약 또는 청약철회 등에 관한 기록
보존근거: 전자상거래 등에서의 소비자보호에 관한 법률
보존기간: 5년

③ 대금결제 및 재화 등의 공급에 관한 기록
보존근거: 전자상거래 등에서의 소비자보호에 관한 법률
보존기간: 5년

④ 소비자의 불만 또는 분쟁처리에 관한 기록
보존근거: 전자상거래 등에서의 소비자보호에 관한 법률
보존기간: 3년

⑤ 신용정보의 수집, 처리 및 이용 등에 관한 기록
보존근거: 신용정보의 이용 및 보호에 관한 법률
보존기간: 3년

⑥ 본인확인에 관한 기록보존
보존근거: 정보통신망 이용촉진 및 정보보호에 관한 법률 제44조의5 및 시행령 제29조
보존기간: 6개월

⑦ 접속에 관한 기록보존
보존근거: 통신비밀보호법 제15조의2 및 시행령 제41조
보존기간: 3개월

나. 기타, 회원의 개별적인 동의가 있는 경우에는 개별 동의에 따른 기간까지 보관합니다.
`
  
  return (
    <Container>
      <Section>
        <Content>
          <TitleText>개인정보수집항목</TitleText>
          <StyledTextArea>
            {content}
          </StyledTextArea>
        </Content>
      </Section>
    </Container>
  )
}

export default PersonalInfoCollectPage;