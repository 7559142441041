import styled from "styled-components";
import theme from "../../styles/theme";

export const Container = styled.div`
    margin-block: 0.5rem 2rem;
    width: 100%;
`
export const TitleText = styled.p`
    font-weight: 500;
    font-size: 1rem;
    line-height: 130%;
    letter-spacing: -0.02em;
    color: ${theme.colors.mainBlackColor};
    margin-bottom: 0.5rem;
    > span {
        font-size: 1.125rem;
        color: ${theme.colors.brownColor1}
    }
`

export const RadioGroup = styled.div`
    width: 100%;
    height: 3.125rem;
    display: grid;
    grid-template-columns: repeat(1, minmax(12.5rem, 1fr));
    grid-gap: 0.625rem;
    @media screen and (max-width: 576px) {
      grid-template-columns: repeat(1, minmax(10.125rem, 1fr));
    }
`

export const Radio = styled.div`
  display: flex;
  flex-direction: row;

  input[type="radio"] {
    opacity: 0;
    position: fixed;
    width: 0;
  }

  input[type="radio"]:checked + label {
    border: 0;
    background-color: #D7BFB9;
    color: #FFFFFF;
  }

  > label {
    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: 600;
    font-size: 1.125rem;
    line-height: 130%;
    color: ${theme.colors.mainBlackColor};

    background-color: #ffffff;
    border: 1px solid ${theme.colors.greyColor3};
    
    width: 100%;
    box-sizing: border-box;
    border-radius: 0.25rem;
    cursor: pointer;
  }
`;