import React, { useState } from 'react'
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { api } from '../../util/api';
import PopupBox from '../../components/PopupBox/PopupBox.component'
import { Container, Text } from './PaySuccessPage.style'
import MoonLoader from "react-spinners/MoonLoader";
import theme from '../../styles/theme';
import { conversionDate, conversionTime } from '../../util/conversionTimestamp';
import Parser from 'html-react-parser';
import { setCookie } from '../../util/cookie';
import { useAppDispatch } from '../../hooks/hooks';
import { setUserCourses, setUserPermission } from "../../redux/slice/userSlice";

const PaySuccessPage = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { category } = useParams();

    // 쿼리 파라미터 파싱
    const [searchParams] = useSearchParams();
    const imp_uid = searchParams.get('imp_uid');
    const merchant_uid = searchParams.get('merchant_uid');
    const imp_success = searchParams.get('imp_success');
    const error_msg = searchParams.get('error_msg');

    // 팝업 여닫기
    const [popupOpen, setPopupOpen] = useState(false);
    const [popupMessage, setPopupMessage] = useState("")

    const openPopup = () => {
        setPopupOpen(true);
    };
    const closePopup = () => {
        setPopupOpen(false);
        navigate('/mypage', {state:{ tabNumber: 1 }}); // 성공하고 닫을 때는 
    };


    /** 결제 정보 서버 전송 */
    const sendPayInfo = (imp_uid: string, merchant_uid: string, paid_amount: number) => {
        api.post(`/user/payment/request`, {
            "imp_uid": imp_uid, // 결제 요청의 응답에 있는 import 결제 id
            "merchant_uid": merchant_uid, // 결제 요청의 응답에 있는 주문번호
            "price": paid_amount, // 물건 금액
            "lectureName": category
        })
        .then((res: any) => {
            if (res.data.status === 'paid') {
                openPopup()

                setCookie('token', res.data.token, { // 새로운 토큰 발급
                    path: '/',
                    maxAge: 60 * 60 * 24, // 24시간
                });
                
                api.get('/user') // 권한 수정
                .then((res: any) => {
                    dispatch(setUserPermission(res.data.info.permission))
                    dispatch(setUserCourses(res.data.info.availableCourse));
                })
                .catch((err: any) => {
                    console.log(err);
                });

            } else if (res.data.status === 'vbank') { 
                setCookie('token', res.data.token, { // 새로운 토큰 발급
                    path: '/',
                    maxAge: 60 * 60 * 24, // 24시간
                });
                setPopupMessage(`
                    <span>입금 후 강의 시청이 가능합니다.</span>
                    
                    <span>입급 은행</span> : ${res.data.vbank.name}
                    <span>계좌번호</span> : ${res.data.vbank.account}
                    <span>입금 기한</span> : ${conversionDate(res.data.vbank.date)} ${conversionTime(res.data.vbank.date)} 까지 
                `)
                openPopup()
            } else {
                alert('변조된 요청입니다.')
            }
            console.log(res.data)
        })
        .catch((err: any) => {
            alert('시스템 오류 입니다.')
            console.log(err);
        });
    }

    if (imp_success === 'true') {
        sendPayInfo(imp_uid, merchant_uid, 1000);
    } else {
        alert(error_msg)
        navigate('/inputpayinfo')
    }

    return (
        <Container>
        
        <MoonLoader
            color={theme.colors.mainColor}
            size={50}
        />
        <Text>결제 중 입니다..</Text>

        {
            popupOpen && (
                <PopupBox>
                    <PopupBox.Text>{Parser(popupMessage)}</PopupBox.Text>
                    <PopupBox.Btn closePopup={closePopup}>확인</PopupBox.Btn>
                </PopupBox>
            )
        }
        </Container>
    )
}

export default PaySuccessPage