import React, { useEffect, useState } from 'react'
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom'
import TitleContent from '../../components/TitleContent/TitleContent.component'
import PostEditor from '../../components/PostEditor/PostEditor.component'
import SubmitBtn from '../../components/SubmitBtn/SubmitBtn.component'
import { api } from '../../util/api'
import { Container, Content, Section } from './EditSubjectPage.style'

const EditSubjectPage = () => {
  const navigate = useNavigate();
  const { category } = useParams();

  const [intro, setIntro] = useState(``); // 내용
  const [QnAList, setQnAList] = useState([]);
  const [newQuestion, setNewQuestion] = useState('');
  const [newAnswer, setNewAnswer] = useState('');

  const getLectureInfo = () => {
    api.get(`/user/lecture/info?lecture_name=${category}`)
      .then((res: any) => {
        if (!res.data.err) {
          setIntro(res.data.info.intro);
          setQnAList(res.data.info.faq);
        } else {
          alert(`정보를 가져올 수 없습니다. ${res.data.err}`)
        }
      })
      .catch((err: any) => {
          console.log(err);
    })
  }

  const addQnA = () => {
    if (newQuestion === '') {
      alert('질문을 입력해주세요!')
    } else if (newAnswer === "") {
      alert('답변을 입력해주세요!')
    } else {
      setQnAList(QnAList => [...QnAList, { q: newQuestion, a: newAnswer }])
      setNewQuestion('');
      setNewAnswer('');
    }
  }

  const deleteQnA = (index: number) => {
    const newQnAList = QnAList.filter((content, i) => i !== index);
    setQnAList(newQnAList)
  } 


  const EditSubject = () => {
    if (intro === "") {
      alert('강의 소개를 입력해주세요!')
      return
    }
    api.put('/admin/lecture/info', {
      "lecture_name": category,
      "intro": intro,
      "faq": QnAList,
    })
    .then((res: any) => {
      if (res.data.err) {
        alert(`작성에 실패했습니다. ${res.data.err}`)
      } else {
        alert('작성되었습니다.');
        navigate(`/subjects/${category}`)
      }
    })
      .catch((err: any) => {
          console.log(err);
    })
  }

  useEffect(() => {
    getLectureInfo()
    // setQnAList(sampleAccordionData);
  }, [])

  return (
    <Container>
      <Section>
        <Content>
          <PostEditor
            title="강의 소개"
            type="intro"
            value={intro}
            setValue={setIntro}
          />

          <TitleContent>
            <TitleContent.Title>Q&A</TitleContent.Title>
            <TitleContent.Container>
            { QnAList && QnAList.map((data, index) => {
              return (
                <TitleContent.Box key={`QnA${index}`}>
                  <TitleContent.Question>
                    {data.q}
                  </TitleContent.Question>
                  <TitleContent.Answer>
                    {data.a}
                  </TitleContent.Answer>
                  <TitleContent.DelButton
                    handler={() => deleteQnA(index)}
                  >삭제</TitleContent.DelButton>
                </TitleContent.Box>
              )
            }) }
            </TitleContent.Container>
            <hr />
            <TitleContent.Container>
                <TitleContent.SubTitle>Question</TitleContent.SubTitle>
                <TitleContent.Input 
                  value={newQuestion}
                  setValue={setNewQuestion}
                />
                <TitleContent.SubTitle>Answer</TitleContent.SubTitle>
                <PostEditor
                  type="intro"
                  value={newAnswer}
                  setValue={setNewAnswer}
                />
                <TitleContent.AddButton
                  handler={addQnA}
                >추가</TitleContent.AddButton>
            </TitleContent.Container>
          </TitleContent>

          <SubmitBtn 
            value='강의 정보 수정'
            type='submit'
            handler={EditSubject}
          />
        </Content>
      </Section>
    </Container>
  )
}

export default EditSubjectPage