import React from 'react'
import { Container, BottomSection, BottomContent } from './NoticePage.style'
import TopSection from '../../components/TopSection/TopSection.component'
import PostContainer from '../../container/PostsContainer/PostsContainer.component'

const NoticePage = () => {
    return (
        <Container>
            <TopSection
                background="linear-gradient(to right, #EDE8E0 40%, rgba(252, 250, 245, 0) 100%), url('/img/noticepage_background.png');"
                backgroundPosition='right'
                backgroundSize='cover'
            >
                <TopSection.Title>공지사항</TopSection.Title>
                <TopSection.BasicText>내 몸값을 올리는 것이 최고의 투자입니다.</TopSection.BasicText>
            </TopSection>
            
            <BottomSection>
                <BottomContent>
                    <PostContainer />
                </BottomContent>
            </BottomSection>
        </Container>
    )
}

export default NoticePage