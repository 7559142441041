import React from 'react'
import TopSection from '../../components/TopSection/TopSection.component'
import { Container, BottomSection, BottomContent } from './MissionPage.style'
import PostContainer from '../../container/PostsContainer/PostsContainer.component'
import { useParams } from 'react-router-dom'
import MissionTopTabSlider from '../../components/MissionTopTabSlider/MissionTopTabSlider.component'

const TabElements = [
  {
    label: "메신저",
    missionType: 'messenger',
  },
  {
    label: "글쓰기",
    missionType: 'writing',
  },
]

const SubmitMissionPage = () => {
  const { boardtype } = useParams();

  return (
    <Container>
      <TopSection
        background="linear-gradient(to right, #E5F0F0 60%, rgba(252, 250, 245, 0) 100%), url('/img/submitmission_background.png');"
        backgroundPosition='right'
        backgroundSize='auto 100%'
      >
        <TopSection.Title>미션제출</TopSection.Title>
        <TopSection.BasicText>미션은 숙제가 아닌, 자신의 몸값을 올리는 수단입니다.</TopSection.BasicText>
      </TopSection>
      
      <BottomSection>
        <BottomContent>
          <MissionTopTabSlider TabElements={TabElements} missionType={boardtype} />
          <PostContainer missionType={boardtype} />
        </BottomContent>
      </BottomSection>
    </Container>
  )
}

export default SubmitMissionPage