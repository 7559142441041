import styled from "styled-components";
import theme from "../../styles/theme";

export const Container= styled.div`
    width: 100%;
`

export const TitleText = styled.h1`
    font-weight: 800;
    font-size: 1.5rem;
    line-height: 1.8125rem;
    margin-bottom: 2rem;
    color: ${theme.colors.mainColor};
`

export const ContentBox = styled.div`
    padding: 1.5rem 1rem;
    background: #FFFFFF;
    box-shadow: 0rem 0.125rem 0.375rem rgba(155, 155, 155, 0.16);
    border-radius: 0.625rem;
    width: 100%;
    box-sizing: border-box;
    > p {
        font-weight: 500;
        font-size: 1rem;
        line-height: 170%;
        text-transform: capitalize;

        color: #000000;

        > b {
            font-weight: 600;
        }
    }
    > figure {
        margin: 0 auto;
        width: 100%;
        > img {
            max-width: 100%;
        }
    }
    @media screen and (max-width: 576px) {
        > p {
            font-size: 0.875rem;
            line-height: 170%;
        }
    }
`