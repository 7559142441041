import React from 'react'
import { Container, BottomSection, BottomContent } from './DiagnosisPage.style'
import TopSection from '../../components/TopSection/TopSection.component'
import PostContainer from '../../container/PostsContainer/PostsContainer.component'

const DiagnosisPage = () => {
    return (
        <Container>
            <TopSection
                background="linear-gradient(to right, #EDE8E0 70%, rgba(252, 250, 245, 0) 100%), url('/img/diagnosispage_background.png');"
                backgroundPosition='right'
                backgroundSize='contain'
            >
                <TopSection.Title>진단서</TopSection.Title>
                <TopSection.BasicText>100만 원 수익 달성 이후, 로드맵을 같이 그려봅니다.</TopSection.BasicText>
            </TopSection>
            
            <BottomSection>
                <BottomContent>
                    <PostContainer />
                </BottomContent>
            </BottomSection>
        </Container>
    )
}

export default DiagnosisPage