import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import OtherPosts from '../../components/OtherPosts/OtherPosts.component'
import TextContainer from '../../container/TextContainer/TextContainer.component'
import { useAppSelector } from '../../hooks/hooks'
import { icons } from '../../static/icons/icon'
import { FlexColumn, FlexRow } from '../../styles/public'
import { api } from '../../util/api'
import { conversionDate } from '../../util/conversionTimestamp'
import { Container, Content, GrayText, TitleText, TopSection, TextSection, ModifiedAndDelete, Modified, Bar, Delete } from './ColumnPage.style'

const ColumnPage = () => {
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    
    const permission = useAppSelector((state: { userReducer: any }) => state.userReducer.permission);
    const [postID, setPostID] = useState("");
    const [title, setTitle] = useState("");
    const [nickname, setNickname] = useState("");
    const [date, setDate] = useState(0);
    const [contents, setContents] = useState(``);
    const [otherPosts, setOtherPosts] = useState([]);

    
    /** 게시글 정보 가저옴 */
    const getPost = () => {
        api.get(`/posts/${params.postid}`)
          .then((res: any) => {
            setTitle(res.data.post.title);
            setNickname(res.data.post.user_nickname);
            setDate(res.data.post.date);
            setContents(res.data.post.contents);
            setOtherPosts([res.data.next, res.data.previous]);
            setPostID(res.data.post.id)
          })
          .catch((err: any) => {
              console.log(err);
        });
    }

    /** 게시글 삭제 */
    const deletePost = () => {
        // 현재 사용자가 관리자인지 아닌지
        if(window.confirm('정말 삭제하시겠습니까?'))   
            api.delete(`/admin/posts/${postID}`)
            .then((res: any) => {
                alert('삭제되었습니다.')
                navigate(`/column`)
            })
            .catch((err: any) => {
                console.log(err);
            });
        else  
            return false;
    }

    useEffect(() => {
        getPost();
    }, [location])

    return (
        <Container>
            <TopSection background="/img/dummy.png">
                <Content>
                    <FlexColumn>
                        <TitleText>
                            {title}
                        </TitleText>
                        <FlexRow>
                            {icons.user_lighter}
                            <GrayText>by {nickname}</GrayText>
                            <GrayText>{conversionDate(date)}</GrayText>
                        </FlexRow>
                        { permission === 4 &&
                            <ModifiedAndDelete>
                                <Modified onClick={() => navigate(`/column/posting`, { state: {
                                    boardType: `column`,
                                    postID: postID,
                                    originTitle: title,
                                    originContents: contents
                                }})}>수정</Modified>
                                <Bar>|</Bar>
                                <Delete onClick={() => deletePost()}>삭제</Delete>
                        </ModifiedAndDelete> }
                    </FlexColumn>
                </Content>
            </TopSection>
            <TextSection>
                <Content>
                    <TextContainer>
                        {contents}
                    </TextContainer>
                </Content>
            </TextSection>
            <OtherPosts 
                boardType='column'
                otherPosts={otherPosts}
            />
        </Container>
  )
}

export default ColumnPage