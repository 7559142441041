import styled from "styled-components";
import { RootContainer, RootContent, RootSection } from "../../styles/public";
import theme from "../../styles/theme";

export const Container = styled(RootContainer)`
    background-color: ${theme.colors.background};
    padding-block: 3rem;
`

export const Section = styled(RootSection)`

`

export const Content = styled(RootContent)`
    flex-direction: column;

`

export const TitleText = styled.h1`
    font-weight: 700;
    font-size: 1.875rem;
    line-height: 2.75rem;
    letter-spacing: -0.02em;
    color: ${theme.colors.mainBlackColor}
`