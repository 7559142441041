import styled, { css } from "styled-components";
import { RootContainer, RootContent } from "../../styles/public";
import theme from "../../styles/theme";

export const Container = styled(RootContainer)`
    margin-bottom: 1.5rem;
    width: 100%;
`

export const Content = styled(RootContent)`

`

export const CommentTable = styled.table`
    width: 100%;
    border: 0;
    border-top: 1px solid ${theme.colors.mainColor};
`

export const Td = styled.td<{isMine? : boolean}>`
    position: relative;
    border-bottom: 1px solid ${theme.colors.greyColor3};
    padding: 1rem;
    height: 100%;
    box-sizing: border-box;
    padding-inline: 1rem;
    display: flex;
    background-color: ${props => props.isMine ? "rgba(149, 141, 141, 0.04);" : "transparent;" }
`

export const CommentWriteContainer = styled.div`
    padding-block: 1.5rem;
    border-bottom: 1px solid ${theme.colors.greyColor3};
`
export const CommentWriteBox = styled.div`
    padding: 1rem;
    border: 1px solid #F6F6F5;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: relative;
    box-sizing: border-box;
    width: 100%;
    background: rgba(149, 141, 141, 0.04);
    border-radius: 4px;
`

export const CommentInput = styled.textarea`
    box-sizing: border-box;
    width: 100%;
    height: 8rem;
    resize: none;
    background-color: transparent;
    color: ${theme.colors.greyColor1};
    font-size: 1.125rem;
    line-height: 1.75rem;
    letter-spacing: -0.02em;
    border: 0;
    :focus {
        outline: 0;
    }
    ::placeholder {
        color: ${theme.colors.greyColor3};
    }

    @media screen and (max-width: 576px) {
        font-size: 0.8125rem;
        line-height: 1.25rem;
    }
`

export const Username = styled.h3`
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.3125rem;
    color: ${theme.colors.mainBlackColor};
    margin-block: 0.25rem;
    @media screen and (max-width: 576px) {
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 150%;
        letter-spacing: -0.02em;
    }
`

export const CommentText = styled.p`
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1.75rem;
    letter-spacing: -0.02em;
    color: ${theme.colors.greyColor1};
    margin: 0.5rem 0;
    @media screen and (max-width: 576px) {
        font-size: 0.8125rem;
        line-height: 1.25rem;
    }
`

export const DateText = styled.p`
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.3125rem;
    color: ${theme.colors.greyColor3};
    margin: 0;
    @media screen and (max-width: 576px) {
        font-size: 0.75rem;
        line-height: 150%;
    }
`

export const WriteReply = styled.p`
    cursor: pointer;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.3125rem;
    color: #D7BFB9;
    margin-inline: 0.5rem;
    margin-block: 0;
`

export const MarginDiv = styled.div`
    margin-inline: 0.5rem;
`

export const ThreeCircleBtn = styled.div`
    cursor: pointer;
    padding-inline: 1rem;
    position: absolute;
    right: 0;
    top: 1.25rem;
    > div {
        right: 0;
        display: none;
        position: absolute;
        background: #FFFFFF;
        box-shadow: 0px 1px 4px rgba(94, 90, 90, 0.1);
        border-radius: 4px;
        width: 6rem;
    }

    &:hover {
        >div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }
`

export const Button = styled.button<{reply?: boolean}>`
    cursor: pointer; 
    background-color: ${theme.colors.mainColor};
    border-radius: 4px;
    border: 0;
    padding: 0.75rem 0.75rem;
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.125rem;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    ${props => props.reply &&
    css`
        border: 1px solid ${theme.colors.brownColor1};
        background-color: transparent;
        color: ${theme.colors.brownColor1}
    `}
`

export const ReplyWriteBox = styled.div`
    margin-left: 1rem;
    padding: 1rem;
    border: 1px solid #F6F6F5;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: relative;
    box-sizing: border-box;
    width: 100%;
    background: rgba(149, 141, 141, 0.04);
    border-radius: 4px;
`

export const ReplyInput = styled.textarea`
    box-sizing: border-box;
    width: 100%;
    height: 8rem;
    resize: none;
    background-color: transparent;
    color: ${theme.colors.greyColor1};
    font-size: 1.125rem;
    line-height: 1.75rem;
    letter-spacing: -0.02em;
    border: 0;
    :focus {
        outline: 0;
    }
    ::placeholder {
        color: ${theme.colors.greyColor3};
    }

    @media screen and (max-width: 576px) {
        font-size: 0.8125rem;
        line-height: 1.25rem;
    }
`

export const CommentModifyContainer = styled.div`
    padding-block: 1.5rem;
    border-bottom: 1px solid ${theme.colors.greyColor3};
`