import styled, { css, keyframes } from "styled-components";
import theme from "../../styles/theme";

export const Container = styled.div`
  padding-block: 1rem;
  width: 100%;
`;

export const TabsContainer = styled.div`
  display: flex;
  width: 50%;
  padding: 2px;
  @media screen and (max-width: 576px) {
    width: 100%;
    justify-content: center;
  }
`;

export const TabHeaderContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  @media screen and (max-width: 576px) {
    align-items: center;
  }
`;

export const StylizedTab = styled.button<{ active: boolean }>`
  color: #fff;
  width: 6.25rem;
  padding-block: 1rem;
  background-color: transparent;
  border: none;
  color: ${theme.colors.greyColor2};
  font-weight: 500;
  font-size: 1.1875rem;
  line-height: 1.4375rem;
  cursor: ${(p) => (p.disabled ? "default" : "pointer")};

  ${(props) =>
    props.active &&
    css`
      color: ${theme.colors.mainColor};
      font-weight: bold;
    `}
`;

export const TabsHolder = styled.div`
  display: flex;
  flex-direction: row;
`;

export const TabSlider = styled.div<{ width: number; index: number }>`
  width: 100px;
  height: 2.5px;
  background-color: ${theme.colors.mainColor};
  transition: 0.2s;
  transform: ${(props) => `translateX(${props.index * 200 / props.width}%)`};
  
  @media screen and (max-width: 576px) {
    ${(props) =>
      props.index === 0 ?
      css`
        transform: translateX(-50px);
      ` :
      css`
        transform: translateX(50px);
    `}
  }
`;

export const TapLine = styled.div`
    position: absolute;
    bottom: -2px;
    width: 200%;
    height: 2px;
    background-color: ${theme.colors.greyColor3};
    @media screen and (max-width: 576px) {
      width: 100%;
    }
`
