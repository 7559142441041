import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import PageMove from '../../components/PageMove/PageMove.component'
import PostTable from '../../components/PostTable/PostTable.component'
import SearchBar from '../../components/SearchBar/SearchBar.component'
import { useAppSelector } from '../../hooks/hooks'
import { FlexRowJustifyContentSpaceBetween } from '../../styles/public'
import { api } from '../../util/api'
import { conversionMilliSec } from '../../util/conversionTimestamp'
import { dateRegexp } from '../../util/regexp'
import { WriteBtn } from './PostsContainer.style'

interface Props {
  missionType?: string
}

const options = [
  { value: 'title_contents', content: '제목+내용' },
  { value: 'contents', content: '내용' },
  { value: 'date', content: '작성일자' },
  { value: 'nickname', content: '닉네임' }
]

const PostContainer = ({ missionType }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  
  const userPermission = useAppSelector((state: { userReducer: any }) => state.userReducer.permission); // 유저 권한
  const courses = useAppSelector((state: { userReducer: { courses: any; }; }) => state.userReducer.courses);

  const boardType = location.pathname.split("/")[1] // 게시판 타입, 미션 타입을 props로 받으면 미션 타입으로, 아니라면 url에서 가져옴  
  const pageNumber = params.pagenumber

  const [isShowWriteBtn, setIsShowWriteBtn] = useState(false); // 글쓰기 버튼 보일지
  const [postingPath, setPostingPath] = useState(''); // 글쓰기 페이지 파라미터
  const [posts, setPosts] = useState([]); // 게시글 데이터
  const [totalPageNum, setTotalPageNum] = useState(0); // 전체 페이지 수

  const [searchWord, setSearchWord] = useState(null);
  const [searchOption, setSearchOption] = useState(options[0].value);
  const [searchPlaceholder, setSearchPlaceholder] = useState('');

  useEffect(() => {
    searchOption === 'date' ? setSearchPlaceholder('YYYYMMDD 형식으로 입력해주세요.') : setSearchPlaceholder('2글자 이상 입력해주세요.')
  }, [searchOption])

  const onSearchWordHanlder=(e: any)=>{
    setSearchWord(e.currentTarget.value)
  }
  const onSearchOptionHanlder=(e: any)=>{
    setSearchOption(e.currentTarget.value)
  }

  /** 게시글 가져오기 */
  const getPosts = () => {
    const query_parameter = `board_type=${missionType ? missionType : boardType}&page=${pageNumber}`
    
    api.get(`/posts?${query_parameter}`)
      .then((res: any) => {
        setTotalPageNum(res.data.totalPageNum)
        setPosts(res.data.pinned.concat(res.data.posts))
      })
      .catch((err: any) => {
          console.log(err);
    });
  }

  const searchPosts = () => {
    // 검색어가 있을 때,
    if (searchWord) {
      let word = searchWord
      // 검색 옵션이 날짜 일 때,
      if (searchOption === 'date') {
        if(dateRegexp(searchWord)) {
          word = conversionMilliSec(searchWord)
          console.log(word)
      } else {
        alert('YYYYMMDD 형식으로 입력해주세요.')
        return
      }
    }
    if (searchWord.length <= 2) {
      alert('2글자 이상 입력해주세요.')
      return
    }
      
      const query_parameter = `board_type=${missionType ? missionType : boardType}&page=${pageNumber}&target_category=${searchOption}&word=${word}`
      api.get(`/posts?${query_parameter}`)
      .then((res: any) => {
        if (!res.data.err) {
          setTotalPageNum(res.data.totalPageNum)
          setPosts(res.data.pinned.concat(res.data.posts))
        } else {
          setTotalPageNum(0)
          setPosts([])
        }
      })
      .catch((err: any) => {
          console.log(err);
      });
    } else {
      alert('검색어를 입력해주세요.')
    }
  }
  useEffect(() => {
    if (missionType && !courses.includes(missionType)) {
      alert('구매하지 않은 강의입니다!')
      navigate(-1);
    }

    getPosts();
  }, [location])

  useEffect(() => {
    // 공지사항, 장학금 페이지 일 때, 관리자 계정이면 글쓰기 버튼 보이게
    if (boardType === 'mission') {
      if(userPermission >= 0) {
        setIsShowWriteBtn(true);
        setPostingPath(`/mission/${missionType}/posting`);
      }
    } else {
      if(userPermission === 4) {
        setIsShowWriteBtn(true);
        setPostingPath(`/${boardType}/posting`);
      }
    }
  }, [boardType, location, missionType, userPermission])

  return (
    <>
        <FlexRowJustifyContentSpaceBetween>
            <SearchBar>
              <SearchBar.SelectBox
                options={options}
                setOption={onSearchOptionHanlder}
              />
              <SearchBar.InputText
                setWord={onSearchWordHanlder}
                placeholder={searchPlaceholder}
              />
              <SearchBar.SearchBtn searchPosts={searchPosts} />
            </SearchBar>

            {
              isShowWriteBtn &&
              <WriteBtn onClick={() => navigate(postingPath, { state: { boardType: boardType, missionType: missionType }})}>글쓰기</WriteBtn>
            }

        </FlexRowJustifyContentSpaceBetween>
        <PostTable datas={posts} missionType={missionType} />
        <PageMove
            totalPageNum={totalPageNum}
            currentPageNumber={Number(pageNumber)}
            boardType={boardType}
            missionType={missionType}
        />
    </>
  )
}

export default PostContainer