import React from 'react'
import AccordionTab from '../../components/FAQAccordionTab/FAQAccordionTab.component';
import { Container, DescriptionText, TitleText } from './FAQContainer.style'

interface Props {
  FAQList: Array<{ q: string, a: string}>
}

const FAQContainer = ({ FAQList }: Props) => {
  return (
    <Container>
      <TitleText>
        자주묻는 질문
      </TitleText>
      <DescriptionText>
        직장 탈출 학교는 직원이 따로 없습니다. 대부분의 궁금한 점은 아래를 통해 답변이 됩니다.
      </DescriptionText>
      <AccordionTab FAQList={FAQList} />
    </Container>
  )
}

export default FAQContainer