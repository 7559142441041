import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import SocialLoginBtn from '../../components/SocialLoginBtn/SocialLoginBtn.component'
import { Container, Contnet, TitleText, Line, SignUpText, FindPWText, FindIDText } from './LoginPage.style'
import { useAppDispatch } from "../../hooks/hooks";
import { handleLogin, setUserCourses, setUserID, setUserNickname, setUserPermission, setUserSocial } from "../../redux/slice/userSlice";
import InputBox from '../../components/InputBox/InputBox.component';
import SubmitBtn from '../../components/SubmitBtn/SubmitBtn.component';
import { Form } from '../../styles/public';
import { api } from '../../util/api'
import { setCookie } from '../../util/cookie'; 
import { useForm } from "react-hook-form"
import { Link } from 'react-router-dom';

const LoginPage = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    /** 정보 가져오기 */
    const getUserInfo = () => {
        api.get('/user')
        .then((res: any) => {
            dispatch(handleLogin(true));
            dispatch(setUserID(res.data.info.login_id));
            dispatch(setUserNickname(res.data.info.nickname));
            dispatch(setUserPermission(res.data.info.permission));
            dispatch(setUserSocial(res.data.info.social));
            dispatch(setUserCourses(res.data.info.availableCourse));
        })
        .catch((err: any) => {
            console.log(err);
        });
    }

    /** 로그인 */
    const login = (data: any) => {
        api.post('/signjoin/sign', {
            "id": data.id,
            "pw": data.pw
        })
        .then((res: any) => {
            if (res.data.ok) {
                setCookie('token', res.data.access, {
                    path: '/',
                    maxAge: 60 * 60 * 24, // 24시간
                });
                getUserInfo();
                
                navigate('/')
            } else {
                alert('아이디 또는 비밀번호가 잘못되었습니다.');
            }
        })
        .catch((err: any) => {
            if (err.respose.status === 400) {
                alert(`잘못된 정보입니다.`)
            } else {
                alert(`시스템 오류입니다.`)
            }
        });
    }                                                                               

    const {
        setValue,
        register,
        formState: { isSubmitting, errors },
        handleSubmit
    } = useForm();

    useEffect(() => {
        register("id", {
            required: "아이디를 입력 해주세요.",
            pattern: {
              value: /^[a-z]+[a-z0-9]{5,19}$/,
              message: "아이디 형식에 맞지 않습니다."
            }
        });

        register("pw", {
            required: "비밀번호를 입력 해주세요.",
            minLength: {
                value: 8,
                message: "8자리 이상 비밀번호를 사용하세요."
            }
        });
    }, [register]);

    return (
        <Container>
            <Contnet>
                <TitleText>로그인</TitleText>
                <Form onSubmit={handleSubmit(login)}>
                    <InputBox>
                        <InputBox.Label htmlFor="id">아이디</InputBox.Label>
                        <InputBox.InputText
                            type="text"
                            placeholder='아이디를 입력해 주세요.'
                            handler={async (e: any) => setValue("id", e.target.value)}
                        />
                        { errors?.id && <InputBox.ErrorText>{`${errors?.id?.message}`}</InputBox.ErrorText> }
                    </InputBox>

                    <InputBox>
                        <InputBox.Label htmlFor="pw">비밀번호</InputBox.Label>
                        <InputBox.InputText 
                            placeholder='비밀번호를 입력해 주세요.'
                            type='password'
                            handler={async (e: any) => setValue("pw", e.target.value)}
                        />
                        { errors?.pw && <InputBox.ErrorText>{`${errors?.pw?.message}`}</InputBox.ErrorText> }
                    </InputBox>

                    <SubmitBtn
                        value='로그인'
                        type='submit'
                        disabled={isSubmitting}
                    />
                </Form> 
                
                <Line />

                {/* <SocialLoginBtn company='kakao' />
                <SocialLoginBtn company='naver' /> */}
                
                <SignUpText>아직 회원이 아니신가요? <Link to='/signup'>회원가입</Link></SignUpText>
                <FindIDText><Link to='/findid'>아이디 찾기</Link></FindIDText>
                <FindPWText><Link to='/findpassword'>비밀번호 찾기</Link></FindPWText>
            </Contnet>
        </Container>
    )
}

export default LoginPage