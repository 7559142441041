import axios, { AxiosInstance } from 'axios';
import { setCookie, getCookie } from './cookie';

export const api: AxiosInstance = axios.create({
    baseURL: 'https://onoff-house.com:8000', // 기본 서버 주소 입력
    headers: {
        'Content-Type': 'application/json',
    },
});

axios.defaults.withCredentials = true;

api.interceptors.request.use((config: any) => {
    const token = getCookie('token');
    config.headers.Authorization = token ? `Bearer ${token}` : "";    
    return config;
});

api.interceptors.response.use((config: any) => {
    if (config.data.newToken) {
        setCookie('token', config.data.newToken, { // 새로운 토큰 발급
            path: '/',
            maxAge: 60 * 60 * 24, // 24시간
        });
        window.location.reload();
    }
    return config;
});