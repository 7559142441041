import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

interface Props {
    condition: boolean,
    message: string,
    fallback: string,
    page: ReactNode
}

const PrivateRoute = ({ condition, page, fallback, message }: Props) => {
    return (
        condition ? <>{page}</> : <><Navigate to={fallback} {...(alert(message) as any)} /></>
        
    )
}

 export default PrivateRoute 