import HTMLReactParser from 'html-react-parser'
import React, { useEffect, useState } from 'react'
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom'
import TitleContent from '../../components/TitleContent/TitleContent.component'
import PostEditor from '../../components/PostEditor/PostEditor.component'
import SubmitBtn from '../../components/SubmitBtn/SubmitBtn.component'
import { api } from '../../util/api'
import { Container, Content, Section } from './EditFAQPage.style'

const EditFAQPage = () => {
  const navigate = useNavigate();

  const [FAQList, setFAQList] = useState([]);
  const [newQuestion, setNewQuestion] = useState('');
  const [newAnswer, setNewAnswer] = useState('');

  const getFAQList = () => {
    api.get(`/faq`)
      .then((res: any) => {
        setFAQList(res.data.faqList)
      })
      .catch((err: any) => {
        console.log(err);
    })
  }
  
  useEffect(() => {
    getFAQList()
  }, [])

  const addFAQ = () => {
    if (newQuestion === '') {
      alert('질문을 입력해주세요!')
    } else if (newAnswer === "") {
      alert('답변을 입력해주세요!')
    } else {

        api.post('/faq', {
            "question" : newQuestion,
            "answer" : newAnswer
          })
          .then((res: any) => {
            if (res.data.err) {
              alert(`추가에 실패했습니다. ${res.data.err}`)
            } else {
                setFAQList(FAQList => [...FAQList, res.data.faq])
            }
          })
            .catch((err: any) => {
                console.log(err);
          })
        
        
        setNewQuestion('');
        setNewAnswer('');
    }
  }

  const deleteFAQ = (index: number) => {
    const newFAQList = FAQList.filter((content, i) => i !== index);
    setFAQList(newFAQList)
  } 
  useEffect(() => {
    getFAQList()
  }, [])


  const EditFAQ = () => {
    api.put('/faq', {
      "faqList" : FAQList
    })
    .then((res: any) => {
      if (res.data.err) {
        alert(`작성에 실패했습니다. ${res.data.err}`)
      } else {
        alert('작성되었습니다.');
        navigate(`/FAQ`)
      }
    })
      .catch((err: any) => {
          console.log(err);
    })
  }


  return (
    <Container>
      <Section>
        <Content>
          <TitleContent>
            <TitleContent.Title>FAQ</TitleContent.Title>
            <TitleContent.Container>
            { FAQList && FAQList.map((data, index) => {
              return (
                <TitleContent.Box key={`QnA${index}`}>
                  <TitleContent.Question>
                    Q. {data.question}
                  </TitleContent.Question>
                  <TitleContent.Answer>
                    A. {HTMLReactParser(data.answer)}
                  </TitleContent.Answer>
                  <TitleContent.DelButton
                    handler={() => deleteFAQ(index)}
                  >삭제</TitleContent.DelButton>
                </TitleContent.Box>
              )
            }) }
            </TitleContent.Container>
            <hr />
            <TitleContent.Container>
                <TitleContent.SubTitle>Question</TitleContent.SubTitle>
                <TitleContent.Input 
                  value={newQuestion}
                  setValue={setNewQuestion}
                />
                <TitleContent.SubTitle>Answer</TitleContent.SubTitle>
                <PostEditor
                  type="intro"
                  value={newAnswer}
                  setValue={setNewAnswer}
                />
                <TitleContent.AddButton
                  handler={addFAQ}
                >추가</TitleContent.AddButton>
            </TitleContent.Container>
          </TitleContent>

          <SubmitBtn 
            value='강의 정보 수정'
            type='submit'
            handler={EditFAQ}
          />
        </Content>
      </Section>
    </Container>
  )
}

export default EditFAQPage