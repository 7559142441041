import React, { useEffect, useState } from 'react'
import PageMove from '../../components/PageMove/PageMove.component';
import SubmitBtn from '../../components/SubmitBtn/SubmitBtn.component';
import TitleContent from '../../components/TitleContent/TitleContent.component'
import UserInfo from '../../components/UserInfo/UserInfo.component';
import UserInfoTable from '../../components/UserInfoTable/UserInfoTable.component';
import { icons } from '../../static/icons/icon';
import { api } from '../../util/api';
import { Container, Content, Section } from './AdminPage.style'

const AdminPage = () => {
    const [notice, setNotice] = useState('');
    const [userInfoList, setUserInfoList] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const [totalPageNum, setTotalPageNum] = useState(1);

    const range =  Math.floor((pageNum - 1) / 5) * 5 + 1
    const lastNumber = Math.min(range + 4, totalPageNum)


    const getNotice = () => {
        api
          .get("/admin/notice")
          .then((res: any) => {
            if (!res.data.err) {
                setNotice(res.data.contents);
            } else {
                alert(`정보를 가져올 수 없습니다. ${res.data.err}`)
            }
          })
          .catch((err: any) => {
            console.log(err);
          });
    }

    const putNotice = () => {
        if (notice === "") {
            alert('공지 내용을 입력해주세요!')
            return
        }

        api
          .put("/admin/notice", {
            "notice": notice
          })
          .then((res: any) => {
            if (res.data.err) {
                alert(`작성에 실패했습니다. ${res.data.err}`)
            } else {
                alert('공지를 설정하였습니다.');
            }
          })
          .catch((err: any) => {
            console.log(err);
          });
    }

    const getAllUserInfo = (page: number) => {
        api
          .get(`/admin/user?page=${page}`)
          .then((res: any) => {
              console.log(res.data)
            setUserInfoList(res.data.userArray)
            setTotalPageNum(res.data.totalPage)
            setPageNum(page);
          })
          .catch((err: any) => {
            console.log(err);
          });
    }

    useEffect(() => {
        getNotice();
        getAllUserInfo(1);
    }, [])

    return (
        <Container>
        <Section>
            <Content>
                <TitleContent>
                    <TitleContent.Title>공지 설정</TitleContent.Title>
                    <TitleContent.Container>
                        <TitleContent.SubTitle>내용</TitleContent.SubTitle>
                        <TitleContent.Input 
                            value={notice}
                            setValue={setNotice}
                        />
                        <SubmitBtn 
                            value='공지 등록'
                            type='submit'
                            handler={putNotice}
                        />
                    </TitleContent.Container>

                    <hr />
                    
                    <TitleContent.Title>회원 목록</TitleContent.Title>
                    <TitleContent.Container>
                        <UserInfoTable>
                            <>
                                <UserInfoTable.Th>
                                    아이디   
                                </UserInfoTable.Th>
                                <UserInfoTable.Th>
                                    이름
                                </UserInfoTable.Th>
                                <UserInfoTable.Th>
                                    닉네임   
                                </UserInfoTable.Th>
                                <UserInfoTable.Th>
                                    이메일   
                                </UserInfoTable.Th>
                                <UserInfoTable.Th>
                                    전화번호   
                                </UserInfoTable.Th>
                                <UserInfoTable.Th>
                                    등급
                                </UserInfoTable.Th>
                                {
                                    userInfoList && userInfoList.map((userInfo, idx) => { return (
                                        <UserInfoTable.Tr key={`userInfo${idx}`}>
                                            <UserInfoTable.Td>
                                                {userInfo.login_id}
                                            </UserInfoTable.Td>
                                            <UserInfoTable.Td>
                                                {userInfo.name}
                                            </UserInfoTable.Td>
                                            <UserInfoTable.Td>
                                                {userInfo.nickname}
                                            </UserInfoTable.Td>
                                            <UserInfoTable.Td>
                                                {userInfo.email}
                                            </UserInfoTable.Td>
                                            <UserInfoTable.Td>
                                                {userInfo.phone}
                                            </UserInfoTable.Td>
                                            <UserInfoTable.Td>
                                                {userInfo.permission}
                                            </UserInfoTable.Td>
                                        </UserInfoTable.Tr>
                                    )})
                                }
                            </>
                        </UserInfoTable>
                        <UserInfoTable.PageMove>
                            <UserInfoTable.Btn 
                                onClick={() => getAllUserInfo(pageNum-1)}
                                disabled={pageNum === 1}
                            >
                                {icons.leftArrow}
                            </UserInfoTable.Btn>
                            {Array.from({length: lastNumber % 5 ? lastNumber % 5 : 5 }, (v, i)=> i + range).map((n) => {
                                return (
                                    <UserInfoTable.Btn 
                                        key={`page${n}`}
                                        disabled={pageNum === n}
                                        onClick={() => getAllUserInfo(n)}
                                    >
                                        {n}
                                    </UserInfoTable.Btn>
                                )
                            })}
                            <UserInfoTable.Btn 
                                onClick={() => getAllUserInfo(pageNum+1)}
                                disabled={pageNum === totalPageNum}
                            >
                                {icons.rightArrow}
                            </UserInfoTable.Btn>
                        </UserInfoTable.PageMove>
                    </TitleContent.Container>
                </TitleContent>
            </Content>
        </Section>
        </Container>
    )
}

export default AdminPage