import React, { useEffect, useState } from 'react'
import Column from '../../components/Column/Column.component';
import { FlexColumn, } from '../../styles/public';
import { Container, Section, Content, TitleText, DescriptionText, ColumnsContainer, Button } from './ColumnsPage.style';
import { useNavigate } from 'react-router-dom';
import { api } from '../../util/api';
import { conversionDate } from '../../util/conversionTimestamp';
import { useAppSelector } from '../../hooks/hooks';

const ColumnPage = () => {
  const navigate = useNavigate();
  const [posts, setPosts] = useState([]); // 게시글 데이터

  const permission = useAppSelector((state: { userReducer: { permission: any; }; }) => state.userReducer.permission);

  const getPosts = () => {
    api.get(`/posts/?board_type=column&page=1`)
      .then((res: any) => {
        setPosts(res.data.posts)
      })
      .catch((err: any) => {
          console.log(err);
    });
  }
  useEffect(() => {
    getPosts();
  }, [])

  return (
    <Container>
      <Section>
        <Content>
          <FlexColumn>
            <TitleText>
              칼럼만 읽어도 <span>직장 탈출이 가능합니다.</span>
            </TitleText>
            <DescriptionText>
              기존의 고정관념을 깨지 않으면 당신의 인생은 절대 변하지 않습니다.
            </DescriptionText>
            <DescriptionText>
              아래 6가지 칼럼만 읽어도 당신의 삶을 충분히 바꿀 수 있습니다. 
            </DescriptionText>
          </FlexColumn>
          {permission === 4 &&
            <Button onClick={() => navigate(`/column/posting`, { state: { boardType: 'column' }})}>글쓰기</Button>
          }
          
          <ColumnsContainer>
            {
              posts.map((post, i) => {
                return (
                  <Column postid={post.id}>
                    <Column.Index>{i + 1}</Column.Index>
                    <Column.Thumbnail src="img/dummy.png" alt={post.title} />
                    <Column.TextBox>
                      <Column.Title>{post.title}</Column.Title>
                      <Column.Date>{conversionDate(post.date)}</Column.Date>
                    </Column.TextBox>
                  </Column>
                )
              })
            }
          </ColumnsContainer>
        </Content>
      </Section>
    </Container>
  )
}

export default ColumnPage