import React, { useEffect, useState } from 'react';
import { icons } from '../../static/icons/icon';
import { RootContent } from '../../styles/public';
import MobileMenuBox from '../MobileMenuBox/MobileMenuBox.component';
import Hamburger from 'hamburger-react'
import { useCookies } from "react-cookie";
import moment from 'moment';
import { useAppSelector, useAppDispatch } from "../../hooks/hooks";


import { 
    HeaderContainer,
    PopupBar, 
    PopupBarText, 
    CloseContainer, 
    IndexBar,
    IndexBox,
    CloseBtn,
    JiktalLogo,
    StyledLink,
    LoginBtn,
    MobileLogin,
    MobileMenuContainer,
    MobileLogout
} from './TopNavi.style';
import theme from '../../styles/theme';
import { useNavigate } from 'react-router-dom';
import UserBtn from '../../components/UserBtn/UserBtn.component';
import ToggleDropdown from '../../components/ToggleDropdown/ToggleDropdown.component';
import { getCookie, removeCookie, setCookie } from '../../util/cookie';
import { resetUserInfo } from "../../redux/slice/userSlice";
import { api } from '../../util/api';

const TopNavi = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const courses = useAppSelector((state: { userReducer: { courses: any; }; }) => state.userReducer.courses);

    const [notice, setNotice] = useState('');
    const getNotice = () => {
        api
          .get("/notice")
          .then((res: any) => {
            if (!res.data.err) {
                setNotice(res.data.contents);
            } else {
                setNotice("🤔 회사를 위한 것이 아닌, 나의 가치를 높이며 살자!");
            }
          })
          .catch((err: any) => {
            console.log(err);
          });
    }

    useEffect(() => {
        getNotice();
      }, [])

    const indexs = [
        {
            "name" : "입학 안내",
            "url" : "/admission",
            "subIndexs" : [
                {
                    "name" : "직장탈출학교란",
                    "url" : "/admission",
                },
                {
                    "name" : "신입생가이드",
                    "url" : "/admission/guide",
                },
                {
                    "name" : "자주묻는질문",
                    "url" : "/FAQ",
                },
            ]
        }, {
            "name" : "교육 과목",
            "url" : "/subjects",
            "subIndexs" : null
        }, {
            "name" : "교육 활동",
            "url" : "/notice/1",
            "subIndexs" : [
                {
                    "name" : "공지사항",
                    "url" : "/notice/1",
                },
                {
                    "name" : "미션제출",
                    "url" : `/mission/${courses ? courses[0] : 'messenger'}/1`,
                },
            ]
        }, {
            "name" : "칼럼",
            "url" : "/column",
            "subIndexs" : null
        }, {
            "name" : "장학금",
            "url" : "/scholarship/1",
            "subIndexs" : null
        }, {
            "name" : "진단서",
            "url" : "/diagnosis/1",
            "subIndexs" : null
        },
    ]

    const [isPopupClose, setIsPopupClose] = useState(false);
    const [isMenuBoxOn, setIsMenuBoxOn] = useState(false);
    const isLoggedIn = useAppSelector((state) => state.userReducer.isLoggedIn);

    const logOut = () => {
        removeCookie('token');
        dispatch(resetUserInfo())
        navigate('/');
		window.location.reload();
	};

    useEffect(() => {
        const currentCookies = getCookie('popupclose');
        setIsPopupClose(currentCookies);
    }, []);

    const closePopUp = () => {
        const expires: Date = moment().add(1, 'days').toDate();
        setCookie('popupclose', true, {expires});
        setIsPopupClose(true);
    }

  return (
    <>
        <HeaderContainer>
            {/* 상단 팝업 바 */}
            {
                !isPopupClose && (
                    <PopupBar>
                        <RootContent>
                            <PopupBarText>{notice}</PopupBarText>
                            <CloseContainer>
                                <PopupBarText>1일동안 보지 않기</PopupBarText>
                                <CloseBtn onClick={closePopUp}>
                                    {icons.closeButton}
                                </CloseBtn>
                            </CloseContainer>
                        </RootContent>
                    </PopupBar>
                )
            }
            
            {/* 목차 바 */}
            <IndexBar>
                <RootContent>
                    <JiktalLogo to="/">
                        {icons.jiktallogo}
                    </JiktalLogo>
                    { indexs.map((index) => {
                        return (
                            <IndexBox>
                                <StyledLink key={index.name} to={index.url}>
                                    {index.name}
                                </StyledLink>
                                { 
                                    index.subIndexs && 
                                    <ToggleDropdown>
                                        {
                                        index.subIndexs.map((subindex) => {
                                            return (
                                                <ToggleDropdown.Link key={subindex.name} to={subindex.url}>{subindex.name}</ToggleDropdown.Link>
                                            )
                                        })}
                                    </ToggleDropdown>
                                }
                            </IndexBox>
                            
                        )
                    })}        
                    
                    {/* 로그인 확인 */}
                    {isLoggedIn ? (
                        <UserBtn />
                    ) : (
                        <LoginBtn to="/login">
                            로그인
                        </LoginBtn> 
                    )}

                    {/* 모바일 */}
                    <MobileMenuContainer>
                        {isMenuBoxOn ? (
                            !isLoggedIn ? (
                                <MobileLogin to="/login" onClick={() => setIsMenuBoxOn(false)}>
                                    {icons.mobile_user}
                                    <p>
                                        로그인
                                    </p>
                                </MobileLogin>
                            ) : (
                                <MobileLogout onClick={() => logOut()}>
                                    {icons.mobile_user}
                                    <p>
                                        로그아웃
                                    </p>
                                </MobileLogout>
                            ))
                        : null}

                        <Hamburger 
                                toggled={isMenuBoxOn} 
                                toggle={setIsMenuBoxOn} 
                                size={24}
                                color={theme.colors.mainColor}
                        />
                    </MobileMenuContainer>

                </RootContent>
            </IndexBar>
        </HeaderContainer>
        <MobileMenuBox indexs={indexs} on={isMenuBoxOn} setOn={setIsMenuBoxOn} />
    </>
  )
}

export default TopNavi