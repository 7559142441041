import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Img } from '../../styles/public'
import { 
  Container, 
  FirstSection,
  FirstSectionContent,
  FirstSectionTitleText,
  FirstSectionSmallText,
  SecondSection,
  SecondSectionContent,
  SecondSectionTitleText,
  SecondSectionBasicText,
  SecondSectionGrayText,
  TextDiv,
  CircleImg,
  ThirdSection,
  ThirdSectionContent,
  GreenButton,
  ThirdSectionText,
} from './AdmissionPage.style'

const AdmissionPage = () => {
  // 윈도우 width 크기 가져오기
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []); 

  
  return (
    <Container>
      <FirstSection>
        <FirstSectionContent>
          <div>
            <FirstSectionTitleText>우리의 목적은<br /><b>회사를 위해 사는 것이 아닌<br />나 자신의 몸값을 올리는 것</b> 입니다.</FirstSectionTitleText>
            <FirstSectionSmallText>직장탈출학교란?</FirstSectionSmallText>
          </div>
        </FirstSectionContent>
      </FirstSection>
      
      
      <SecondSection>
        <SecondSectionContent>
          <TextDiv>
            <SecondSectionTitleText>이게 무슨 말인가요?</SecondSectionTitleText>
            { windowWidth <= 576 &&
              <CircleImg>
                <Img src='img/admissionpage_secondsection_picture1.png' />
              </CircleImg>
            }
            <SecondSectionGrayText>
            저는 물리치료사로 일하면서 의문을 가졌습니다.
            </SecondSectionGrayText>
            <SecondSectionBasicText>
              '내가 과연 이 일을 평생할 수 있을까?'<br />
              '이 월급으로 내 가족을 지킬 수 있을까?'<br />
              '나는 지금 누구를 위해 일하고 있는 걸까?'<br />
            </SecondSectionBasicText> 
            <SecondSectionGrayText>
            좋아하는 일이라도 타인에 의해 정해진 공간과 시간에서 하게 되면 그것은 일이 됩니다. 결국 버티는 인생을 살며 퇴근과 주말만 기다리겠죠. 저는 INFP 유형으로 남 눈치를 많이 보고, 혼자 일할 때 행복을 느낍니다.
            </SecondSectionGrayText>
            <SecondSectionBasicText>
              그래서 퇴근 이후에는 직장을 탈출하는 방법을 연구했습니다.
            </SecondSectionBasicText>
          </TextDiv>

          { windowWidth > 576 &&
            <CircleImg>
              <Img src='img/admissionpage_secondsection_picture1.png' />
            </CircleImg>
          }
          { windowWidth > 576 &&
            <CircleImg>
              <Img src='img/admissionpage_secondsection_picture2.png' />
            </CircleImg>
          }
        
          <TextDiv>
            <SecondSectionTitleText>직장을 다니면서<br />도대체 뭘 한 건가요?</SecondSectionTitleText>
            { windowWidth <= 576 &&
              <CircleImg>
                <Img src='img/admissionpage_secondsection_picture2.png' />
              </CircleImg>
            }
            <SecondSectionBasicText>
              유튜브 채널을 운영하면서 가장 많이 들었던 질문이기도 합니다.<br /> 
              제가 직장을 다니면서 얻으려고 했던 능력은 크게 두 가지입니다.
            </SecondSectionBasicText> 
            <SecondSectionGrayText>
              1. 사람을 모으는 방법 <br />
              2. 사람을 설득하는 방법
              <br /><br />
              두 가지 능력만 얻게 된다면, 무엇을 하든 돈을 벌 수 있을 거라 확신했습니다. 
              이 능력들을 얻기 위해 수 백권의 책을 읽고 강의를 들었습니다. 당연히 무수한 
              장애물을 만나 실패했고 20대 전부를 소비했습니다. 그리고 결국 돈 없이 시작할 수 있는 '메신저 사업'을 발견했죠
            </SecondSectionGrayText>
          </TextDiv>
          
          <TextDiv>
            <SecondSectionTitleText>왜 강의를 들어도<br />인생이 달라지지 않을까</SecondSectionTitleText>
            { windowWidth <= 576 &&
              <CircleImg>
                <Img src='img/admissionpage_secondsection_picture3.png' />
              </CircleImg>
            }
            <SecondSectionGrayText>
            사실, 요즘은 돈 버는 방법을 쉽게 알 수 있는 세상입니다. 하지만 10명 중 9명이 성과를 내지 못하는데, 그 이유에는 두 가지가 있습니다.
            <br /><br />
            1. 완강을 하지 않는다<br />
            2.해보지를 않는다
            <br /><br />
            저는 2년 넘게 여러 곳에서 강의를 해봤습니다. 해당 관리자님들한테 들어보니 강의 완강률이 15%도 안 나온다는 겁니다. 저는 그때 큰 충격을 받았습니다. 심지어 강의를 듣고 실제로 해보는 사람은 매우 드물었습니다. 그러니 당연히 인생이 달라지지 않겠죠. 완강률을 높일 수 있는 방법, 실행을 할 수 있게 만드는 방법에 대해 고민했습니다. 이 두 가지 목표를 이루기 위해서는 다양한 기능이 필요했습니다. 
            </SecondSectionGrayText>
            <SecondSectionBasicText>
              따라서 5개월이라는 시간과 수천 만원의 투자금이 들어간 끝에 &lt;직장 탈출 학교&gt;를 론칭하게 되었습니다.
            </SecondSectionBasicText>
          </TextDiv>
          { windowWidth >= 576 &&
            <CircleImg>
              <Img src='img/admissionpage_secondsection_picture3.png' />
            </CircleImg>
          }
          { windowWidth >= 576 &&
            <CircleImg>
              <Img src='img/admissionpage_secondsection_picture4.png' />
            </CircleImg>
          }

          <TextDiv>
            <SecondSectionTitleText>광고를 하지 않는 이상한 곳</SecondSectionTitleText>
            { windowWidth < 576 &&
              <CircleImg>
                <Img src='img/admissionpage_secondsection_picture4.png' />
              </CircleImg>
            }
            <SecondSectionGrayText>
              2020년 1월, 온라인 강의 초창기 때부터 강의를 해왔습니다. 그리고 코로나가 확산되면서 온라인 시장은 미친듯이 커졌죠. 근데 요즘 공포 심리를 자극하는 문구가 너무 많이 보입니다. 실제로 여러 사업을 하면서 다음과 같은 말을 자주 들었습니다.
            </SecondSectionGrayText>
            <SecondSectionBasicText>
              "일단 어그로를 끌어서 이런게 있다고 알려야지"<br />
              "이번에 신청하지 않으면 기회가 없도록 공포를 조성해야해"
            </SecondSectionBasicText> 
            <SecondSectionGrayText>
              물론 이렇게 하는게 판매가 잘 되고 매출이 좋습니다. 저도 다른 플랫폼에서 강의를 할 때 이런 식으로 홍보를 했었으니까요. 하지만, 그러다 보니 저만의 고유한 색깔을 잃어가고 있었습니다. 그래서 담당자님에게 독립하겠다 말하고 직장 탈출 학교를 만들게 되었습니다.
            </SecondSectionGrayText>
            <SecondSectionBasicText>
              못 벌어도 그냥 제가 좋은 거 하려고요. 결이 비슷한 사람들에게만 제 에너지를 모두 쏟고 싶은 마음이 큽니다. 그래서 저는 직원을 따로 두지 않고 미련하게 혼자서 일하는 걸 고집합니다.
            </SecondSectionBasicText>
          </TextDiv>

        </SecondSectionContent>
      </SecondSection>
      
      <ThirdSection>
        <ThirdSectionContent>
          <div>  
            <ThirdSectionText>직장탈출학교는 교육법에 따른 시설이 아닌,<br/><b>학교컨셉입니다.</b></ThirdSectionText>
            <Link to={'/admission/guide'} style={{"textDecoration" : "none"}}>
              <GreenButton>신입생 가이드 보러가기</GreenButton>
            </Link>
          </div>
        </ThirdSectionContent>
      </ThirdSection>
    </Container>
  )
}

export default AdmissionPage