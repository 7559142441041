import React, { ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'
import { icons } from '../../static/icons/icon'
import { CategoryBox, Container, DescriptionText, PlayIcon, TextContainer, Thumbnail, ThumbnailContainer, TitleContainer, TitleText } from './SubjectCard.style'

interface Props {
    children: ReactNode
    category?: string
}

interface ImgProps {
  src: string
}

const SubjectCard = ({ children, category }: Props) => {
  const navigate = useNavigate();
  return <Container onClick={e => navigate(`/subjects/${category}`)}>{children}</Container>
}

SubjectCard.ThumbnailContainer = ({children}: Props) => {
  return (
    <ThumbnailContainer >
      {children}
      <PlayIcon>
        {icons.play}
      </PlayIcon>
    </ThumbnailContainer>
  )
}

SubjectCard.Thumbnail = ({ src }: ImgProps) => {
  return <Thumbnail src={src} />
}

SubjectCard.TextContainer = ({children}: Props) => {
  return <TextContainer>{children}</TextContainer>
}

SubjectCard.CategoryBox = ({children}: Props) => {
  return <CategoryBox>{children}</CategoryBox>
}

SubjectCard.TitleText = ({children}: Props) => {
  return <TitleText>{children}</TitleText>
}

SubjectCard.DescriptionText = ({children}: Props) => {
  return <DescriptionText>{children}</DescriptionText>
}

export default SubjectCard