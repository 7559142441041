import styled from "styled-components"
import { RootContent, RootSection } from "../../styles/public"
import theme from "../../styles/theme"

export const TopContent = styled(RootContent)`
    
`

export const Section = styled(RootSection)<{background: string, backgroundPosition: string, backgroundSize: string}>`
    background: ${props => props.background};
    background-position: ${props => props.backgroundPosition};
    background-size: ${props => props.backgroundSize};
    background-repeat: no-repeat;
    background-blend-mode: normal;
    padding-block: 4vw;

    @media screen and (max-width: 576px) {
        padding-block: 2.5rem;
        background-size: cover;
        
    }
`

export const TitleText = styled.h1`
    font-weight: 800;
    font-size: 2.125rem;
    line-height: 2.75rem;
    letter-spacing: -0.02em;
    color: ${theme.colors.mainColor};
`

export const DescriptionText = styled.p`
    font-weight: 500;
    font-size: 1.0625rem;
    line-height: 1.125rem;

    color: ${theme.colors.greyColor1};
`
