import { Cookies } from 'react-cookie';

const cookies = new Cookies();

export const setCookie = (cookieName: string, cookieValue: any, option: { expires?: Date; path?: string; maxAge?: number; }) => {
    return cookies.set(cookieName, cookieValue, { ...option });
}

export const getCookie = (cookieName: string) => {
    return cookies.get(cookieName);
}

export const removeCookie = (cookieName: string) => {
    return cookies.remove(cookieName, { path: '/' });
}
