import React, { ReactNode } from 'react'
import {Container} from './StyledTextArea.style' 
interface Props {
    children: ReactNode
}

const StyledTextArea = ({ children }: Props) => {
  return (
    <Container readOnly>{children}</Container>
  )
}

export default StyledTextArea