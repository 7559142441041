import styled from "styled-components";

export const Btn = styled.div`
    position: relative;
    cursor: pointer;
    @media screen and (min-width: 576px) {
        display: flex;
        justify-content: flex-end;
    }
    @media screen and (max-width: 576px) {
        display: none;
    }

    :hover {
        > div {
            display: block;
            padding-top: 2.5rem;
        }
    }
`