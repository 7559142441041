import React, { ReactNode } from 'react'
import { icons } from '../../static/icons/icon';
import { Container, Input, SearchBtn, SelectBox } from './SearchBar.style';

interface OptionProps {
  options: { value: string, content: string }[];
  setOption: (e: any) => void;
}

interface WordProps {
  setWord: (e: any) => void;
  placeholder: string;
}

interface ButtonProps {
  searchPosts: () => void;
}
interface Props {
  children: ReactNode
}

const SearchBar = ({ children }: Props) => {
  return (
    <Container>
        {children}
    </Container>
  )
}

SearchBar.SelectBox = ({ options, setOption }: OptionProps) => {
  return (
    <SelectBox size={1} onChange={setOption}>
      {options.map((option) => {
        return <option
                  key={option.value} 
                  value={option.value}
              >
                  {option.content}
                </option>
      })}
    </SelectBox>
  )
}

SearchBar.InputText = ({ setWord, placeholder }: WordProps) => {
  return (
   <Input
    onChange={setWord}
    placeholder={placeholder}
   />)
}

SearchBar.SearchBtn = ({ searchPosts }: ButtonProps) => {
  return <SearchBtn onClick={searchPosts}>{icons.search}</SearchBtn>
}

export default SearchBar