import { createSlice } from "@reduxjs/toolkit";

export interface BoardState {
  curBoard: string;
}

const initialState: BoardState = {
  curBoard: "",
};

export const boardSlice = createSlice({
  name: "board",
  initialState,
  reducers: {
    setCurBoard: (state: any, action: { payload: string | null; }) => {
        state.curBoard = action.payload;
    },
  },
});

export const { setCurBoard } = boardSlice.actions;

export default boardSlice.reducer;
