import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import theme from "../../styles/theme";

export const Container = styled.div<{ on: boolean }>`
    @media screen and (max-width: 576px) {
        width:100%;
        position:fixed;
        z-index: 99;
        left: ${props => props.on ? "0%" : "-100%" };                    //좌측에 위치 시키기 위해 현 가로폭의 -100%만큼 왼쪽으로 이동
        top: 0;                      //슬라이드 메뉴의 경우 화면에 꽉차게 해야 하는데
        right:0;                       //모바일 웹은 화면의 높이가 일정하지 않을 일이 많아
        bottom:0;                      //전,후,좌,우 전체 0을 주면 화면에 가득 차게 됩니다.
        
        -webkit-transition: left .3s;  //애니메이션 css 브라우저별로 작동시키기 위해 다 적어주는게 좋다
        -moz-transition: left .3s;     //left 속성에 대해 0.3초 동안 애니메이션 실행
        -ms-transition: left .3s;
        -o-transition: left .3s;
        transition: left .3s;
        background-color:#FFFFFF;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    display: none;
`

export const Tab = styled.div`
    margin-block: 1rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export const NoSubIndex = styled(Link)`
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1.875rem;
    text-decoration: none;
    margin-block: 0;
    color: ${theme.colors.mainBlackColor};
`

export const Index = styled.h3<{ active : boolean }>`
    font-weight: ${props => props.active ? '700' : '400'};
    margin-block: 0;
    font-size: 1.125rem;
    line-height: 1.875rem;
    text-decoration: none;
    color: ${props => props.active ? theme.colors.mainColor : theme.colors.mainBlackColor};
`

export const SubIndexBox = styled.div<{ active : boolean, bodyHeight: number }>`
    position: relative;
    padding: 0;
    margin: 0;
    height: 0;
    overflow: hidden;
    transition: height 0.3s;
    background-color: ${theme.colors.lightYellowColor};
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    ${({ active, bodyHeight }) =>
        active && css`
        height: ${bodyHeight}px;
    `}
`;

export const SubIndex = styled(Link)`
    font-weight: 400;
    font-size: 0.9375rem;
    line-height: 1.875rem;
    text-decoration: none;
    margin-block: 0.25rem;
    color: ${theme.colors.mainBlackColor};
`;

export const Content = styled.div`
    height: auto;
    padding-block: 1rem;
    display: flex;
    flex-direction: column;
`;