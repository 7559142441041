import { Link } from "react-router-dom";
import styled from "styled-components";
import theme from "./theme";

/* 모든 컨테이너에 Base */
export const RootContent = styled.div`
    max-width: 75rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    margin: 0 auto;
`

export const RootContainer = styled.div`
    
`

export const RootSection = styled.section`
    box-sizing: border-box;
    padding-inline: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 100%; */
`

export const FlexRow = styled.div`
    display:flex;
    align-items: center;
`

export const FlexColumn = styled.div`
    width: 100%;
    display:flex;
    flex-direction: column;
`

export const FlexRowJustifyContentSpaceBetween = styled(FlexRow)`
    width: 100%;
    justify-content: space-between;
`

export const FlexRowCenter = styled(FlexRow)`
    width: 100%;
    justify-content: center;
    align-items: center;
`

export const FlexColumnCenter = styled(FlexColumn)`
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
`

export const FlexColumnJustifyContentSpaceBetween = styled(FlexColumn)`
    height: 100%;
    justify-content: space-between;
`

/* 오른쪽 배치 */
export const RightPlacement = styled.div`
    width: 100%;
    display: flex;
    justify-content: end;
`

/* 왼쪽 배치 */
export const LeftPlacement = styled.div`
    display: flex;
    justify-content: start;
`

interface TextProps {
    color?: string;
    size?: number;
}

export const TitleText = styled.p<TextProps>`
    font-weight: 400;
    font-size: 1.875rem;
    line-height: 2.75rem;
    /* or 147% */
    color: ${(props)=> props.color || theme.colors.mainColor};
    letter-spacing: -0.02em;
    margin-top: 0;

    @media screen and (max-width: 576px) {
        font-size: 1.5rem;       
        line-height: 140%;
    }
 `

export const Bold = styled.b<TextProps>`
    font-weight: 700;
    color: ${(props=> props.color || theme.colors.mainBlackColor)}
`

export const BasicText = styled.p`
    font-weight: 400;
    line-height: 165%;
    color: ${theme.colors.greyColor1};
    font-size: 17px;
    @media screen and (max-width: 576px) {
        font-size: 15px;       
    }
`

export const GrayText = styled.p`
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: ${theme.colors.greyColor2};
`

export const Img = styled.img`
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
    width: 100%;
    height: auto;
    object-fit: cover;
`

export const Form = styled.form`
    width: 100%;
`

export const StyledLink = styled(Link)`
    text-decoration: none;
`