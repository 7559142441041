import styled from "styled-components";
import theme from "../../styles/theme";


export const Container = styled.div`
    padding-block: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const Content = styled.div`
    width: 36.25rem;
    display: flex;
    flex-direction: column;
`

const Btn = styled.input`
    cursor: pointer;
    border: 0;
    width: 8.75rem;
    height: 3.125rem;
    border-radius: 0.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 150%;
    letter-spacing: -0.02em;
    color: #FFFFFF;
    margin-top: 2rem;
    margin-inline: 1rem;
`

export const ChangePasswordBtn = styled(Btn)`
    background: ${theme.colors.mainColor};
`

export const FixBtn = styled(Btn)`
    background: ${theme.colors.brownColor2};
`