import React, { useEffect, useState } from 'react'
import Swipe from 'react-easy-swipe';
import { useNavigate } from 'react-router-dom';

import { icons } from '../../static/icons/icon'
import {
    Slider, 
    Slides, 
    State, 
    NumDiv,
    SeparationBar,
    CurrentNum, 
    TotalNum, 
    TotalBar, 
    GageBar, 
    Slide, 
    ImgDiv, 
    Img, 
    TextDiv,
    Text,
    SubText,
    ArrowBtn,
    Button
} from './ImageSlider.style'

const ImageSlider = () => {
    const navigate = useNavigate();

    const slideList = [
        <>
            <ImgDiv>
                <Img src='/img/mainpage_imageslider_first.png' />
            </ImgDiv>
            <TextDiv>
                 <Text>
                    직장을 탈출하기 위해서<br />
                    <b>우리는 학교에서 가르치지 않는걸<br />
                    배워야합니다.</b>
                </Text>
                <Button onClick={() => navigate(`/admission`)}>직장탈출학교란?</Button>
            </TextDiv>
        </>,
        <>
            <ImgDiv>
                <Img src='/img/mainpage_imageslider_second.png' />
            </ImgDiv>
            <TextDiv>
                <Text>
                    직장을 탈출하기 위해서<br />
                    <b>우리는 두가지 능력만 얻어도<br />
                    충분합니다.</b>
                </Text>
                <Button onClick={() => navigate(`/admission`)}>직장탈출학교란?</Button>
            </TextDiv>
        </>,
        <>
            <ImgDiv>
                <Img src='/img/mainpage_imageslider_third.png' />
            </ImgDiv>
            <TextDiv>
                <Text>
                    회사를 위한 것이 아닌<br />
                    <b>자신의 몸값을 높여가는 것<br />
                    그것이 우리의 목적입니다.</b>
                </Text>
                <Button onClick={() => navigate(`/admission`)}>직장탈출학교란?</Button>
            </TextDiv>
        </>,
    ]

    const [curSlide, setCurSlice] = useState(0);
    const [nextSlide, setNextSlice] = useState(1);
    const [prevSlide, setPrevSlice] = useState(2);
    const [positionx, setPositionx] = useState<number>(0);
    const [endSwipe, setEndSwipe] = useState<boolean>(false);

    const onSwipeMove = (position: { x: number }) => {
        setEndSwipe(false);
        if (slideList.length === 1) {
            return;
        }
        if (curSlide === 0 && position.x < 0) {
            setPositionx(() => position.x);
            return;
        }
        if (curSlide > 0 && curSlide < slideList.length) {
            setPositionx(() => position.x);
            return;
        }
        if (curSlide === slideList.length - 1 && position.x > 0) {
            setPositionx(() => position.x);
            return;
        }
    };
    
    const onSwipeEnd = () => {
        if (positionx < -20) {
            setCurSlice(nextSlide);
        }
        if (positionx > 20) {
            setCurSlice(prevSlide);
        }
        setPositionx(0);
        setEndSwipe(true);
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            setCurSlice(nextSlide);
            curSlide === 0 ? setPrevSlice(2) : setPrevSlice(curSlide - 1)
            curSlide === 2 ? setNextSlice(0) : setNextSlice(curSlide + 1)
        }, 5000);
        return () => clearTimeout(timer);
    }, [curSlide, prevSlide, nextSlide]);

    return (
        <Slider>
            <Swipe onSwipeEnd={onSwipeEnd} onSwipeMove={onSwipeMove}>
                <Slides curSlide={curSlide} positionx={positionx} endSwipe={endSwipe}>
                { slideList.map((content, i) => {
                return (
                    <Slide key={`slide${i}`}>
                        {content}
                    </Slide>
                )
                })}
                </Slides>
            </Swipe>
            <State>
                <NumDiv>
                    <CurrentNum>{(curSlide + 1).toString().padStart(2, '0')}</CurrentNum>
                    <SeparationBar>|</SeparationBar>
                    <TotalNum>03</TotalNum>
                </NumDiv>

                <TotalBar>
                    <GageBar gage={(curSlide + 1) / 3}></GageBar>
                </TotalBar>
                
                <ArrowBtn onClick={() => {
                    setCurSlice(prevSlide)
                    curSlide === 0 ? setPrevSlice(2) : setPrevSlice(curSlide - 1)
                }}>
                    {icons.leftArrow}
                </ArrowBtn>
                <ArrowBtn onClick={() => {
                    setCurSlice(nextSlide)
                    curSlide === 2 ? setNextSlice(0) : setNextSlice(curSlide + 1)
                }}>
                    {icons.rightArrow}
                </ArrowBtn>
            </State>
        </Slider>
    )
}

export default ImageSlider