import React, { useState, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Container, Contnet, TitleText } from './ResetPasswordPage.style'
import InputBox from '../../components/InputBox/InputBox.component';
import SubmitBtn from '../../components/SubmitBtn/SubmitBtn.component';
import { useForm } from "react-hook-form"
import { Form } from '../../styles/public';
import { api } from '../../util/api';
import PopupBox from '../../components/PopupBox/PopupBox.component';
import LoadingBox from '../../components/LoadingBox/LoadingBox.component';
import { removeCookie } from '../../util/cookie';
import { useDispatch } from 'react-redux';
import { resetUserInfo } from '../../redux/slice/userSlice';

const ResetPasswordPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [searchParams, setSearchParams] = useSearchParams();

    const token = searchParams.get('token');

    const [isCertifiedUser, setIsCertifiedUser] = useState(false);

    const [popupOpen, setPopupOpen] = useState(false);
    const openPopup = () => {
        setPopupOpen(true);
    };
    const closePopup = () => {
        removeCookie('token');
        dispatch(resetUserInfo())
        navigate('/');
		window.location.reload();
        setPopupOpen(false);
    };

    const [loading, setLoading] = useState(false);
    const openLoading = () => {
        setLoading(true);
    };
    const closeLoading = () => {
        setLoading(false);
    };

    const {
        setValue,
        register,
        formState: { errors },
        handleSubmit,
        setError
    } = useForm();

    useEffect(() => {
        /** 토큰 검증 */
        api.get(`/auth/mail_check?token=${token}`)
        .then((res: any) => {
            if (!res.data.err) {
                setIsCertifiedUser(true);
            } else if(res.data.err === 'expired') {
                alert('토큰이 만료되었습니다!')
                window.close()
            } else {
                alert('시스템 에러입니다.')
                window.close()
            }
        })
        .catch((err: any) => {
            console.log(err);
            window.close()
        });
    }, [])


    useEffect(() => {
        register("newPW", {
            required: "새 비밀번호를 입력 해주세요.",
            pattern: {
                value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                message: "비밀번호 형식에 맞지 않습니다. (영어, 숫자 포함 8자리 이상)"
            }
        });

        register("confirlNewPW", {
            required: "새 비밀번호를 재입력해 해주세요.",
            pattern: {
                value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                message: "비밀번호 형식에 맞지 않습니다. (영어, 숫자 포함 8자리 이상)"
            }
        });
    }, [register]);
                    

    const submitReset = (data: any) => {
        if (data.newPW !== data.confirlNewPW) {
            setError(
                'confirlNewPW',
                { message: '비밀번호가 일치하지 않습니다.' },
                { shouldFocus: true },
            );
            return ;
        }
        
        openLoading();
        api.put('/signjoin/edit/password', {
            "password": data.newPW,
            "token": token
        })
        .then((res: any) => {
            if (res.data.password) {
                closeLoading();
                openPopup();
            } else {
                if (res.data.err === 'same password') {
                    setError(
                        'confirlNewPW',
                        { message: '이전 비밀번호와 같은 비밀번호입니다.' },
                        { shouldFocus: true },
                    );
                } else {
                    alert('시스템 오류 입니다. 다시 시도해주세요.');
                }
            }
        })
        .catch((err: any) => {
            alert('시스템 오류 입니다. 다시 시도해주세요.');
        });
    }

    return (
        <Container>
        { isCertifiedUser && 
            <Contnet>
                <TitleText>비밀번호 재설정</TitleText>
                <Form onSubmit={handleSubmit(submitReset)}>
                    <InputBox>
                        <InputBox.Label htmlFor="newPW">새 비밀번호</InputBox.Label>
                        <InputBox.InputText 
                            placeholder='새 비밀번호를 입력해 주세요.'
                            type='password'
                            handler={async (e: any) => setValue("newPW", e.target.value)}
                        />
                        { errors?.newPW && <InputBox.ErrorText>{`${errors?.newPW?.message}`}</InputBox.ErrorText> }
                    </InputBox>

                    <InputBox>
                        <InputBox.Label htmlFor="confirlNewPW">새 비밀번호 확인</InputBox.Label>
                        <InputBox.InputText 
                            placeholder='새 비밀번호를 재입력해 주세요.'
                            type='password'
                            handler={async (e: any) => setValue("confirlNewPW", e.target.value)}
                        />
                        { errors?.confirlNewPW && <InputBox.ErrorText>{`${errors?.confirlNewPW?.message}`}</InputBox.ErrorText> }
                    </InputBox>

                    <SubmitBtn
                        value='재설정 하기'
                        type='submit'
                    />
                </Form>
            </Contnet>
        }
        {
            popupOpen && (
            <PopupBox>
                <PopupBox.Text>비밀번호가 변경되었습니다. 다시 로그인 해주세요.</PopupBox.Text>
                <PopupBox.Btn closePopup={closePopup}>확인</PopupBox.Btn>
            </PopupBox>
            )
        }
        { loading && ( <LoadingBox /> ) }
        </Container>
    )
}

export default ResetPasswordPage