import styled from "styled-components";
import theme from "../../styles/theme";

export const Container = styled.div`
    margin-block: 2rem;
    width: 100%;
`
export const Label = styled.label`
    font-weight: 500;
    font-size: 1rem;
    line-height: 130%;
    letter-spacing: -0.02em;
    color: ${theme.colors.mainBlackColor};
    margin-block: 1rem;
    > span {
        font-size: 1.125rem;
        color: ${theme.colors.brownColor1}
    }
`

export const InputText = styled.input`
    border: 1px solid ${theme.colors.greyColor3};
    border-radius: 4px;
    box-sizing: border-box;
    width: 100%;
    height: 3.125rem;
    padding-left: 16px;
    color: ${theme.colors.greyColor2};
    font-weight: 500;
    font-size: 1rem;
    line-height: 130%;
    background-color: rgba( 255, 255, 255, 0 );

    :focus {
        outline: none;
    }

    ::placeholder {
        font-weight: 500;
        font-size: 1rem;
        line-height: 130%;

        color: ${theme.colors.greyColor3};
    }

    :disabled {
        background-color: #f6f0e8;
        color: ${theme.colors.greyColor3};
    }

    @media screen and (max-width: 576px) {
        height: 2.8125rem;
        border-radius: 0.375rem;
        font-weight: 400;
        font-size: 0.9375rem;
        line-height: 130%;
        letter-spacing: -0.02em;
    }
`

export const SubText = styled.p`
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.125rem;

    letter-spacing: -0.02em;
    color: ${theme.colors.oliveColor1};
    margin-bottom: 8px;
    @media screen and (max-width: 576px) {
        margin-block: 0 12px;
    }
`

export const ErrorText = styled.p`
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.125rem;

    letter-spacing: -0.02em;
    color: red;
    margin-block: 0.5rem;
    @media screen and (max-width: 576px) {
        margin-block: 0 0.75rem;
    }
`

export const TextDiv = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;

    @media screen and (max-width: 576px) {
        flex-direction: column;
        align-items: flex-start;
    }
`