import styled from "styled-components";
import theme from "../../styles/theme";

export const Container = styled.div`
    width: 100%;
    margin-bottom: 1rem;
`

export const Title = styled.p`
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 1.625rem;
    margin-block: 1rem;
    color: ${theme.colors.mainColor};
`

export const SubTitle = styled.p`
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.625rem;
    margin-block: 1.5rem;
    color: ${theme.colors.mainColor};
`

export const QnAContainer = styled.div`
    position: relative;
    border: 1px solid ${theme.colors.greyColor3};
    padding: 1rem;
`

export const QnABox = styled.div`
    padding: 1rem;
    
`

export const Question = styled.p`
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.125rem;
    color: ${theme.colors.mainBlackColor};
`

export const Answer = styled.p`
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.125rem;
    color: ${theme.colors.greyColor1};
`

export const Input = styled.input`
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #ccced1;
    border-radius: 0;
    padding: 1rem 2rem;

    :focus {
        border: 1px solid hsl(218,81.8%,56.9%);
        box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.1) inset;
        outline: none;
    }
`

export const Button = styled.button`
    border: 0;
    background-color: ${theme.colors.mainColor};
    border-radius: 4px;
    padding: 0.5rem 0.5rem;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.125rem;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    margin-block: 1rem;
`