import styled from "styled-components";
import theme from "../../styles/theme";

export const Container = styled.div`
    display: flex;
    align-items: center;
`

export const ProfileImgBox = styled.div`
    width: 9rem;
    aspect-ratio: 1;
    border-radius: 70%;
    overflow: hidden;
    margin-right: 1rem;
`

export const ProfileImg = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`

export const NicknameText = styled.h1`
    font-weight: 700;
    font-size: 1.875rem;
    line-height: 2.25rem;
    text-transform: capitalize;
    color: ${theme.colors.mainBlackColor};
    margin-block: 0.25rem;
    > span {
        font-weight: 500;
    }

    @media screen and (max-width: 576px) {
        font-size: 1.25rem;
    }
`

export const EmailText = styled.p`
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.1875rem;
    color: ${theme.colors.greyColor2};
    margin-block: 0.25rem;
    @media screen and (max-width: 576px) {
        font-size: 0.875rem;
        line-height: 1.0625rem;
    }
`