import styled from "styled-components";
import theme from "../../styles/theme";

export const Container = styled.div`
    box-sizing:border-box;
    background-color: #FFFFFF;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 2rem;
    justify-content: space-around;

    @media screen and (max-width: 576px) {
        padding: 1rem;
    }
`

export const TopContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    @media screen and (max-width: 576px) {
        margin-bottom: 1rem;
    }
`

export const SymbolBox = styled.div<{ color: string }>`
    background-color: ${props => props.color};
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;

    @media screen and (max-width: 576px) {
        width: 22px;
        height: 22px;
    }
`

export const BottomContainer = styled.div`
`

export const TitleText= styled.h1<{ color: string }>`
    color: ${props => props.color};
    font-weight: 700;
    font-size: 28px;
    line-height: 41px;
    margin: 0;
    @media screen and (max-width: 576px) {
        font-size: 18px;
        line-height: 26px;
    }
`

export const SubTitleText = styled.h2`
    font-weight: 700;
    font-size: 20px;
    line-height: 29px;
    color: ${theme.colors.brownColor1};
    @media screen and (max-width: 576px) {
        font-size: 15px;
        line-height: 22px;
        margin-block: 0.25rem;
    }
`

export const DescriptionText = styled.p`
    font-weight: 400;
    font-size: 17px;
    line-height: 30px;
    margin: 0;
    display: flex;
    align-items: center;
    white-space: pre-wrap;

    /* gray01 */

    color: ${theme.colors.greyColor1};

    @media screen and (max-width: 576px) {
        font-size: 14px;
        line-height: 160%;
        white-space: normal;
    }
`