import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import SubmitBtn from '../../components/SubmitBtn/SubmitBtn.component'
import { useAppSelector } from '../../hooks/hooks'
import { subjects } from '../../static/subjects/subjects'
import { FlexRowJustifyContentSpaceBetween } from '../../styles/public'
import { api } from '../../util/api'
import { Container, Section, Content, Category, TitleText, Box, TicketName, BoldText, SubText, TimeInfoText, GreyLine, TotalPrice, MonthlyPrice, Installment, BrownLine } from './PayPage.style'
const PayPage = () => {
    const navigate = useNavigate()
    
    const { category } = useParams();
    const subjectsPrice = useAppSelector((state: { subjectsReducer: { subjects: any; }; }) => state.subjectsReducer.subjects)[category];

    const subject = subjects.filter((s) => {
        return s.category === category;
    })[0]

    const myCourses = useAppSelector((state: { userReducer: { courses: any; }; }) => state.userReducer.courses);

    /** 결제 상태 가져오기 */
    const getPaidInfo = () => {
        api.get(`/user/lecture/check`)
        .then((res: any) => {
            if (res.data.availableCourse.includes(myCourses)) {
                alert('이미 구매한 강의 입니다.')
                navigate(`/subjects/${category}`)
            }
        })
        .catch((err: any) => {
            console.log(err);
        })
    }

    useEffect(() => {
        getPaidInfo();
    }, [])

    return (
        <Container>
            <Section>
                <Content>
                    <TitleText>결제하기</TitleText>
                    <Box>
                        <Category>{subject.kr_category}</Category>
                        <TicketName>{subject.title}</TicketName>
                        <SubText>{subject.description}</SubText>
                        <TimeInfoText>개강날짜 ㅣ <span>즉시시작</span></TimeInfoText>
                        <TimeInfoText>수강기간 ㅣ <span>{subject.duration}</span></TimeInfoText>
                        <GreyLine />
                        <TicketName>1년 이용권</TicketName>
                        <BoldText>1년 동안 강의를 반복해서 볼 수 있고 커뮤니티 이용이 가능합니다.</BoldText>
                        <SubText>*100% 환급 보장 장학금이 있습니다. (메신저 강의만 해당)</SubText>
                        <SubText>*강의 내용과 후기가 늘어남에 따라 가격은 인상됩니다.</SubText>
                        <GreyLine />
                        <TotalPrice>{subjectsPrice.sale_price.toLocaleString()}원</TotalPrice>
                        <FlexRowJustifyContentSpaceBetween>
                            <MonthlyPrice>월 <span>{(subjectsPrice.sale_price/12).toLocaleString()}</span>원</MonthlyPrice>
                            <Installment>12개월 할부 시</Installment>
                        </FlexRowJustifyContentSpaceBetween>
                        <SubmitBtn 
                            value='1년 이용권 선택하기'
                            type='submit' 
                            handler={() => {navigate(`/inputpayinfo/${category}`)}}
                        />
                        <BrownLine />
                        <BoldText>환불정책</BoldText>
                        <SubText>-수강 신청시간 기준: 7일 미만, 3강 미만 수강 시에는 100% 환불 가능</SubText>
                        <SubText>-수강 신청시간 기준: 7일 이상, 3강 이상 수강 시에는 환불 불가능 </SubText>
                    </Box>
                </Content>
            </Section>
        </Container>
  )
}

export default PayPage;