import { createSlice } from "@reduxjs/toolkit";

export interface UserState {
  isLoggedIn: boolean;
  login_id: string;
  nickname: string;
  permission: number;
  social: string;
  courses: Array<string>;
}

const initialState: UserState = {
  isLoggedIn: false,
  login_id: "",
  nickname: "",
  permission: -1,
  social: "",
  courses: []
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    handleLogin: (state: { isLoggedIn: any; }, action: { payload: any; }) => {
      state.isLoggedIn = action.payload;
    },
    setUserNickname: (state: { nickname: any; }, action: { payload: any; }) => {
      state.nickname = action.payload;
    },
    setUserID: (state: { login_id: any; }, action: { payload: any; }) => {
      state.login_id = action.payload;
    },
    setUserPermission: (state: { permission: any; }, action: { payload: any; }) => {
      state.permission = action.payload;
    },
    setUserSocial: (state: { social: any; }, action: { payload: any; }) => {
      state.social = action.payload;
    },
    setUserCourses: (state: { courses: any; }, action: { payload: any; }) => {
      state.courses = action.payload;
    },
    resetUserInfo: (state: UserState) => {
      state.login_id = "";
      state.nickname = "";
      state.permission = -1;
      state.social = "";
      state.courses = [];
    }
  },
});

export const { handleLogin, setUserNickname, setUserID, setUserPermission, setUserSocial, setUserCourses, resetUserInfo } = userSlice.actions;

export default userSlice.reducer;
