import React, { useEffect } from 'react';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import MainPage from './pages/MainPage/MainPage';
import AdmissionPage from './pages/AdmissionPage/AdmissionPage';
import AdmissionGuidePage from './pages/AdmissionGuidePage/AdmissionGuidePage';
import LoginPage from './pages/LoginPage/LoginPage';
import Footer from './container/Footer/Footer.component'
import TopNavi from './container/TopNavi/TopNavi.component'
import ScrollToTop from './util/ScrollTop';
import SignUpPage from './pages/SignUpPage/SignUpPage';
import DiagnosisPage from './pages/DiagnosisPage/DiagnosisPage'
import TermOfServicePage from './pages/TermOfServicePage/TermOfServicePage';
import SubjectsPage from './pages/SubjectsPage/SubjectsPage';
import SubjectPage from './pages/SubjectPage/SubjectPage';
import NoticePage from './pages/NoticePage/NoticePage';
import SubmitMissionPage from './pages/MissionPage/MissionPage';
import PostingPage from './pages/PostingPage/PostingPage';
import PostPage from './pages/PostPage/PostPage';
import ColumnsPage from './pages/ColumnsPage/ColumnsPage';
import ColumnPage from './pages/ColumnPage/ColumnPage';
import ScholarshipPage from './pages/ScholarshipPage/ScholarshipPage';
import { useAppSelector, useAppDispatch } from "./hooks/hooks";
import { handleLogin, setUserCourses, setUserSocial } from "./redux/slice/userSlice"
import MyPage from './pages/MyPage/MyPage';
import ResetPasswordPage from './pages/ResetPasswordPage/ResetPasswordPage';
import PayPage from './pages/PayPage/PayPage';
import InputPayInfoPage from './pages/InputPayInfoPage/InputPayInfoPage';
import RefundPolicyPage from './pages/RefundPolicyPage/RefundPolicyPage.component';
import PersonalInfoPolicyPage from './pages/PersonalInfoPolicyPage/PersonalInfoPolicyPage';
import { getCookie } from './util/cookie';
import { api } from './util/api'
import {setUserID, setUserNickname, setUserPermission} from "./redux/slice/userSlice";
import PaySuccessPage from './pages/PaySuccessPage/PaySuccessPage';
import PrivateRoute from './util/PrivateRoute';
import SocialLoginPage from './pages/SocialLoginPage/SocialLoginPage';
import LectureCallbackPage from './pages/LectureCallbackPage/LectureCallbackPage';
import PersonalInfoCollectPage from './pages/PersonalInfoCollectPage/PersonalInfoCollectPage';
import EditSubjectPage from './pages/EditSubjectPage/EditSubjectPage';
import FAQPage from './pages/FAQPage/FAQPage';
import EditFAQPage from './pages/EditFAQPage/EditFAQPage';
import FindPasswordPage from './pages/FindPasswordPage/FindPasswordPage';
import { setSubjectsPrice } from './redux/slice/subjectSlice';
import FindIDPage from './pages/FindIDPage/FindIDPage';
import AdminPage from './pages/AdminPage/AdminPage';

function App() {
    const dispatch = useAppDispatch();

    const permission = useAppSelector((state: { userReducer: { permission: any; }; }) => state.userReducer.permission);
    const isLoggedIn = useAppSelector((state: { userReducer: { isLoggedIn: any; }; }) => state.userReducer.isLoggedIn);

    const getUserInfo = () => {
        api.get('/user')
        .then((res: any) => {
            dispatch(setUserID(res.data.info.login_id))
            dispatch(setUserNickname(res.data.info.nickname))
            dispatch(setUserPermission(res.data.info.permission))
            dispatch(setUserSocial(res.data.info.social));
            dispatch(setUserCourses(res.data.info.availableCourse));
        })
        .catch((err: any) => {
            console.log(err);
        });
    }

    const tokenCheck = () => {
		const token = getCookie('token'); // 쿠키에서 token 있는지 확인
        if (!token) {
            dispatch(handleLogin(false));
        } else {
            getUserInfo();
            dispatch(handleLogin(true));
        }
    };

    const getSubjectPrice = () => {
        api.get('/payment/list')
        .then((res: any) => {
            dispatch(setSubjectsPrice({
                messenger: {
                    total_price: res.data.info[0].prePrice,
                    sale_price: res.data.info[0].price,
                },
                writing: {
                    total_price: res.data.info[1].prePrice,
                    sale_price: res.data.info[1].price,
                }
            }))
        })
        .catch((err: any) => {
            console.log(err);
        });
    }

	useEffect(() => {
		tokenCheck(); // 로그인 체크 함수
        getSubjectPrice();
	}, []);
    
    return (
        <BrowserRouter>
            <TopNavi />
            <ScrollToTop />
            <Routes>
                <Route path="/" element={<MainPage />} />
                <Route path="/*" element={<MainPage />} />

                <Route path="/admission" element={<AdmissionPage />} />
                <Route path="/admission/guide" element={<AdmissionGuidePage />} />

                <Route path="/subjects" element={<SubjectsPage />} />
                <Route path="/subjects/:category" element={<SubjectPage />} />

                <Route path="/subjects" element={<SubjectsPage />} />



                <Route path="/notice/:pagenumber" element={<NoticePage />} />
                <Route path="/notice/post/:postid" element={<PostPage />} />

                <Route path="/diagnosis/:pagenumber" element={<DiagnosisPage />} />
                <Route path="/diagnosis/post/:postid" element={<PostPage />} />

                <Route path="/column" element={<ColumnsPage />} />
                <Route path="/column/post/:postid" element={<ColumnPage />} />

                <Route path="/scholarship/:pagenumber" element={<ScholarshipPage />} />
                <Route path="/scholarship/post/:postid" element={<PostPage />} />
                <Route path="/refundpolicy" element={<RefundPolicyPage />} />
                <Route path="/termofservice" element={<TermOfServicePage />} />
                <Route path="/personalinfopolicy" element={<PersonalInfoPolicyPage />} />
                <Route path="/personalinfocollect" element={<PersonalInfoCollectPage />} />
                <Route path="/lecturecallback" element={<LectureCallbackPage />} />

                <Route path="/FAQ" element={<FAQPage />} />

                <Route path="/login" element={
                    <PrivateRoute 
                        condition={!isLoggedIn}
                        message='이미 로그인 상태입니다.'
                        fallback='/'
                        page={<LoginPage />}
                />} />

                <Route path="/social/:company" element={
                    <PrivateRoute 
                        condition={!isLoggedIn}
                        message='이미 로그인 상태입니다.'
                        fallback='/'
                        page={<SocialLoginPage />}
                />} />

                <Route path="/signup" element={
                    <PrivateRoute 
                        condition={!isLoggedIn}
                        message='이미 로그인 상태입니다.'
                        fallback='/'
                        page={<SignUpPage />}
                />} />

                <Route path="/mypage" element={
                    <PrivateRoute 
                        condition={isLoggedIn}
                        message='로그인을 해주세요.'
                        fallback='/login'
                        page={<MyPage />}
                />} />

                <Route path="/mypage" element={
                    <PrivateRoute 
                        condition={isLoggedIn}
                        message='로그인을 해주세요.'
                        fallback='/login'
                        page={<MyPage />}
                />} />

                <Route path="/pay/:category" element={
                    isLoggedIn ? // 로그인 되었을 때 
                    <PrivateRoute 
                        condition={permission >= 0}
                        message='이미 결제한 회원입니다.'
                        fallback='/'
                        page={<PayPage />}
                    /> : <PrivateRoute // 로그인 안되어 있을 때
                    condition={isLoggedIn}
                    message='로그인을 해주세요.'
                    fallback='/login'
                    page={<PayPage />}
                    /> 
                } />

                <Route path="/inputpayinfo/:category" element={
                    <PrivateRoute 
                        condition={permission >= 0}
                        message='접근 권한이 없습니다.'
                        fallback='/'
                        page={<InputPayInfoPage />}
                />} />

                <Route path="/paysuccess/:category" element={
                    <PrivateRoute 
                        condition={permission >= 0}
                        message='접근 권한이 없습니다.'
                        fallback='/'
                        page={<PaySuccessPage />}
                />} />

                <Route path="/mission/:boardtype/:pagenumber" element={
                    <PrivateRoute 
                        condition={permission >= 1}
                        message='강의 결제 후 이용 가능합니다.'
                        fallback='/pay'
                        page={<SubmitMissionPage />}
                />} />

                <Route path="/mission/:boardtype/post/:postid" element={
                    <PrivateRoute 
                        condition={permission >= 1}
                        message='강의 결제 후 이용 가능합니다.'
                        fallback='/pay'
                        page={<PostPage />}
                />} />

                <Route path="/mission/:boardtype/posting" element={ // 관리자 일 때만
                    <PrivateRoute 
                        condition={permission >= 1}
                        message='접근 권한이 없습니다.'
                        fallback='/'
                        page={<PostingPage />}
                />} />

                <Route path="/notice/posting" element={ // 관리자 일 때만
                    <PrivateRoute 
                        condition={permission === 4}
                        message='접근 권한이 없습니다.'
                        fallback='/'
                        page={<PostingPage />}
                />} />

                <Route path="/diagnosis/posting" element={ // 관리자 일 때만
                    <PrivateRoute 
                        condition={permission === 4}
                        message='접근 권한이 없습니다.'
                        fallback='/'
                        page={<PostingPage />}
                />} />

                <Route path="/column/posting" element={ // 관리자 일 때만
                    <PrivateRoute 
                        condition={permission === 4}
                        message='접근 권한이 없습니다.'
                        fallback='/'
                        page={<PostingPage />}
                />} />

                <Route path="/subject/:category/posting" element={ // 관리자 일 때만
                    <PrivateRoute 
                        condition={permission === 4}
                        message='접근 권한이 없습니다.'
                        fallback='/'
                        page={<EditSubjectPage />}
                />} />

                <Route path="/scholarship/posting" element={ // 관리자 일 때만
                    <PrivateRoute 
                        condition={permission === 4}
                        message='접근 권한이 없습니다.'
                        fallback='/'
                        page={<PostingPage />}
                />} />

                <Route path="/FAQ/edit" element={ // 관리자 일 때만
                    <PrivateRoute 
                        condition={permission === 4}
                        message='접근 권한이 없습니다.'
                        fallback='/FAQ'
                        page={<EditFAQPage />}
                />} />

                <Route path="/adminpage" element={ // 관리자 일 때만
                    <PrivateRoute 
                        condition={permission === 4}
                        message='접근 권한이 없습니다.'
                        fallback='/'
                        page={<AdminPage />}
                />} />

                <Route path="/resetpassword" element={<ResetPasswordPage />} />
                <Route path="/findpassword" element={<FindPasswordPage />} />
                <Route path="/findid" element={<FindIDPage />} />
            </Routes>
            <Footer />
        </BrowserRouter>
    
  );
}

export default App;
