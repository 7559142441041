import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import PaymentCard from '../../components/PaymentCard/PaymentCard.component'
import { FlexRow, FlexRowJustifyContentSpaceBetween } from '../../styles/public'
import { api } from '../../util/api'
import { conversionDate } from '../../util/conversionTimestamp'
import { Container } from './PaymentHistoryContainer.style'
import PopupBox from '../../components/PopupBox/PopupBox.component'
import Parser from 'html-react-parser';

const PaymentHistoryContainer = () => {
  const navigate = useNavigate();

  const [userPayInfo, setUserPayInfo] = useState([]);
    // 팝업 여닫기
  const [popupOpen, setPopupOpen] = useState(false);
  const [popupMessage, setPopupMessage] = useState("")

  const openPopup = () => {
      setPopupOpen(true);
  };
  const closePopup = () => {
      setPopupOpen(false);
      navigate('/mypage', {state:{ tabNumber: 1 }}); // 성공하고 닫을 때는 
  };

  /** 상세 결제 정보 가져오기 */
  const getDetailPayInfo = (imp_uid: string) => {
    api.get(`/user/payment/info/${imp_uid}`, {
    })
    .then((res: any) => {
      console.log(res.data)
    })
    .catch((err: any) => {
      alert('결제 정보를 가져올 수 없습니다.')
      console.log(err);
    });
}
  /** 모든 결제 정보 가져오기 */
  const getAllPayInfos = () => {
    api.get('/user/payment')
    .then((res: any) => {
      setUserPayInfo(res.data.payment)
    })
    .catch((err: any) => {
      alert('결제 정보를 가져올 수 없습니다.')
      console.log(err);
    });
  }

  useEffect(() => {
    getAllPayInfos();
  }, [])

  return (
    <Container>
      {
        userPayInfo.length > 0 ? 
        userPayInfo.map((info) => {
          let status = null
          if (info.status === "ready") status = "결제 대기"
          else if (info.status === "paid") status = "결제 성공"
          else if (info.status === "cancelled") status = "결제 취소"
          const date = conversionDate(info.date)

          return(
            <PaymentCard>
              <FlexRowJustifyContentSpaceBetween>
                <FlexRow>
                  <PaymentCard.Category>{status}</PaymentCard.Category>
                  <PaymentCard.Date>{date}</PaymentCard.Date>
                </FlexRow>
                {/* <PaymentCard.DetailBtn handler={() => getDetailPayInfo(info.imp_uid)} /> */}
              </FlexRowJustifyContentSpaceBetween>
              <hr />
              <PaymentCard.TicketName>1년 이용권</PaymentCard.TicketName>
              <PaymentCard.BoldText>1년 동안 강의를 반복해서 볼 수 있고 커뮤니티 이용이 가능합니다.</PaymentCard.BoldText>
              <PaymentCard.SubText>*100% 환급 보장 장학금이 있습니다. (메신저 강의만 해당)</PaymentCard.SubText>
              <PaymentCard.SubText>*강의 내용과 후기가 늘어남에 따라 가격은 인상됩니다.</PaymentCard.SubText>
            </PaymentCard>
          )
        }) : (
          <PaymentCard.BoldText>결제 내역이 없습니다.</PaymentCard.BoldText>
        )
      }
      {
            popupOpen && (
                <PopupBox>
                    <PopupBox.Text>{Parser(popupMessage)}</PopupBox.Text>
                    <PopupBox.Btn closePopup={closePopup}>확인</PopupBox.Btn>
                </PopupBox>
            )
        }
    </Container>
  )
}

export default PaymentHistoryContainer