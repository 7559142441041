import React from 'react'
import CurriAccordionTab from '../../components/CurriAccordionTab/CurriAccordionTab.component';
import { Container, TitleText } from './CurriculumContainer.style'

interface VideoProps {
  length: string;
  mckey: string;
  title: string;
}
interface Props {
  lectureList: Array<{ subcatKey: string, title: string, totalLength: string, video: Array<VideoProps> }>;
  getKollusJWT: (mcKey: string) => void
}
const CurriculumContainer = ({ lectureList, getKollusJWT }: Props) => {
  return (
    <Container>
      <TitleText>
        커리 큘럼
      </TitleText>
      { lectureList.map(({title, totalLength, video}) => {
        return <CurriAccordionTab title={title} totalLength={totalLength} videos={video} getKollusJWT={getKollusJWT} />
      }) }
    </Container>
  )
}

export default CurriculumContainer