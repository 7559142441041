import styled from "styled-components";
import { RootContainer, RootContent, RootSection } from "../../styles/public";
import theme from "../../styles/theme";

export const Container = styled(RootContainer)`
    background-image: url('/img/admissionguidepage_background.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
    padding-block: 3rem;
`

export const Section = styled(RootSection)`
    padding: 0;
`

export const Content = styled(RootContent)`
    flex-direction: column;
    width: 32rem;
`

export const TitleText = styled.h1`
    font-weight: 700;
    font-size: 1.875rem;
    line-height: 2.75rem;
    letter-spacing: -0.02em;
    color: ${theme.colors.mainBlackColor};
`

export const Box = styled.div`
    background: #FFFFFF;
    border-radius: 0.25rem;
    padding: 2rem;
    width: 100%;
    box-sizing: border-box;

    @media screen and (max-width: 576px) {
        border-radius: 0;
    }
`

export const TicketName = styled.h2`
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 2.75rem;
    letter-spacing: -0.02em;
    color: ${theme.colors.mainColor};
    margin: 0;
`

export const Category = styled.div`
    width: 3.25rem;
    height: 1.75rem;
    background: ${theme.colors.mainColor};
    border-radius: 0.125rem;

    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.75rem;
    text-align: center;
    letter-spacing: -0.02em;
    color: #FFFFFF;
    margin-block: 0.5rem;
`

export const BoldText = styled.p`
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.25rem;
    color: ${theme.colors.mainBlackColor};
    @media screen and (max-width: 576px) {
        font-size: 0.875rem;
    }
`

export const SubText = styled(BoldText)`
    font-weight: 400;
    margin-block: 0.5rem;
    @media screen and (max-width: 576px) {
        font-size: 0.75rem;
    }
`

export const TimeInfoText = styled.p`
    font-weight: 700;
    font-size: 0.9375rem;
    line-height: 1.125rem;
    color: ${theme.colors.oliveGreenColor};
    margin-block: 1rem;
    > span {
        font-weight: 400;
        color: ${theme.colors.mainBlackColor}
    }
`

export const GreyLine = styled.hr`
    border: 1px solid ${theme.colors.greyColor3};
    margin-block: 2rem;
`

export const BrownLine = styled.hr`
    border: 1px solid ${theme.colors.brownColor1};
    opacity: 0.5;
    margin-block: 2rem;
`

export const TotalPrice = styled.p`
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: ${theme.colors.greyColor2};
`

export const MonthlyPrice = styled.p`
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.125rem;
    color: ${theme.colors.mainColor};
    margin-block: 0.25rem 2rem;
    > span {
        font-size: 1.5rem;
    }
`

export const Installment = styled.p`
    font-weight: 500;
    font-size: 0.9375rem;
    line-height: 1.125rem;
    color: ${theme.colors.greyColor2};
    margin-block: 0.25rem 2rem;
`