import React from 'react'
import { Container, Text, Input} from './InputTitle.style'

interface Props {
    title: string
    value: string
    setValue: (e: any) => void;
}

const InputTitle = ({ title, value, setValue }: Props) => {
    return (
    <Container>
        <Text>{title}</Text>
        <Input 
            placeholder={`${title}을 입력하세요.`}
            value={value}
            onChange={(e) => setValue(e.currentTarget.value)}
        />
    </Container>
  )
}

export default InputTitle