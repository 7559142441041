import styled from "styled-components";
import theme from "../../styles/theme";

export const Slider = styled.div`
    width: 100%;
    height: 100%;
    text-align: center;
    overflow: hidden;
    position: relative;
`

export const Slides = styled.div<{curSlide: number, positionx: number, endSwipe: boolean}>`
    display: flex;
    align-items: center;
    transform: translateX(${({ curSlide }) => `calc(${-100 * (curSlide)}%)`});
    transition: ease 1s;
    @media screen and (max-width: 576px) {
        transform: translateX(${({ curSlide, positionx }) => `calc(${positionx}px + ${-100 * (curSlide)}%)`});
    }
`

export const Slide = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4rem;
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    scroll-snap-align: center;
    box-sizing: border-box;
    background: transparent;

    @media screen and (max-width: 576px) {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        grid-gap: 0rem;
    }
`

export const State = styled.div`
    position: absolute;
    width: 40%;
    top:80%;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 2rem;
    @media screen and (max-width: 576px) {
        width: 100%;
        top:50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding-left: 0rem;
    }
`
export const NumDiv = styled.div`
    display: flex;
    align-items: center;
    @media screen and (max-width: 576px) {
        display: none;
    }
`

export const CurrentNum = styled.p`
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    color: ${theme.colors.oliveGreenColor};
`
export const TotalNum = styled(CurrentNum)`
    font-weight: 500;
    color: ${theme.colors.greyColor3};
`

export const SeparationBar = styled(CurrentNum)`
    margin-inline: 0.5rem;
    
`

export const TotalBar = styled.div`
    width: 300px;
    height: 2px;
    background-color: ${theme.colors.greyColor3};
    @media screen and (max-width: 576px) {
        width: 100%;
    }
`
export const GageBar = styled.div<{gage: number}>`
    width: ${props => props.gage * 100}%;
    height: 100%;
    background-color: ${theme.colors.oliveGreenColor};
    transition: ease 1s;
`

export const ImgDiv = styled.div`
    width: auto;
    height: 100%;
    overflow: auto;
    order: 1;
    @media screen and (max-width: 576px) {
        order: 2;
    }
`

export const Img = styled.img`
    width: 100%;
    height: auto;
`

export const TextDiv = styled.div`
    text-align: left;
    display:flex;
    flex-direction: column;
    justify-content: center;
    order: 2;
    @media screen and (max-width: 576px) {
        order: 1;
    }
`

export const Text = styled.p`
    font-weight: 400;
    font-size: 2.1875rem;
    line-height: 3rem;
    
    letter-spacing: -0.03em;
    color: ${theme.colors.greyColor1};
    > b {
        font-weight: 800;
        color: ${theme.colors.mainColor};
    }

    @media screen and (max-width: 576px) {
        font-weight: 400;
        font-size: 1.5rem;
        line-height: 140%;
    }
`

export const SubText = styled.p`
    font-weight: 500;
    font-size: 0.9375rem;
    line-height: 170%;
    letter-spacing: -0.02em;
    margin-block: 0rem 5rem;
    color: ${theme.colors.oliveGreenColor};
    @media screen and (max-width: 576px) {
        margin-block: 0rem;
    }
`

export const ArrowBtn = styled.button`
    border: 0;
    cursor: pointer;
    background-color: transparent;
    @media screen and (max-width: 576px) {
        display: none;
    }
`

export const Button = styled.button`
    cursor: pointer;
    max-width: 8rem;
    font-size: 1rem;
    padding: 0.5rem;
    margin-block: 0rem 5rem;
    border: 0;
    border-radius: 0.25rem;
    background-color: ${theme.colors.oliveGreenColor};
    color: white;
`