import styled from "styled-components";
import theme from "../../styles/theme";

export const Container = styled.div`
    cursor: pointer;
    width: 100%;
    box-sizing: border-box;
    order: 2;

    @media screen and (max-width: 576px) {
        width: 100%;
        order: 1;
        :nth-child(3) {
            display: none;
        }
    }
`

export const TextBox = styled.image`
        display: flex;
        align-items: center;
`

export const Title = styled.div`
    width: 3.125rem;
    height: 1.75rem;
    background: ${theme.colors.mainColor};
    border-radius: 0.125rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.75rem;
    letter-spacing: -0.02em;
    color: #FFFFFF;
`

export const Text = styled.p`
    font-weight: 400;
    line-height: 1.625rem;
    color: #4D4444;
    margin-inline: 0.5rem;
`