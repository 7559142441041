import { createSlice } from "@reduxjs/toolkit";

interface PriceInfo {
    total_price: number;
    sale_price: number;
}

export interface SubjectsState {
  subjects: { messenger: PriceInfo, writing: PriceInfo }
}

const initialState: SubjectsState = {
    subjects: { messenger: { total_price: 0, sale_price: 0 } , writing: { total_price: 0, sale_price: 0 } }
};

export const subjectsSlice = createSlice({
  name: "subjects",
  initialState,
  reducers: {
    setSubjectsPrice: (state: any, action: { payload: { messenger: { total_price: number, sale_price: number }, writing: { total_price: number, sale_price: number } } }) => {
        state.subjects = action.payload;
    }
  },
});

export const { setSubjectsPrice } = subjectsSlice.actions;

export default subjectsSlice.reducer;
