import React from 'react'
import StyledTextArea from '../../components/StyledTextArea/StyledTextArea.component'
import { terms } from '../../static/terms/terms'
import {Container, Section, Content, TitleText} from './TermOfServicePage.style'
const TermOfServicePage = () => {
  return (
    <Container>
      <Section>
        <Content>
          <TitleText>{terms.TermsAndConditions.title}</TitleText>
          <StyledTextArea>
            {terms.TermsAndConditions.text}
          </StyledTextArea>
        </Content>
      </Section>
    </Container>
  )
}

export default TermOfServicePage