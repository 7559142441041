import styled from "styled-components";

export const Btn = styled.input`
    cursor: pointer;
    width: 100%;
    height: 3.125rem;
    margin-block: 32px;
    background: #6B3F36;
    border-radius: 0.25rem;
    border: 0;
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 150%;
    /* or 1.6875rem */
    margin-block: 0.5rem;
    letter-spacing: -0.02em;

    /* wht */

    color: #FFFFFF;

    @media screen and (max-width: 576px) {
        height: 2.8125rem;
        border-radius: 0.375rem;
    }
`