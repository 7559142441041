import React, { useEffect } from 'react'
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import { api } from '../../util/api';
import MoonLoader from "react-spinners/MoonLoader";
import theme from '../../styles/theme';
import { Container, Text } from './SocialLoginPage.style'
import { useAppDispatch } from "../../hooks/hooks";
import { handleLogin, setUserCourses, setUserID, setUserNickname, setUserPermission, setUserSocial } from "../../redux/slice/userSlice";
import { setCookie } from '../../util/cookie'; 

const SocialLoginPage = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const company = location.pathname.split('/')[2]

    // 쿼리 파라미터 파싱
    const [searchParams] = useSearchParams();
    const code = searchParams.get('code');
    const state = searchParams.get('state');
    const error = searchParams.get('error');
    const error_description = searchParams.get('error_description');

    /** 정보 가져오기 */
    const getUserInfo = () => {
        api.get('/user')
        .then((res: any) => {
            dispatch(handleLogin(true));
            dispatch(setUserID(res.data.info.login_id));
            dispatch(setUserNickname(res.data.info.nickname));
            dispatch(setUserPermission(res.data.info.permission));
            dispatch(setUserSocial(res.data.info.social));
            dispatch(setUserCourses(res.data.info.availableCourse));
        })
        .catch((err: any) => {
            console.log(err);
        });
    }       

    /** redirect_url로 받은 state와 code 백엔드로 전송 */
    const sociallogin = () => {
        api.get(`/${company}/callback_login?code=${code}&state=${state}`)
        .then((res: any) => {
            if (res.data.access_token) {
                setCookie('token', res.data.access_token, {
                    path: '/',
                    maxAge: 60 * 60 * 24 * 30, // 30일
                });
                getUserInfo();
                navigate('/')
            } else {
                alert(res.data.err);
                navigate('/login')
            }
        })
        .catch((err: any) => {
            alert('시스템 오류 입니다.')
            navigate('/login')
        });
    }

    if (error) {
        alert(error_description);
        navigate('/login');
    } else {
        sociallogin();
    }

  return (
    <Container>
        
        <MoonLoader
            color={theme.colors.mainColor}
            size={50}
        />
        <Text>로그인 중 입니다..</Text>

    </Container>
  )
}

export default SocialLoginPage