import React from 'react'
import { To, useNavigate } from 'react-router-dom'
import { FlexColumn, FlexRow, FlexRowJustifyContentSpaceBetween, RootSection } from '../../styles/public'
import { conversionDate } from '../../util/conversionTimestamp'
import {Container, Content, TitleText, Table, GoToListBtn, Td, PostTitleText, GreyText} from './OtherPosts.style'

interface PostData {
    date: number
    id: number
    title: string
    user_nickname: string
}

interface Props {
    boardType: string,
    otherPosts: PostData[]
}

const OtherPosts = ({ boardType, otherPosts }: Props) => {
    const navigate = useNavigate();
    let boardName
    let backToList: To // 목록으로 돌악기 링크
    switch(boardType) {
        case 'notice': {
            boardName = '공지사항';
            backToList = `/${boardType}/1`;
            break;
        }
        case 'scholarship': {
            boardName = '장학금';
            backToList = `/${boardType}/1`;
            break;
        }
        case 'column': {
            boardName = '칼럼';
            backToList = `/${boardType}`;
            break;
        }
        default :{
            boardName = '미션 제출';
            backToList = `/mission/${boardType}/1`;
            break;
        }
    }

    const OtherPostsTd = ({ id, title, date, user_nickname } : PostData) => {
        return (
            <Td onClick={() => navigate(`/${boardType}/post/${id}`, { state: { boardType: boardType, postID: id }})}>
                <FlexRowJustifyContentSpaceBetween>
                    <FlexColumn>
                        <PostTitleText>{title}</PostTitleText>
                        <GreyText>{conversionDate(date)} {user_nickname}</GreyText>
                    </FlexColumn>
                </FlexRowJustifyContentSpaceBetween>
            </Td>
        )
    }

    return (
        <Container>
            <RootSection>
                <Content>
                    <FlexRow>
                        <TitleText>
                            {`${boardName}의 다른 게시글`}
                        </TitleText>
                    </FlexRow>
                    <Table>
                        <tbody>
                        { otherPosts.map((post, i) => {
                            return post && (
                                <tr key={`post${i}`}>
                                    <OtherPostsTd 
                                        id={post.id}
                                        title={post.title} 
                                        date={post.date} 
                                        user_nickname={post.user_nickname}
                                    />
                                </tr>
                        )  }) } 
                        </tbody>
                    </Table>
                    <GoToListBtn
                        onClick={() => navigate(backToList)}
                    >목록으로 돌아가기</GoToListBtn>
                </Content>
            </RootSection>
        </Container>
  )
}

export default OtherPosts