import styled from "styled-components";
import theme from "../../styles/theme";

export const Background = styled.div`
    z-index: 999;
    position: fixed;
    top:0; left: 0; bottom: 0; right: 0;
    background: rgba(0, 0, 0, 0.4);
`

export const Container = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`


export const Box = styled.div`
    background: #FFFFFF;
    border-radius: 10px;
    padding: 2rem;
    box-sizing: border-box;
    min-width: 60.875rem;
    height: 70vh;
    white-space: pre-wrap;
    overflow: scroll;

    @media screen and (max-width: 60.875rem) {
        min-width: 100%;
        width: 90vw;
    }

    ::-webkit-scrollbar {
        width: 1.5rem;
        
    }
    ::-webkit-scrollbar-thumb {
        background-color: ${theme.colors.oliveColor2};
        border-radius: 3.75rem;
        background-clip : padding-box;
        border: 0.5rem solid transparent;
        height: 10rem;
    }
    ::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 10px;
    }
`

export const CloseButton = styled.div`
    cursor: pointer;
    position: absolute;
    top: -5%;
    right: 0;
`

export const Title = styled.h1`
    font-weight: 700;
    font-size: 1.875rem;
    line-height: 2.75rem;
    letter-spacing: -0.02em;
    margin: 0 auto;
    text-align: center;
    color: ${theme.colors.mainBlackColor};

    @media screen and (max-width: 576px) {
        font-size: 1.875rem;
        line-height: 2.75rem;
    }
`

export const Text = styled.p`
    font-weight: 400;
    font-size: 1.0625rem;
    line-height: 1.625rem;
    letter-spacing: -0.02em;

    color: ${theme.colors.mainBlackColor};

    @media screen and (max-width: 576px) {
        font-size: 1.0625rem;
        line-height: 1.625rem;
    }
`