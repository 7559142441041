import React, { ReactNode } from 'react'
import { icons } from '../../static/icons/icon'
import { Container, Head, TitleText, UserThumbnail, Username, DateText, Line, Content } from './Post.style';
// const PostContext = createContext();

interface Props {
    children?: ReactNode
}

const Post = ({ children, ...restProps } : Props) => {
    return <Container>{children}</Container>
}

Post.Head = function PostHead({ children, ...restProps } : Props) {
    return <Head>{children}</Head>
}

Post.TitleText = function PostTitleText({ children, ...restProps } : Props) {
    return <TitleText>{children}</TitleText>
}

Post.UserThumbnail = function PostUserThumbnail({ children, ...restProps } : Props) {
    return children === "" ? 
        (icons.user_lighter) 
        : (<UserThumbnail></UserThumbnail>)
}

Post.Username = function PostUsername({ children, ...restProps } : Props) {
    return <Username>{children}</Username>
}

Post.Date = function PostDate({ children, ...restProps } : Props) {
    return <DateText>{children}</DateText>
}

Post.Line = function PostLine() {
    return (<Line />)
}

Post.Content = function PostContent({ children, ...restProps } : Props) {
    return <Content>{children}</Content>
}

export default Post;

