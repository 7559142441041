import React from 'react'
import { Btn } from './SubmitBtn.style'

interface Props {
    value: string;
    type: string;
    disabled?: boolean;
    handler?: () => void;
}

const SubmitBtn = ({ value, type, disabled, handler }: Props) => {
  return <Btn
            value={value}
            type={type}
            onClick={handler}
            disabled={disabled}
        />
}

export default SubmitBtn