import styled, { css, keyframes } from "styled-components";
import theme from "../../styles/theme";

export const Container = styled.div`
  padding-top: 1rem;
  width: 100%;
`;

export const TabsContainer = styled.div`
  display: flex;
  width: 50%;
  padding: 2px;
  @media screen and (max-width: 576px) {
    width: 100%
  }
`;

export const TabPanelContainer = styled.div`
  padding-top: 1rem;
`;

export const TabHeaderContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StylizedTab = styled.button<{ active: boolean }>`
  color: #fff;
  width: 100%;
  padding-block: 1rem;
  background-color: transparent;
  border: none;
  color: ${theme.colors.greyColor2};
  font-weight: 500;
  font-size: 1.1875rem;
  line-height: 1.4375rem;
  cursor: ${(p) => (p.disabled ? "default" : "pointer")};

  ${(p) =>
    p.active &&
    css`
      color: ${theme.colors.mainColor};
      font-weight: bold;
    `}
    @media screen and (max-width: 576px) {
      font-size: 1rem;
      line-height: 1.4375rem;
    }
`;

export const StyledTabPanel = styled.div<{ active: boolean }>`
  display: ${(p) => (p.active ? "flex" : "none")};
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

export const TabsHolder = styled.div`
  display: flex;
  flex-direction: row;
`;

export const TabSlider = styled.div<{ width: number; index: number }>`
  width: ${(props) => `${100 / props.width}%`};
  height: 2.5px;
  background-color: ${theme.colors.mainColor};
  transition: 0.2s;
  transform: ${(props) => `translateX(${props.index * 300 / props.width}%)`};
`;

export const TapLine = styled.div`
    position: absolute;
    bottom: -2px;
    width: 200%;
    height: 2px;
    background-color: ${theme.colors.greyColor3};
    @media screen and (max-width: 576px) {
      width: 100%;
    }
`
