import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import CurriculumContainer from '../../container/CurriculumContainer/CurriculumContainer.component';
import FAQContainer from '../../container/FAQContainer/FAQContainer.component';
import IntroduceSubjectContainer from '../../container/IntroduceSubjectContainer/IntroduceSubjectContainer.component';
import SubjectRightBox from '../../components/SubjectRightBox/SubjectRightBox.component';
import TopTabSlider from '../../components/TopTabSlider/TopTabSlider.component';
import { icons } from '../../static/icons/icon';
import { Container, Content, LeftContainer, PlayIcon, RightContainer, Thumbnail, ThumbnailContainer, TopSection } from './SubjectPage.style';
import { api } from '../../util/api';
import { useAppSelector } from '../../hooks/hooks';

const SubjectPage = () => {
  const { category } = useParams();
  const isLoggedIn = useAppSelector((state: { userReducer: { isLoggedIn: any; }; }) => state.userReducer.isLoggedIn);

  const [intro, setIntro] = useState(``);
  const [lectureList, setLectureList] = useState([]);
  const [FAQList, setFAQList] = useState([]);
  const [kollusURL, setKollusURL] = useState(null);

  const videoPlayValidation = (mcKey: string) => {
    if (!isLoggedIn) {
      throw new Error('로그인을 해주세요.');
    }
    if (mcKey == null) {
      throw new Error('수강신청된 강의가 아닙니다.');
    }
  }


  const getKollusJWT = async (mcKey: string) => {
    try {
      videoPlayValidation(mcKey);
      const response = await api.get(`/lecture/url?mc=${JSON.stringify([{"mckey": mcKey}])}&lecture_name=${category}`);
      if (response?.data?.url == null) {
        throw new Error('잘못된 요청입니다.');
      }
      setKollusURL(response.data.url.replace("http://", "https://"));
      console.log(response.data.url.replace("http://", "https://"));
    } catch (err: any) {
      alert(err.message);
    }
  }

  const getLectureInfo = () => {
    isLoggedIn ? (
      api.get(`/user/lecture/info?lecture_name=${category}`)
      .then((res: any) => {
        if (!res.data.err) {
          setIntro(res.data.info.intro);
          setLectureList(res.data.info.list[0].subcategory)
          setFAQList(res.data.info.faq);
        } else {
          alert(`정보를 가져올 수 없습니다. ${res.data.err}`)
        }
      })
      .catch((err: any) => {
          console.log(err);
      })
    ) : (
      api.get(`/lecture/info?lecture_name=${category}`)
      .then((res: any) => {
        if (!res.data.err) {
          setIntro(res.data.info.intro);
          setLectureList(res.data.info.list[0].subcategory)
          setFAQList(res.data.info.faq);
        } else {
          alert(`정보를 가져올 수 없습니다. ${res.data.err}`)
        }
      })
      .catch((err: any) => {
          console.log(err);
      })
    )
  }

  useEffect(() => {
    getLectureInfo();
  }, [isLoggedIn])

  const TabElements = [
    {
      label: "강의소개",
      container: <IntroduceSubjectContainer intro={intro} />
    },
    {
      label: "커리큘럼",
      container: <CurriculumContainer lectureList={lectureList} getKollusJWT={getKollusJWT} />
    },
    {
      label: "FAQ",
      container: <FAQContainer FAQList={FAQList} />
    },
  ]

  return (
    <Container>
      <TopSection></TopSection>
      <Content>
        <LeftContainer>
          <ThumbnailContainer backgroundImage={`/img/${category}_thumbnail.png`} key={kollusURL} >
            {
              kollusURL ? (
		<>
		<a style={{color: "#ffffff"}} href={kollusURL} target="_blank">영상이 재생되지 않을시 클릭</a>
                <iframe width="100%" height="100%" src={kollusURL} allowFullScreen />
		</>
              ) : (      
                <PlayIcon>
                    {icons.play}
                </PlayIcon>
              )
            }
          </ThumbnailContainer>
          <SubjectRightBox />
          <TopTabSlider 
            TabElements={TabElements}
          />
        </LeftContainer>
        <RightContainer>
          <SubjectRightBox />
        </RightContainer>
      </Content>
    </Container>
  )
}

export default SubjectPage
