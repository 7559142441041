import styled from "styled-components";
import theme from "../../styles/theme";

export const Container = styled.div`
    width: 100%;
`

export const ThumbnailContainer = styled.div`
    cursor: pointer;
    position: relative;
    width: 100%;
    aspect-ratio: 16 / 9;

    border-radius: 0.25rem;

    overflow: hidden;
    background-color: grey;
`

export const ThumbnailImg = styled.img`
    width: 100%;
    height: 100%;
`
export const PlayIcon = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`


export const BottomContainer = styled.div`

`

export const CategoryBox = styled.div`
    background: ${theme.colors.mainColor};
    border-radius: 0.125rem;
    padding: 0.1rem 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.75rem;
    letter-spacing: -0.02em;
    color: #FFFFFF;
    margin-right: 0.5rem;
`
export const TitleText = styled.h1`
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.1875rem;
    color: ${theme.colors.greyColor1};
`

export const EmptyBar = styled.div`
    height: 2px;
    width: 100%;
    background-color: ${theme.colors.greyColor3};
    position: relative;
    @media screen and (max-width: 576px) {
        height: 5px;
    }
`

export const PercentGageBar = styled.div<{gage: number}>`
    height: 100%;
    width: ${props => props.gage}%; 
    background-color: ${theme.colors.brownColor1};
    position: absolute;
`

export const PercentText = styled.p`
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin-block: 0.5rem;
    color: ${theme.colors.greyColor1};
`