import styled from "styled-components";
import { RootContainer, RootContent } from "../../styles/public";
import theme from "../../styles/theme";

export const Container = styled(RootContainer)`
    background-color: ${theme.colors.background};
    padding-block: 3rem;
`

export const Contnet = styled(RootContent)`
    flex-direction: column;
    width: 26.25rem;
`

export const TitleText = styled.h1`
    font-weight: 700;
    font-size: 30px;
    line-height: 44px;
    letter-spacing: -0.02em;
`

export const Line = styled.div`
    height: 0.5px;
    background-color: ${theme.colors.greyColor3};
    width: 100%;
    margin-block: 0.5rem;
`

export const SignUpText = styled.p`
    margin-block: 2rem;
    font-weight: 500;
    font-size: 15px;
    line-height: 130%;
    letter-spacing: -0.02em;
    color: ${theme.colors.brownColor1};
    > a  {
        font-weight: 700;
        text-decoration: underline;
        color: ${theme.colors.brownColor1};
    }
`
