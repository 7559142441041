import React, { useEffect, useRef, useState } from 'react'
import ReviewCard from '../../components/ReviewCard/ReviewCard.component'
import { icons } from '../../static/icons/icon'
import { FlexRow, FlexRowJustifyContentSpaceBetween } from '../../styles/public'
import { ArrowBtn, Slider, Slides, ArrowBtnContainer, Container } from './ReviewCardSlider.style'

const reviewDatas = [
    { title: `내건주님을 안지
벌써 2년이 다 되어가요`,
      content: `내건주님을 안지 벌써 2년이 다 되어가요. 당시 저는 대기업 직장인이었고, 퇴사가 하고 싶어서 투잡 공부를 다양하게 하던 중 내건주님을 알게 되었습니다. 내건주님이 2년간 성장하는 사이, 저도 그토록 원했던 대기업에서 퇴사를 했습니다. 좋은 결과를 보니 일하는 보람이 이런거구나를 느끼게 되는 요즘입니다.`,
      name: `선OO`,
      score: 5.0
    },
    { title: `너무나 감사드리고 그렇게나마
작은 보답을 하고 싶었습니다.`,
      content: `오늘 군산팀 심화반 시작했고 두분 다 카드결재했습니다. 그리고 적은 금액이지만 남은 금액의 20% 계좌로 넣었습니다. 너무나 감사드리고 그렇게나마 작은 보답을 하고 싶었습니다. 기부하지 마시고 아버님과 맛있는 거 사드세요~^^`,
      name: `조OO`,
      score: 5.0
    },
    { title: `다른 일을 시작하는데에
가장 큰 원동력이었던 건`,
      content: `저는 작년 6월에 수강한 임xx입니다. 어제 퇴사 통보를 했고 4월까지만 다니기루 했답니다! :) 2년 반도 안 다닌 첫 직장이라 조금 싱숭생숭하더라구요. 제가 다른 일을 시작하는데에 가장 큰 원동력이었던 건 내건주님 덕분이라 이렇게 몇자써서 감사의 마음을 전합니다.`,
      name: `임OO`,
      score: 5.0
    },
    { title: `수익 100만원을 달성하고 진단서 서비스를
신청하게 되다니 감회가 새롭습니다`,
      content: `1월에 듣고 블로그 등 메신저 창업을 시작한 결과보다 태도입니다. 1월 29일에 시작했으면서 2월 14일에 벌써 수익화 질문을 드렸던 때가 엊그제 같은데 5월 말이 되어서 수익 100만원을 달성하고 진단서 서비스를 신청하게 되다니 감회가 새롭습니다. 직장에서는 남의 일을 대신 해주고 소모되는 느낌만 받았었는데 다른 이의 문제를 해결해주고, 또 공감하고 제가 도움이 된다는 사실이 정말 즐겁습니다 `,
      name: `이OO`,
      score: 5.0
    },
    { title: `아깝지가 않네요.
2배 가격이라도 샀을 겁니다.`,
      content: `강의가 50만원, 100만원짜리도 있고 300만원짜리도 있습니다. 그런데 몇 천, 몇 억을 버는 걸 생각하면 500만원짜리도 아깝지 않죠.
중요한 것은 강의에서 단 한개의 인사이트를 얻고 그것을 실천에 옮겼으면 이미 본전은 찾은 겁니다. 
몇십만원이라는 돈은 사실 직장인에게 술자리 몇번으로 날아가는 금액이라.. 
20~30대의 사람들에게 '모두' 추천드리고 싶습니다.`,
      name: `김OO`,
      score: 5.0
    },
    { title: `미로속에서 갇혀있던 삶에서 한줄기 빛을
본것과 같은 느낌이 듭니다.`,
      content: `저는 s전자 대기업 과장입니다. 오랜만에 이 시간까지 눈이 떠 있습니다. 처음에는 어린 친구 눈 빨리 떴네? 오 말 잘하네? 이정도로만 봤던 유튜브 몇 편이 신청하게 만들고 지금 이 시간에 글을 쓰네요.
답을 못찾고 막연히 지내왔던 삶, 미로속에서 갇혀있던 삶에서 한줄기 빛을 본것과 같은 느낌이 듭니다. 
떠오르는 주체할 수 없는 아이디어가 공책 한가득 적혀있네요.`,
      name: `이OO`,
      score: 5.0
    },
    { title: `어디 자랑할 곳이
내건주님밖에 없네요`,
      content: `이번에 인건비 100만원짜리 공사를 하게 되었습니다
내건주님의 강의와 추천해주신 책들을 보고 
제 삶에 적용시켜 이번에 인건비 100만원짜리 공사를 하게 되었습니다.
뭐 진단권 받으려고 드리는 말씀은 아니구요
어디 자랑할 곳이 내건주님밖에 없네요`,
      name: `용OO`,
      score: 5.0
    },
    { title: `이 강의는 내 무의식마저
깨어나게 해주는강의에요`,
      content: `솔직히 처음엔 무슨 강의인줄 몰랐어요. 
내건주라는 타이틀도 이 강의를 들으면서 알게 되었습니다. 전반부엔 이렇다할 발전을 못 느꼈어요 미션도 많아서.. 그런데 그렇게 몇주 쉬다가 돌아오니 미친 아이디어가 생각났어요.
이 강의는 내 무의식마저 깨어나게 해주는 강의에요
100강이라도 수강할 수 있을 거 같아요.`,
      name: `전OO`,
      score: 5.0
    },
    { title: `강의 내용을 활용해서 제 사업에 적용했고
작년 대비 2배의 순수익을 벌고 있습니다.`,
      content: `강의 내용을 활용해서 제 사업에 적용했고, 작년 대비 2배의 순수익을 벌고 있습니다
마케팅 홍보 문구도 영감 받았고, 진상 고객 응대방법도 영감 받았습니다. 
바쁘게 일하다 보니, 다시 레벨업이 필요하다 생각되서 다시 정주행중입니다. 
추후에 추가로 신청해서 컨설팅 요청드리겠습니다~`,
      name: `자OOO`,
      score: 5.0
    }
]

const ReviewCardSlider = () => {
  const [curCard, setCurCard] = useState(0);
  const [nextCard, setNextCard] = useState(1);
  const [prevCard, setPrevCard] = useState(8);

  useEffect(() => {
    curCard === 8 ? setNextCard(0) : setNextCard(curCard + 1)
    curCard === 0 ? setPrevCard(8) : setPrevCard(curCard - 1)
}, [curCard])

  const totalWidthRef = useRef<HTMLInputElement>(null);
  
  const [width, setWidth] = useState<any>(0);
  useEffect(() => {
    totalWidthRef.current && setWidth(totalWidthRef.current.clientWidth);
  }, []);

  return (
    <Container ref={totalWidthRef}>
      <Slider>
        <Slides curCard={curCard} totalCard={reviewDatas.length} width={width}>
            { reviewDatas.map((data, i) => {
            return (
                <ReviewCard key={`reviewcard${i}`}>
                    <div>
                    <ReviewCard.Title>{data.title}</ReviewCard.Title>
                    <ReviewCard.Content>{data.content}</ReviewCard.Content>  
                    </div>
                    <div>
                    <ReviewCard.Line />
                    <FlexRowJustifyContentSpaceBetween>
                        <FlexRow>
                        {icons.user}
                        <ReviewCard.Name>{data.name}</ReviewCard.Name>
                        </FlexRow>
                        <FlexRow>
                        {icons.star}
                        <ReviewCard.Score>{data.score}</ReviewCard.Score>
                        </FlexRow>
                    </FlexRowJustifyContentSpaceBetween>
                    </div>
                </ReviewCard>
            )
            })}
        </Slides>
      </Slider>
      <ArrowBtnContainer>
          <ArrowBtn onClick={() => setCurCard(prevCard)}>
            {icons.roundleftarrow}
          </ArrowBtn>
          <ArrowBtn onClick={() => setCurCard(nextCard)}>
            {icons.roundrightarrow}
          </ArrowBtn>
        </ArrowBtnContainer>
    </Container>
  )
}

export default ReviewCardSlider