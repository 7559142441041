import React from 'react'
import { FlexColumnJustifyContentSpaceBetween, FlexRow, FlexRowJustifyContentSpaceBetween } from '../../styles/public'
import { Table, Td, FixationText, TitleText, GreyText } from './PostTable.style'
import { icons } from "../../static/icons/icon";
import { useNavigate } from 'react-router-dom';
import { conversionDate } from '../../util/conversionTimestamp';

interface PostsData {
    pinned: boolean,
    user_nickname: string,
    date: number,
    title: string,
    id: number,
    type: string,
    user_id?: number,
    contents?: string,
}
interface Props {
    datas: PostsData[]
    missionType?: string
}


const PostTable = ({ datas, missionType } : Props) => {

    const PostTd = ({ id, pinned, title, date, user_nickname, type }: PostsData) => {
        const navigate = useNavigate();
        const postPath = missionType ? `/mission/${type}/post/${id}` : `/${type}/post/${id}`

        return (
            <Td onClick={() => navigate(postPath, { state: { boardType: type, postID: id }})}>
                <FlexRowJustifyContentSpaceBetween>
                    <FlexColumnJustifyContentSpaceBetween>
                        {pinned && (
                            <FlexRow><span>{icons.bell}</span><FixationText>고정</FixationText></FlexRow>
                        )}
                        <TitleText>{title}</TitleText>
                        <GreyText>{conversionDate(date)} {user_nickname}</GreyText>
                    </FlexColumnJustifyContentSpaceBetween>
                </FlexRowJustifyContentSpaceBetween>
            </Td>
        )
    }

    return (
        <Table>
            <tbody>
                <tr>
                {
                    datas && datas.map((data, i) => {
                        return (
                            <PostTd
                                key={`post${i}`}
                                id={data.id}
                                type={data.type}
                                pinned={data.pinned}
                                title={data.title}
                                date={data.date}
                                user_nickname={data.user_nickname}
                            />
                        )
                    })
                }
                </tr>
            </tbody>
        </Table>
  )
}
export default PostTable