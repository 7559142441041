import { createGlobalStyle } from "styled-components";
// import theme from './theme';


const GlobalStyle = createGlobalStyle`
    html, body {
        margin: 0;
        padding: 0;
        background-color: #FFFFFF;
        font-size: 100%;
        font-family: 'Pretendard';
        font-weight: 400;   
    }

    p {
        padding: 0;
    }
`;

export default GlobalStyle;