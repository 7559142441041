import styled from "styled-components";
import { RootContainer, RootContent, RootSection } from "../../styles/public";
import theme from "../../styles/theme";

export const Container = styled(RootContainer)`
    background-color: ${theme.colors.background};
    padding-block: 3rem;
`

export const Section = styled(RootSection)`
    

`

export const Content = styled(RootContent)`
    display: flex;
    flex-direction: column;
`

export const TitleText = styled.h1`
    font-weight: 400;
    font-size: 2.125rem;
    line-height: 2.75rem;
    letter-spacing: -0.02em;
    color: ${theme.colors.mainBlackColor};
    > span {
        font-weight: 800;
        color: ${theme.colors.mainColor};
    }

    @media screen and (max-width: 576px) {
        font-size: 1.375rem;
        margin-block: 0.25rem;
    }
`

export const DescriptionText = styled.p`
    font-weight: 400;
    font-size: 1.0625rem;
    line-height: 1.125rem;
    margin-block: 0.5rem;
    color: ${theme.colors.mainBlackColor};

    @media screen and (max-width: 576px) {
        font-size: 0.9375rem;
        line-height: 170%;
        margin-block: 0.25rem;
    }
`

export const Button = styled.button`
    cursor: pointer;
    border: 0;
    background-color: ${theme.colors.mainColor};
    border-radius: 4px;
    height: 2rem;
    color: white;
`

export const ColumnsContainer = styled.div`
    width: 100%;
    margin-block: 4rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(36.25rem, 1fr));
    grid-column-gap: 1.5rem;
    grid-row-gap: 2.5rem;

    @media screen and (max-width: 576px) {
        grid-template-columns: repeat(1, minmax(100%, 1fr));
    }
`