import React, { useState, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Container, Contnet, TitleText } from './FindPasswordPage.style'
import InputBox from '../../components/InputBox/InputBox.component';
import SubmitBtn from '../../components/SubmitBtn/SubmitBtn.component';
import { useForm } from "react-hook-form"
import { Form } from '../../styles/public';
import { Data } from '../../components/ToggleDropdown/ToggleDropdown.style';
import { api } from '../../util/api';
import PopupBox from '../../components/PopupBox/PopupBox.component';
import LoadingBox from '../../components/LoadingBox/LoadingBox.component';

const FindPasswordPage = () => {
    const navigate = useNavigate();

    const [popupOpen, setPopupOpen] = useState(false);
    const openPopup = () => {
        setPopupOpen(true);
    };
    const closePopup = () => {
        setPopupOpen(false);
    };

    const [loading, setLoading] = useState(false);
    const openLoading = () => {
        setLoading(true);
    };
    const closeLoading = () => {
        setLoading(false);
    };

    const {
        setValue,
        register,
        formState: { errors },
        handleSubmit,
    } = useForm();

    useEffect(() => {
        register("id", {
            required: "아이디를 입력 해주세요.",
            pattern: {
                value: /^[a-z]+[a-z0-9]{5,19}$/,
                message: "아이디 형식에 맞지 않습니다. (영어 소문자, 숫자 5~19자)"
            }
        });

        register("email", {
            required: "이메일을 입력 해주세요.",
            pattern: {
                value: /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i,
                message: "이메일 형식에 맞지 않습니다."
            }
        });
    }, [register]);
                    

    const submitReset = (data: any) => {
        openLoading();
        api.put('/signjoin/find/password', {
            "login_id": data.id,
            "email": data.email
        })
        .then((res: any) => {
            if (!res.data.err) {
                closeLoading();
                openPopup();
            } else {
                closeLoading();
                alert('입력한 정보의 계정이 없습니다.')
            }
        })
        .catch((err: any) => {
            closeLoading();
            if (err.respose.status === 400) {
                alert(`잘못된 정보입니다.`)
            } else {
                alert(`시스템 오류입니다.`)
            }
        });
    }

    return (
        <Container>
            <Contnet>
                <TitleText>비밀번호 찾기</TitleText>
                <Form onSubmit={handleSubmit(submitReset)}>
                    <InputBox>
                        <InputBox.Label htmlFor="id">아이디</InputBox.Label>
                        <InputBox.InputText 
                            placeholder='아아디를 입력해 주세요.'
                            type='id'
                            handler={async (e: any) => setValue("id", e.target.value)}
                        />
                        { errors?.id && <InputBox.ErrorText>{`${errors?.id?.message}`}</InputBox.ErrorText> }
                    </InputBox>

                    <InputBox>
                        <InputBox.Label htmlFor="email">이메일</InputBox.Label>
                        <InputBox.InputText 
                            placeholder='이메일을 재입력해 주세요.'
                            type='email'
                            handler={async (e: any) => setValue("email", e.target.value)}
                        />
                        { errors?.email && <InputBox.ErrorText>{`${errors?.email?.message}`}</InputBox.ErrorText> }
                    </InputBox>

                    <SubmitBtn
                        value='비밀번호 찾기 '
                        type='submit'
                    />
                </Form>
            </Contnet>
            {
                popupOpen && (
                <PopupBox>
                    <PopupBox.Text>고객님의 이메일로 메일이 발송되었습니다.</PopupBox.Text>
                    <PopupBox.Btn closePopup={closePopup}>확인</PopupBox.Btn>
                </PopupBox>
                )
            }
            { loading && ( <LoadingBox /> ) }
        </Container>
    )
}

export default FindPasswordPage