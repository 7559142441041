import React, { ReactNode } from 'react'
import { useNavigate } from 'react-router-dom';
import { Img } from '../../styles/public'
import { Container, IndexBox, Thumbnail, TextBox, TitleText, DateText} from './Column.style';

interface Props {
    children: ReactNode
    postid?: string
}

interface ThumnbnailProps {
    src: string,
    alt: string
}

const Column = ({ children, postid }: Props) => {
    const navigate = useNavigate();
    return <Container onClick={e => navigate(`/column/post/${postid}`)}>{children}</Container>
}

Column.Index = ({ children }: Props) => {
    return <IndexBox>{children}</IndexBox>
}

Column.Thumbnail = ({ src, alt }: ThumnbnailProps) => {
    return <Thumbnail><Img src={src} alt={alt} /></Thumbnail>
}

Column.TextBox = ({ children }: Props) => {
    return <TextBox>{children}</TextBox>
}

Column.Title = ({ children }: Props) => {
    return <TitleText>{children}</TitleText>
}

Column.Date = ({ children }: Props) => {
    return <DateText>{children}</DateText>
}

export default Column