import { Link } from "react-router-dom";
import styled from "styled-components";
import theme from "../../styles/theme";

export const Container = styled.div`
    position: absolute;
    display: none;
    background: rgba(0, 0, 0, 0)

`

export const Table = styled.table`
    background: #FFFFFF;
    border-radius: 0.25rem;
    box-shadow: 0rem 0rem 0.5rem rgba(0, 0, 0, 0.05);
`

export const Row = styled.tr`
    width: 9rem;
    border-top: 1px solid ${theme.colors.greyColor3};
    padding: 1rem 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    
    &:first-child {
        border: none;
    }

    :hover {
        > td {
            font-weight: 600;
            color: ${theme.colors.mainColor};
        }
    }
`
export const StyledLink = styled(Link)`
    text-decoration: none;
    width: 100%;
    height: 100%
`

export const Data = styled.td`
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.1875rem;
    color: ${theme.colors.greyColor2};
    
`