import { combineReducers } from 'redux';
import { configureStore } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { boardSlice } from './slice/boardSlice';
import { userSlice } from "./slice/userSlice";
import { subjectsSlice } from './slice/subjectSlice';

const reducers = combineReducers({
    userReducer: userSlice.reducer,
    boardReducer: boardSlice.reducer,
    subjectsReducer: subjectsSlice.reducer,
});

const persistConfig = {
    key: 'root',
    storage,
};
  
const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
	reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production'
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;