import styled from "styled-components";

export const Container = styled.div`
    position: relative;
    width: 100%;
    height: 30.0625rem;
    margin-bottom: 5rem;
    box-sizing: border-box;

    @media screen and (max-width: 576px) {
        height: 28.6875rem;
    }
`

export const Slider = styled.div`
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
`

export const Slides = styled.div<{totalCard: number, curCard: number, width: number}>`
    display: grid;
    box-sizing: border-box;
    grid-template-columns: ${({ totalCard }) => `repeat(${totalCard}, minmax(380px, auto))`};
    grid-column-gap: 25px;
    transform: translateX(${({ curCard }) => `calc(${-(100 / 3) * (curCard)}%)`});
    transition: ease 1s;

    @media screen and (max-width: 576px) {
        grid-template-columns: ${({ width, totalCard }) => `repeat(${totalCard}, minmax(${width}px, auto))`};
        grid-column-gap: 0rem;
        transform: translateX(${({ curCard }) => `calc(${-(100) * (curCard)}%)`});
    }
`

export const ArrowBtnContainer = styled.div`
    position: absolute;
    width: 112.5%;
    display: flex;
    justify-content: space-between;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media screen and (max-width: 576px) {
        width: 80%;
        top: 10%;
    }
`

export const ArrowBtn = styled.button`
    cursor: pointer;
    border: 0;
    background-color: transparent;
`