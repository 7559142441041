import styled from "styled-components";
import { RootContainer, RootContent } from "../../styles/public";
import theme from "../../styles/theme";

export const Container = styled(RootContainer)`
    background-color: ${theme.colors.background};
    padding-block: 3rem;
`

export const Contnet = styled(RootContent)`
    flex-direction: column;
    width: 26.25rem;
    box-sizing: border-box;
    padding-inline: 1rem;
    @media screen and (max-width: 576px) {
        width: 100%;
    }
`

export const TitleText = styled.h1`
    font-weight: 700;
    font-size: 30px;
    line-height: 44px;
    letter-spacing: -0.02em;
`

export const SubmitBtn = styled.input`
    width: 420px;
    height: 50px;
    margin-block: 2rem;
    background: #6B3F36;
    border-radius: 4px;
    border: 0;
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    /* or 27px */
    margin-block: 2rem 0.5rem;
    letter-spacing: -0.02em;

    /* wht */

    color: #FFFFFF;

    @media screen and (max-width: 576px) {
        width: 334px;
        height: 45px;
        border-radius: 6px;
    }
`
