import styled from "styled-components";
import { RootContainer, RootContent, RootSection } from "../../styles/public";

export const Container = styled(RootContainer)`
    padding-block: 5rem;
`

export const Section = styled(RootSection)`
`

export const Content = styled(RootContent)`
    padding-inline: 1rem;
    display: flex;
    flex-direction: column;
`