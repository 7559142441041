import styled from "styled-components";
import { RootContainer } from "../../styles/public";
import theme from "../../styles/theme";

export const Container = styled(RootContainer)`
    width: 100%;
    box-sizing: border-box;

    > p {
        font-weight: 500;
        font-size: 1.125rem;
        line-height: 2rem;
        color: ${theme.colors.mainBlackColor};
        text-transform: capitalize;

        > strong {
            font-weight: 700;
        }   
    }    
    
    > figure {
        margin: 0 auto;
        width: 100%;
        > img {
            max-width: 100%;
        }
    }
    @media screen and (max-width: 576px) {
        > p {
            font-size: 0.875rem;
            line-height: 170%;
        }
    }
`