import styled from "styled-components";
import { RootContainer } from "../../styles/public";
import theme from "../../styles/theme";

export const Container = styled(RootContainer)`
    height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export const Text = styled.h1`
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1.8125rem;
    color: ${theme.colors.mainColor};
`