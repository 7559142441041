import styled from "styled-components";
import theme from "../../styles/theme";

export const Container = styled.div`
    margin-block: 2rem;
    border: 1px solid #D7D7D7;
    border-radius: 4px;
    display: flex;
    align-items: center;
    box-sizing: border-box;

`
export const SelectBox = styled.select`
    border: 0;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.625rem;
    padding-left: 0.5rem;
    color: ${theme.colors.mainBlackColor};
    background-color: transparent;
    
    > option {
        font-size: 1rem;
    }
    :focus {
        outline: none;
    }
    @media screen and (max-width: 576px) {
        font-size: 0.75rem;
    }
`

export const Input = styled.input`
    margin-inline: 1rem;
    font-weight: 400;
    line-height: 1.625rem;
    font-size: 1rem;
    border: 0;
    color: ${theme.colors.mainBlackColor};
    :focus {
        outline: none;
    }
    @media screen and (max-width: 576px) {
        width: 6rem;
        font-size: 0.75rem;
        margin-inline: 0rem;
    }
`

export const SearchBtn = styled.button`
    width: 3.125rem;
    aspect-ratio: 1/1;
    background: ${theme.colors.mainColor};
    border-radius: 4px;
    border: 0;
    cursor: pointer;
    
    @media screen and (max-width: 576px) {
        width: 2.5rem;
    }
`