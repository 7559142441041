import styled from "styled-components";
import theme from "../../styles/theme";

export const Container= styled.div`
    width: 100%;
`

export const TitleText = styled.h1`
    font-weight: 800;
    font-size: 1.5rem;
    line-height: 1.8125rem;
    margin-bottom: 1rem;
    color: ${theme.colors.mainColor};
`

export const DescriptionText = styled.p`
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    margin-bottom: 2rem;
    color: ${theme.colors.greyColor1};
`

export const ContentBox = styled.div`

`