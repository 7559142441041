import styled, {css} from "styled-components";
import theme from "../../styles/theme";

export const Container = styled.div`
    position: relative;
    width: 100%;
    border-radius: 4px;
    margin-block: 1rem;
    border: 1px solid #F4F4F4;
`

export const Tab = styled.div`
`

export const TitleContainer = styled.div<{ active : boolean }>`
  margin: 0;
  padding: 1.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${props => props.active ? "#F6F6F5" : "#FFFFFF"};
`;

export const Div = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
`

export const TitleText = styled.h3<{ active : boolean }>`
  margin: 0;
  font-weight: ${props => props.active ? "700" : "400"};
  font-size: 1.125rem;
  line-height: 1.375rem;

  color: ${props => props.active ? theme.colors.mainColor : "#000000"};

  @media screen and (max-width: 576px) {
    font-size: 1rem;
  }
`

export const AllTimeText = styled.p`
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.1875rem;
  color: ${theme.colors.mainColor};
  margin: 0;
  @media screen and (max-width: 576px) {
    display: none;
  }
`


export const Icon = styled.div`
  margin-right: 1rem;
`

export const BodyContainer = styled.div<{ active : boolean }>`
  display: block;
  position: relative;
  padding: 0;
  margin: 0;
  height: 0;
  overflow: hidden;
  background-color: #FFFFFF;
  ${({ active }) =>
    active &&
    css`
      height: 100%;
    `}
`;

export const Content = styled.div`
  margin: 0;
  padding: 0.25rem 1.5rem;
  height: auto;
  display: flex;
  justify-content: space-between;
`;

export const VideoText = styled.p`
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.1875rem;
`

export const VideoTimeText = styled(VideoText)`
  @media screen and (max-width: 576px) {
    display: none;
  }
`
