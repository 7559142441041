import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { RootContainer, RootContent } from '../../styles/public'

export const FooterContainer = styled.footer`
    background-color: #6B3F36;
    width: 100%;
    padding-block: 30px;
`

export const TopContainer = styled(RootContainer)`
`
export const TopContent = styled(RootContent)`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding-inline: 1rem;

    @media screen and (max-width: 576px) {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
    }
`

export const FirstGrid = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    > div > p {
        color: #D7BFB9;
    }

    > div:nth-child(1) {
        display: flex;
        flex-direction: column
    }

    > div:nth-child(1) > p {
        font-weight: 400;
        font-size: 14px;
        line-height: 26px;
    }

    > div:nth-child(1) > p > b {
        font-weight: 700;
    }

    /* 유튜브 */
    > div:nth-child(2) {
        display: flex;
        align-items: center;
    }

    > div:nth-child(2) > p {
        font-weight: 500;
        line-height: 26px;
        display: flex;
        align-items: center;
        margin-left: 10px;
    }

    @media screen and (max-width: 576px) {
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        > div:nth-child(2) > p {
            margin-block: 0;
        }
    }
`

export const SecondGrid = styled.div`
    display: flex;
    justify-content: space-around;

    > div {
        display: flex;
        flex-direction: column;
    }

    @media screen and (max-width: 576px) {
        display: none;
    }
`

export const StyledLink = styled(Link)`
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-decoration: none;
    color: #D7BFB9;
    display: flex;
    align-items: center;
    text-align: center;
    margin-bottom: 15px;
`

export const ThirdGrid = styled.div`
    display: flex;
    align-self: start;
    justify-content: flex-end;
    width: 100%;
    > div > p:nth-child(1) {
        color: #FFFFFF;
        font-weight: 700;
        font-size: 15px;
        line-height: 18px;
        margin: 0;
    }

    > div > p:nth-child(2) {
        color: #D7BFB9;
        font-size: 15px;
        line-height: 26px;
    }

    @media screen and (max-width: 576px) {
        justify-content: flex-start;
        
        > div {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
`

export const Line = styled.hr`
    opacity: 0.4;
    border: 1px solid #A6756A;
    @media screen and (max-width: 576px) {
        width: 90%;
    }
`

export const BottomContainer = styled(RootContainer)`
    padding-inline: 1rem;
`
export const BottomContent = styled(RootContent)`
    
    > div > p {
        color: #D7BFB9;
        font-weight: 400;
        font-size: 13px;
        margin-bottom: 0;
    }
    

    @media screen and (max-width: 576px) {
        flex-direction: column;
        align-items: flex-start;
        box-sizing: border-box;
        
        > div {
            margin-top: 10px;
            /* 두 번째 p태그는 font-size 작게 */
            &:nth-child(2) > p {
                font-size: 11px;
            }
        }

        > div > p {
            line-height: 22px;
            margin-block: 0;
        }
    }
`