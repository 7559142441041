import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppSelector } from '../../hooks/hooks'
import { subjects } from '../../static/subjects/subjects'
import { FlexRow, FlexRowJustifyContentSpaceBetween } from '../../styles/public'
import { api } from '../../util/api'
import { Button, Category, Container, Content, TotalCostText, SaleCostText, DescriptionText, GrayText, Line, TimeInfoText, TitleText } from './SubjectRightBox.style'

const SubjectRightBox = () => {
    const navigate = useNavigate();
    const { category } = useParams();

    const permission = useAppSelector((state: { userReducer: { permission: any; }; }) => state.userReducer.permission);
    const subjectsPrice = useAppSelector((state: { subjectsReducer: { subjects: any; }; }) => state.subjectsReducer.subjects)[category];

    const subject = subjects.filter((s) => {
        return s.category === category;
    })[0]

    const [buttonText, setButtonText] = useState('');
    const [status, setStatus] = useState(-1);

     /** 결제 상태 가져오기 */
    const getPaidInfo = () => {
        api.get(`/user/lecture/check`)
        .then((res: any) => {
            if (res.data.availableCourse.includes(category)) {
                setButtonText('수강 중')
                setStatus(0)
            } else {
                if (res.data.vbank.includes(category)) {
                    setButtonText('입금 대기 중')
                    setStatus(0)
                } else {
                    setButtonText('수강 신청')
                    setStatus(1)
                }
            }
        })
        .catch((err: any) => {
            console.log(err);
        })

    }


    useEffect(() => {
        switch(permission) {
            case -1:
                setButtonText('로그인')
                break
            case 3 :
                setButtonText('수강 완료')
                setStatus(0)
                break
            case 4 :
                setButtonText('강의 수정')
                setStatus(2)
                break
            default :
                getPaidInfo();
       } 
       
    }, [permission])

    const onSubmit = () => {
        if (status === -1) {
            navigate('/login')
            return
        } else if (status === 1) {
            navigate(`/pay/${category}`)
            return
        } else if (status === 2) {
            navigate(`/subject/${category}/posting`)
            return
        }
    }

    return (
        <Container>
            <Content>
                <Category>{subject.kr_category}</Category>
                <TitleText>{subject.title}</TitleText>
                <DescriptionText>{subject.description}</DescriptionText>
            </Content>
            <Line />
            <Content>
                <TimeInfoText>개강날짜 ㅣ <span>즉시시작</span></TimeInfoText>
                <TimeInfoText>수강기간 ㅣ <span>{subject.duration}</span></TimeInfoText>
            </Content>
            <Line />
            <Content>
                <FlexRow>
                    <TotalCostText><s>{subjectsPrice.total_price.toLocaleString()}원</s></TotalCostText>
                    <SaleCostText>{subjectsPrice.sale_price.toLocaleString()}원</SaleCostText>
                </FlexRow>
                
                <FlexRowJustifyContentSpaceBetween>
                    <TotalCostText>월 <span>{(subjectsPrice.sale_price/12).toLocaleString()}원</span></TotalCostText>
                    <GrayText>12개월 할부 시</GrayText>
                </FlexRowJustifyContentSpaceBetween>
            </Content>
            <Button onClick={() => onSubmit()} permission={permission}>
                {buttonText}
            </Button>
        </Container>
  )
}

export default SubjectRightBox