import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import AccordionTab from '../../components/FAQAccordionTab/FAQAccordionTab.component';
import { useAppSelector } from '../../hooks/hooks';
import { api } from '../../util/api';
import { Container, Content, TitleContainer, TitleText, BasicText, Button } from './FAQPage.style';

const FAQPage = () => {
  const navigate = useNavigate();

  const permission = useAppSelector((state: { userReducer: { permission: any; }; }) => state.userReducer.permission);
  const [FAQList, setFAQList] = useState([]);
  
  const getFAQList = () => {
    api.get(`/faq`)
      .then((res: any) => {
        for (const faq of res.data.faqList) {
          setFAQList(FAQList => [...FAQList, { "q": faq.question, "a": faq.answer }])
        }
      })
      .catch((err: any) => {
        console.log(err);
    })
  }
  useEffect(() => {
    getFAQList()
  }, [])

  return (
    <Container>
      <Content>
        <TitleContainer>
          <TitleText>
              FAQ
          </TitleText>
          <BasicText>
              자주 묻는 질문입니다.
          </BasicText>
        </TitleContainer>
        {
          permission === 4 && <>
            <Button onClick={() => navigate(`/FAQ/edit`)}>FAQ 추가</Button>
          </>
        }
        <AccordionTab FAQList={FAQList} />
      </Content>
    </Container>
  )
}

export default FAQPage;