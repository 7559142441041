import React from 'react'
import { icons } from '../../static/icons/icon'
import { removeCookie } from '../../util/cookie'
import ToggleDropdown from '../ToggleDropdown/ToggleDropdown.component'
import { Btn } from './UserBtn.style'
import { useAppDispatch, useAppSelector } from '../../hooks/hooks'
import { resetUserInfo } from "../../redux/slice/userSlice";
import { useNavigate } from 'react-router-dom'

const UserBtn = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const permission = useAppSelector((state: { userReducer: { permission: any; }; }) => state.userReducer.permission);


    const logOut = () => {
		removeCookie('token'); // 쿠키를 삭제
        dispatch(resetUserInfo()); // 유저 정보 초기화
        navigate('/');
        window.location.reload();
	};

    return (
        <Btn>
            {icons.user}
            <ToggleDropdown>
                <ToggleDropdown.Link to={'/mypage'}>마이페이지</ToggleDropdown.Link>
                {
                    permission === 4 && <ToggleDropdown.Link to={'/adminpage'}>관리자 페이지</ToggleDropdown.Link>
                }
                
                <ToggleDropdown.Action action={logOut}>로그아웃</ToggleDropdown.Action>
            </ToggleDropdown>
        </Btn>
    )
}

export default UserBtn