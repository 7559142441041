import React, { ReactNode } from 'react'
import { Container, Label, SubText, InputText, TextDiv, ErrorText } from './InputBox.style'

interface Props {
    placeholder?: string;
    type?: string;
    value?: string;
    children?: ReactNode;
    disabled?: boolean;
    htmlFor?: string;
    handler?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const InputBox = ({ children } : Props) => {
  return <Container>{children}</Container>
}

InputBox.Label = ({ children, htmlFor } : Props) => {
  return <Label htmlFor={htmlFor}>{ children }</Label>
}

InputBox.TextDiv = ({children}: Props) => {
  return <TextDiv>{children}</TextDiv>
}

InputBox.SubText = ({ children} : Props) => {
  return <SubText>{children}</SubText>
}

InputBox.ErrorText = ({ children} : Props) => {
    return <ErrorText>{children}</ErrorText>
}

InputBox.InputText = ({ placeholder, type, value, disabled, handler }: Props) => {
    return <InputText 
            placeholder={placeholder}
            type={type}
            defaultValue={value}
            disabled={disabled}
            onChange={handler}
        />
}

export default InputBox