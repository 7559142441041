import React from 'react'
import { useNavigate } from 'react-router-dom'
import { icons } from '../../static/icons/icon'
import { Container, Btn } from './PageMove.style'

interface Props {
    totalPageNum: number
    currentPageNumber: number
    boardType: string
    missionType?: string
}
const PageMove = ({totalPageNum, currentPageNumber, boardType, missionType} : Props) => {
    const navigate = useNavigate();
    const path = missionType ? `/mission/${missionType}` : `/${boardType}`

    // 5단위 씩 끊기 위한 범위
    const range =  Math.floor((currentPageNumber - 1) / 5) * 5 + 1
    const lastNumber = Math.min(range + 4, totalPageNum)

    return (
        <Container>
            <Btn 
                onClick={() => navigate(`${path}/${currentPageNumber-1}`)}
                disabled={currentPageNumber === 1}
            >
                {icons.leftArrow}
            </Btn>
            {Array.from({length: lastNumber % 5 ? lastNumber % 5 : 5 }, (v, i)=> i + range).map((n) => {
                return (
                    <Btn 
                        key={`page${n}`}
                        disabled={currentPageNumber === n}
                        onClick={() => navigate(`${path}/${n}`)}
                    >
                        {n}
                    </Btn>
                )
            })}
            <Btn 
                onClick={() => navigate(`${path}/${currentPageNumber+1}`)}
                disabled={currentPageNumber === totalPageNum}
            >
                {icons.rightArrow}
            </Btn>
        </Container>
    )
}

export default PageMove