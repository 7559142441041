import styled from "styled-components";
import theme from "../../styles/theme";

export const Container = styled.div`
    padding-block: 1rem;
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(19rem, 1fr));
    grid-gap: 15px;
`

export const BoldText = styled.p`
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.25rem;
    color: ${theme.colors.greyColor1};
`