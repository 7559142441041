import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import CommentContainer from '../../container/CommentContainer/CommentContainer.component';
import OtherPosts from '../../components/OtherPosts/OtherPosts.component';
import Post from '../../components/Post/Post.component'
import TextContainer from '../../container/TextContainer/TextContainer.component';
import { useAppSelector } from '../../hooks/hooks';
import { FlexRow, FlexRowJustifyContentSpaceBetween } from '../../styles/public';
import { api } from '../../util/api';
import { conversionDate } from '../../util/conversionTimestamp';
import { Container, Section, Content, CommentNumber, ModifiedAndDelete, Modified, Bar, Delete, PinButton, ButtonBox, CircleButton } from './PostPage.style';

const PostPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    
    const urlParsing = location.pathname.split('/');

    const isMission = urlParsing.length === 5;

    const boardType = urlParsing[urlParsing.length-3];
    const postID = urlParsing[urlParsing.length-1]
    
    const userNickname = useAppSelector((state: { userReducer: any }) => state.userReducer.nickname);
    const permission = useAppSelector((state: { userReducer: any }) => state.userReducer.permission);

    const [title, setTitle] = useState("");
    const [postUserNickname, setPostUserNickname] = useState("");
    const [date, setDate] = useState(0);
    const [contents, setContents] = useState(``);
    const [pinned, setPinned] = useState(false);
    const [otherPosts, setOtherPosts] = useState([]);
    const [comments, setComments] = useState([]);

    /** 게시글 정보 가저옴 */
    const getPost = () => {
        api.get(`/posts/${postID}`)
          .then((res: any) => {
            if (res.data.permission) { // 권한이 있을 시
                if (res.data.post) {
                    setTitle(res.data.post.title);
                    setPostUserNickname(res.data.post.user_nickname);
                    setDate(res.data.post.date);
                    setContents(res.data.post.contents);
                    setPinned(res.data.post.pinned);
                    setOtherPosts([res.data.next, res.data.previous]);
                    setComments(comments => comments.concat(res.data.replies));
                } else {
                    alert('삭제된 게시글 입니다.')
                    navigate(isMission ? `/mission/${boardType}/1` : `/${boardType}/1`)
                }
            } else { // 권한이 없을 시
                alert('접근 권한이 없습니다.')
                navigate(`/`)
            }
          })
          .catch((err: any) => {
            if (!err.response.data.permission) {
                alert('접근 권한이 없습니다.')
                navigate(`/`)
            }
        });
    }

    useEffect(() => {
        getPost();
    }, [location])
        
    /** 게시글 삭제 */
    const deletePost = () => {
        // 현재 사용자가 관리자인지 아닌지
        const mode = permission === 4 ? 'admin' : 'user';

        if(window.confirm('정말 삭제하시겠습니까?'))   
            api.delete(`/${mode}/posts/${postID}`)
            .then((res: any) => {
                alert('삭제되었습니다.')
                navigate(isMission ? `/mission/${boardType}/1` : `/${boardType}/1`)
            })
            .catch((err: any) => {
                console.log(err);
            });
        else  
            return false;
    }

    /** 게시물 고정 */
    const pinPost = () => {
        api.put(`admin/posts/${postID}/pinned`)
        .then((res: any) => {
            setPinned(res.data.pinned)
        })
        .catch((err: any) => {
            console.log(err);
        });
    }

    return (
        <Container>
            <Section>
                <Content>
                    <Post>
                        <Post.Head>
                            <FlexRowJustifyContentSpaceBetween>
                                <div>
                                    <Post.TitleText>{title}</Post.TitleText>
                                    <FlexRow>
                                        <Post.UserThumbnail>{""}</Post.UserThumbnail>
                                        <Post.Username>{`by ${postUserNickname}`}</Post.Username>
                                        <Post.Date>{conversionDate(date)}</Post.Date>
                                    </FlexRow>
                                </div>  
                                
                                {
                                    permission === 4 &&
                                    <ButtonBox>
                                        <CircleButton
                                            onClick={() => navigate(isMission ? `/mission/${boardType}/posting` : `/${boardType}/posting`, { state: {
                                                boardType: boardType,
                                                postID: postID,
                                                originTitle: title,
                                                originContents: contents
                                            }})}
                                        >
                                            수정
                                        </CircleButton>
                                        <CircleButton 
                                            onClick={() => deletePost()}
                                        >
                                            삭제
                                        </CircleButton>
                                        <PinButton 
                                            active={pinned}
                                            onClick={() => pinPost()}
                                        >
                                            고정
                                        </PinButton>
                                    </ButtonBox>
                                    
                                }
                                
                            </FlexRowJustifyContentSpaceBetween>
                            
                        </Post.Head>
                        <Post.Line />
                        <Post.Content>
                            <TextContainer>
                                {contents}
                            </TextContainer>
                        </Post.Content>
                    </Post>
                    {
                        isMission &&
                            <>
                                <FlexRowJustifyContentSpaceBetween>
                                    <CommentNumber>
                                        {`댓글 ${comments.length}`}
                                    </CommentNumber>
                                    {
                                        ((userNickname === postUserNickname) || (permission === 4)) &&
                                        <ModifiedAndDelete>
                                            <Modified onClick={() => navigate(isMission ? `/mission/${boardType}/posting` : `/${boardType}/posting`, { state: {
                                                boardType: boardType,
                                                postID: postID,
                                                originTitle: title,
                                                originContents: contents
                                            }})}>수정</Modified>
                                            <Bar>|</Bar>
                                            <Delete onClick={() => deletePost()}>삭제</Delete>
                                        </ModifiedAndDelete>
                                    }
                                    
                                </FlexRowJustifyContentSpaceBetween>

                                <CommentContainer
                                    comments={comments} 
                                    postID={postID}
                                />
                            </>
                    }
                    
                </Content>
            </Section>
            <OtherPosts
                boardType={boardType}
                otherPosts={otherPosts}
            />
        </Container>
        
    )
}

export default PostPage