import React, { ReactNode } from 'react'
import { Container } from './TextContainer.style';
import HTMLReactParser from 'html-react-parser'
interface Props {
    children: string
}

const TextContainer = ({ children }: Props) => {
  return (
    <Container>{HTMLReactParser(children)}</Container>
  )
}

export default TextContainer