import styled from 'styled-components'
import { RootContainer, RootContent, RootSection } from '../../styles/public'
import theme from '../../styles/theme'

export const Container = styled(RootContainer)`
`

export const Content = styled(RootContent)`
`

export const BottomSection = styled(RootSection)`
    padding-block: 5rem;
`

export const BottomContent = styled(RootContent)`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1rem;
    grid-row-gap: 2rem;
    align-items: flex-start;
    @media screen and (max-width: 576px) {
        grid-template-columns: 1fr;
    }
`

